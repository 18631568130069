import _ from 'lodash'

export const TraitScore = {
    INFERIOR: -3,
    BAD: -1,
    GOOD: 1,
    SUPERIOR: 3,
    STUPENDOUS: 6,
    OMEGA: 9,
}

export const traitScoreColors = {
    [TraitScore.INFERIOR]: '#bb0000',
    [TraitScore.BAD]: '#B54F29',
    [TraitScore.GOOD]: '#0Ba467',
    [TraitScore.SUPERIOR]: '#5062DB',
    [TraitScore.STUPENDOUS]: '#ff8a9f',
    0: '#777',
}

export const traitsByScore = {
    [TraitScore.INFERIOR]: [
        'Always Forgets',
        'Always Grumpy',
        'Always Lonely',
        'Often Bored',
        'Often Confused',
        'Often Grumpy',
        'Often Hungry',
        'Often Lonely',
        'Often Sad',
        'Often Tired',
        'Rarely Affectionate',
    ],

    [TraitScore.BAD]: [
        'Sometimes Bored',
        'Sometimes Confused',
        'Sometimes Forgets',
        'Sometimes Grumpy',
        'Sometimes Hungry',
        'Sometimes Lonely',
        'Sometimes Sad',
        'Sometimes Tired',
    ],

    [TraitScore.GOOD]: [
        'Often Affectionate',
        'Pretty Calm',
        'Pretty Excitable',
        'Sometimes Affectionate',
    ],

    [TraitScore.SUPERIOR]: [
        'Always Affectionate',
        'Rarely Bored',
        'Rarely Confused',
        'Rarely Forgets',
        'Rarely Grumpy',
        'Rarely Lonely',
        'Rarely Sad',
        'Very Excitable',
    ],

    [TraitScore.STUPENDOUS]: [
        'Rarely Tired',
    ],
}

export const traitScores = {}
_.each(traitsByScore, (traits, score) => {
    _.each(traits, (trait) => {
        traitScores[trait] = parseInt(score)
    })
})

export const doodleScore = (doodle) => {
    // Calculate the simple score based on the doodle's traits
    let score = 0
    _.each(doodle.traits, (trait) => {
        score += traitScores[trait] || 0
    })

    // Cap the simple score at 14
    score = Math.min(score, 14)

    // Add an extra bonus if Rarely Tired is the first trait
    if (doodle.traits[0] === 'Rarely Tired') {
        score += (TraitScore.OMEGA - TraitScore.STUPENDOUS)
    }

    return score
}
