//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'

export default {
    props: ['id'],

    data() {
        return {
            points: null,
        }
    },

    computed: {
        invasion() {
            return this.$store.state.invasions.invasions[this.id]
        },

        cog() {
            return this.$store.state.core.cogs[this.invasion.cog]
        },

        district() {
            return this.$store.state.core.districts[this.invasion.district]
        },

        active() {
            return this.$store.getters.isActiveInvasion(this.invasion)
        },

        duration() {
            return this.$store.getters.invasionDuration(this.invasion)
        },

        defeatRate() {
            if (this.invasion.defeat_rate) return this.invasion.defeat_rate
            return this.invasion.total / (this.invasion.end_time - this.invasion.start_time)
        },
    },

    mounted() {
        axios.get(`/api/invasions/${this.id}/points/`).then((response) => {
            this.points = _.map(response.data, p => ({ x: moment(p.as_of * 1000), y: p.defeated }))
        })
    },

    watch: {
        invasion: {
            handler(newData, oldData) {
                if (newData.defeated !== oldData.defeated && this.points) {
                    this.points.push({ x: moment(), y: newData.defeated })
                }
            },
        },
    },
}
