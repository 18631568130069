
import moment from 'moment'
import { Line } from 'vue-chartjs'

export default {
    extends: Line,

    props: ['points', 'invasion'],

    watch: {
        points: {
            handler() {
                const chart = this.$data._chart // eslint-disable-line no-underscore-dangle
                chart.update()
            },
        },
    },

    mounted() {
        this.chartData = {
            data: this.points,
            label: 'Cogs Defeated',
            borderColor: '#33a5ea',
            backgroundColor: 'transparent',
            pointBackgroundColor: '#33a5ea',
            pointBorderWidth: 0,
            pointRadius: 0,
        }

        this.renderChart({
            datasets: [this.chartData],
        }, {
            maintainAspectRatio: false,


            legend: {
                display: false,
            },

            tooltips: {
                callbacks: {
                    title: tooltipItem => moment(tooltipItem[0].xLabel).format('dddd MMMM Do @ h:mm a'),
                    label: tooltipItem => `${tooltipItem.yLabel} cogs defeated (${Math.floor((tooltipItem.yLabel / this.invasion.total) * 100)}%)`,
                },
            },

            scales: {
                xAxes: [{
                    type: 'time',
                    time: {
                        unit: 'minute',
                        unitStepSize: 5,
                        displayFormats: {
                            minute: 'h:mm a',
                        },
                    },
                }],
            },
        })
    },
}
