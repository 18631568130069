//
//
//
//

import { mapGetters } from 'vuex'

import Nostalgie from './nostalgie'

// const shouldShowAnnouncement = () => Date.now() >= 1692392400000

export default {
    components: {
        announcementContent: Nostalgie,
    },

    data() {
        return {
            // showAnnouncement: shouldShowAnnouncement(),
            showAnnouncement: true,
        }
    },

    // mounted() {
    //     this.$listenFor('TICK_MIN', () => {
    //         this.showAnnouncement = shouldShowAnnouncement()
    //     })
    // },

    computed: {
        ...mapGetters(['game']),
    },
}
