//
//
//
//

import _ from 'lodash'

export default {
    props: {
        text: {
            type: String,
        },

        targetLineCount: {
            default: 1,
            type: Number,
        },

        unit: {
            default: 'px',
            type: String,
        },

        min: {
            default: 5,
            type: Number,
        },

        max: {
            default: 16,
            type: Number,
        },
    },

    data() {
        return {
            ready: true,
        }
    },

    methods: {
        calculate() {
            const element = this.$el
            element.style.display = 'inline-block'
            element.style.lineHeight = '1px'

            let fontSize = this.max
            const stepSize = (this.unit === 'px') ? 1 : 0.05
            element.style.fontSize = fontSize + this.unit

            while (element.offsetHeight > this.targetLineCount && fontSize > this.min) {
                fontSize -= stepSize
                element.style.fontSize = fontSize + this.unit
            }

            element.style.display = null
            element.style.lineHeight = null
        },
    },

    mounted() {
        this.calculate()
        this.$options.onWindowResize = _.debounce(() => {
            if (!this.ready) return
            this.calculate()
        }, 100)
        window.addEventListener('resize', this.$options.onWindowResize)
    },

    updated() {
        this.calculate()
    },

    beforeDestroy() {
        this.ready = false
        window.removeEventListener('resize', this.$options.onWindowResize)
    },
}
