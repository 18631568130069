//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'

import { cogTypeImages } from 'core/util/cogs'
import { zeroPad } from 'core/util/filters'

export default {
    props: ['schedule', 'bossType', 'now', 'dayIndex', 'actualDayIndex'],

    data() {
        return {
            bossTypeIcons: {
                vp: cogTypeImages.Sellbot,
                cfo: cogTypeImages.Cashbot,
                cj: cogTypeImages.Lawbot,
                ceo: cogTypeImages.Bossbot,
            },
        }
    },

    computed: {
        dayTimes() {
            return this.schedule[this.dayIndex][this.bossType.toUpperCase()]
        },

        actualDayTimes() {
            return this.schedule[this.actualDayIndex][this.bossType.toUpperCase()]
        },

        nextDayTimes() {
            return this.schedule[this.nextDayIndex][this.bossType.toUpperCase()]
        },

        nextDayIndex() {
            return this.actualDayIndex < 6 ? this.actualDayIndex + 1 : 0
        },

        nextRun() {
            // Get the next time happening on the actual day
            for (const run of this.actualDayTimes) {
                if (run.isAfter(this.now)) return run
            }

            // All the runs on the current day have happened, let's get the next day's first run
            return this.nextDayTimes[0]
        },

        timeUntilNextRun() {
            const secs = this.nextRun.diff(this.now) / 1000
            const hours = Math.floor(secs / 3600)
            const minutes = Math.floor((secs / 60) % 60)
            const seconds = Math.floor(secs % 60)
            return `${hours} : ${zeroPad(minutes)} : ${zeroPad(seconds)}`
        },

        runClasses() {
            let foundActive = false
            return _.map(this.dayTimes, (run) => {
                if (this.dayIndex !== this.actualDayIndex) return 'future'

                if (run.isAfter(this.now)) {
                    if (!foundActive) {
                        foundActive = true
                        return 'next'
                    }
                    return 'future'
                }
                return 'past'
            })
        },
    },
}
