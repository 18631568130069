export default {
    state: {
        jellybeanHint: 'pos',
    },

    mutations: {
        setJellybeanHint(state, hint) {
            state.jellybeanHint = hint
        },
    },
}
