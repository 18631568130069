//
//
//
//
//
//
//
//
//
//
//
//

import { recentChanges } from 'core/changelog'
import ChangelogList from 'core/components/changelog-list'

export default {
    name: 'WhatsNew',

    components: { ChangelogList },

    props: {
        sinceId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            changelog: recentChanges(this.sinceId),
        }
    },
}
