import _ from 'lodash'

export const attributes = [
    {
        id: 'head',
        name: 'Hair',
        order: 1,
        values: [0, 255],
        show: true,
        default: 255,
        resolution: 512,
        classes: 'col-xl-2 col-lg-3 col-md-3 col-sm-4',
        style: {
            'background-size': '300%',
            'background-position': 'center top',
            width: '60px',
            height: '60px',
        },
    },
    {
        id: 'ears',
        name: 'Ears',
        order: 2,
        values: [0, 1, 2, 3, 4, 255],
        show: true,
        default: 255,
        resolution: 512,
        classes: 'col-xl-5 col-lg-3 col-md-5 col-sm-4',
        style: {
            'background-size': '225%',
            'background-position': 'center top',
            width: '60px',
            height: '60px',
        },
    },
    {
        id: 'nose',
        name: 'Nose',
        order: 5,
        values: [0, 1, 2, 3, 255],
        show: true,
        default: 255,
        resolution: 512,
        classes: 'col-xl-5 col-lg-6 col-md-5 col-sm-4',
        style: {
            'background-size': '400%',
            'background-position': '30% 35%',
            width: '60px',
            height: '60px',
        },
    },
    {
        id: 'tail',
        name: 'Tail',
        order: 6,
        values: [0, 1, 2, 3, 255],
        show: true,
        default: 255,
        resolution: false,
        classes: 'col-xl-4 col-lg-6 col-md-4 col-sm-4',
        style: {
            'background-size': '225%',
            'background-position': '75% 40%',
            width: '60px',
            height: '60px',
        },
    },
    {
        id: 'body',
        name: 'Body',
        order: 7,
        values: _.range(0, 7),
        show: true,
        resolution: 200,
        classes: 'col-xl-5 col-lg-5',
        style: {
            width: '80px',
            height: '80px',
        },
    },
    {
        id: 'color',
        name: 'Color',
        order: 8,
        values: _.range(0, 26),
        show: true,
        default: 25,
        resolution: 200,
        classes: 'col-12 col-xl-7 col-lg-7',
        style: {
            width: '50px',
            height: '50px',
        },
    },
    {
        id: 'color_scale',
        name: 'Color Scale',
        values: _.range(0, 9),
        show: false,
        resolution: 200,
        classes: 'col-12',
    },
    {
        id: 'eyes',
        name: 'Eyes',
        order: 3,
        values: _.range(0, 6),
        show: true,
        resolution: 512,
        classes: 'col-xl-5 col-lg-3 col-md-4 col-sm-4',
        style: {
            'background-size': '400%',
            'background-position': '35% 25%',
            width: '60px',
            height: '60px',
        },
    },
    {
        id: 'gender',
        name: 'Eyelashes',
        order: 4,
        values: [0, 1],
        show: true,
        default: 1,
        resolution: 512,
        classes: 'col-xl-2 col-lg-3 col-md-3 col-sm-4',
        style: {
            'background-size': '400%',
            'background-position': '35% 25%',
            width: '60px',
            height: '60px',
        },
    },
]

export const buildDna = (values) => {
    const dna = _.map(attributes, (attr) => {
        let val = values[attr.id]
        if (val === undefined) val = attr.default || 0
        return _.padStart(val.toString(16), 2, '0')
    }).join('')
    return `64${dna}`
}

export const valuesToSubstringFilter = (attrId, values) => ({
    index: (_.map(attributes, 'id').indexOf(attrId) * 2) + 2,
    values: _.map(values, val => _.padStart(val.toString(16), 2, '0')),
})
