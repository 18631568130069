//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'
import moment from 'moment'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

import RequireToonWrapper from 'accounts/components/require-toon-wrapper'
import { keyedGameSuits } from 'core/util/cogs'
import { gameUrl } from 'core/util/games'
import { promptLinkToon } from 'core/util/toons'

import GroupMember from './group-member'
import Speedchat from './speedchat'
import ModerateForm from './moderate-form'

export default {
    props: {
        groupId: {
            type: Number,
            required: true,
        },

        resumeJoin: {
            type: Boolean,
            default: false,
        },

        reportId: {
            type: Number,
            required: false,
        },

        forceShowAll: {
            type: Boolean,
            default: false,
        },
    },

    components: { GroupMember, Speedchat },

    modalClass: 'group-details-dialog modal-content',

    data() {
        return {
            alert: false,
            promptSize: false,
            wipReport: null,
            showChat: false,

            joiningGroup: false,
            leavingGroup: false,
            deferringJoin: false,
            boardingFailed: false,
            offerInvalidate: !!this.reportId,
        }
    },

    computed: {
        // Basic group information
        group() {
            return this.groups[this.groupId]
        },

        type() {
            return this.groupType(this.group)
        },

        displayName() {
            return this.groupDisplayName(this.group)
        },

        roomLeft() {
            if (this.group.max_players) return this.group.max_players - this.groupNumPlayers(this.group)
            return true
        },

        roomLeftWithoutUser() {
            let capacityWithoutUser = this.roomLeft
            if (this.membership) capacityWithoutUser += this.membership.num_players
            return capacityWithoutUser
        },

        partySizeOptions() {
            const sizes = []
            for (let i = 2; i <= this.roomLeftWithoutUser; i++) {
                sizes.push(i)
            }
            return sizes
        },

        suit() {
            if (this.type.name === 'VP') return keyedGameSuits[this.gameId].sellbot
            if (this.type.name === 'CFO') return keyedGameSuits[this.gameId].cashbot
            if (this.type.name === 'CJ' || this.type.name === 'CLO') return keyedGameSuits[this.gameId].lawbot
            if (this.type.name === 'CEO') return keyedGameSuits[this.gameId].bossbot
            return null
        },

        // Membership management
        membership() {
            return this.userMembershipInGroup(this.group)
        },

        membershipToon() {
            const toonId = this.membership?.toon?.id
            if (!this.toons || !toonId) return
            return this.toons[toonId]
        },

        isOwner() {
            return this.membership && this.membership.owner
        },

        canChangeNumberInParty() {
            // Don't allow changing for groups over 8 (like Doodle Groups)
            if (this.group.max_players && this.group.max_players > 8) return false

            return this.membership && this.roomLeft !== true && (this.roomLeft > 0 || this.membership.num_players > 1)
        },

        // Group expiration
        timeUntilExpiration() {
            return moment(this.group.last_keep_alive).add(15, 'minutes').fromNow()
        },

        canKeepAlive() {
            return this.group.keep_alives < 3
        },

        canRelist() {
            return this.isOwner && this.groupIsActive && this.group.went_in && this.displayName.includes('SOS Shopping')
        },

        groupIsActive() {
            return this.activeGroups.includes(this.group)
        },

        // Invasions
        invasion() {
            return this.keyedActiveInvasions[this.group.district]
        },

        invasionCog() {
            if (!this.invasion) return null
            return this.cogs[this.invasion.cog]
        },

        invasionCogImage() {
            if (!this.invasionCog) return null
            return this.invasionCog.image ? `cogs/${this.invasionCog.image}` : null
        },

        reportReasons() {
            const reasons = [
                { text: "They aren't a good fit for my group", value: 'bad_fit' },
                { text: 'They were rude', value: 'rude' },
                { text: 'They have an inappropriate name', value: 'name' },
                { text: 'They sent inappropriate messages', value: 'message' },
            ]
            if (this.wipReport.action === 'Kick') reasons.push({ text: 'Something else (harmless)', value: 'other' })
            return reasons
        },

        showAll() {
            return this.isMod || this.forceShowAll
        },

        // General state
        ...mapState({
            groups: state => state.groups.groups,
            cogs: state => state.core.cogs,
            districts: state => state.core.districts,
            locations: state => state.core.locations,
            toons: state => state.accounts.toons,
        }),
        ...mapGetters([
            'groupType', 'groupDisplayName', 'groupNumPlayers', 'groupPlayerCount', 'userGroupId', 'groupImage',
            'keyedActiveInvasions', 'userMembershipInGroup', 'activeGroups', 'invasionDuration', 'districtList',
            'currentToon', 'gameId', 'game', 'isMod',
        ]),
    },

    methods: {
        expiringSoon() {
            // TODO: Right now, the expiring soon banner doesn't show up on its own unless something happens
            // Change expiringSoon to be stored in data, and set up an interval that sets
            // the value of it every 10 seconds
            // (also needs to be updated whenever group changes (members added, keep alive, etc.))
            const now = moment().unix()
            const lastKeepAlive = moment(this.group.last_keep_alive).unix()
            return (now - lastKeepAlive) > (12 * 60)
        },

        async join(partySize) {
            if (!this.currentToon) {
                // The user either isn't logged in, or doesn't have a toon for this game
                // Let's defer to the require toon wrapper and open ourselves back up
                // once the user has created a toon
                this.deferringJoin = true
                this.$swapModal(RequireToonWrapper, {
                    target: 'GroupDetails',
                    props: {
                        groupId: this.groupId,
                        resumeJoin: true,
                    },
                })
                return
            }

            if (!partySize && this.group.max_players) {
                if (this.roomLeft === 1) {
                    partySize = 1
                } else {
                    this.promptSize = true
                    return
                }
            }

            this.joiningGroup = partySize || true
            this.alert = false
            this.boardingFailed = false
            try {
                await this.joinGroup({ group: this.group, numPlayers: partySize })
            } catch (e) {
                console.log(e)
                this.alert = this.$drf.parseError(e).squashed
            }
            this.joiningGroup = false
            this.promptSize = false
        },

        async leave() {
            this.alert = false
            this.leavingGroup = true
            try {
                await this.leaveGroup(this.groupId)
                this.boardingFailed = false
                this.$vuedals.close(this)
            } catch (e) {
                const error = this.$drf.parseError(e)
                if (error.status === 400) {
                    this.$vuedals.close(this)
                } else {
                    this.alert = error.squashed
                    this.leavingGroup = false
                }
            }
        },

        async updateDistrict(district) {
            this.alert = null
            try {
                await this.changeDistrict({ group: this.group, district })
            } catch (e) {
                this.alert = this.$drf.parseError(e).squashed
            }
        },

        startReport(report) {
            console.log('Starting report', report)
            if (!this.currentToon) {
                // The user either isn't logged in, or doesn't have a toon for this game
                // Let's defer to the require toon wrapper and open ourselves back up
                // once the user has created a toon
                this.$swapModal(RequireToonWrapper, {
                    target: 'GroupDetails',
                    props: { groupId: this.groupId },
                })
                return
            }

            // Show the report screen
            if (report.action !== 'Ban') {
                this.wipReport = { ...report, reason: report.message ? 'message' : null, confirmationRequired: false }
                this.showChat = false
            } else {
                this.$present(ModerateForm, { target: report, reportId: this.reportId })
            }
        },

        async finishReport(block, confirmedNotInGame) {
            if (!this.wipReport.reason) return

            // Check if we need to confirm that this isn't an in-game report
            if (!confirmedNotInGame && this.wipReport.action === 'Report' && this.wipReport.reason === 'rude') {
                this.wipReport.confirmationRequired = true
                return
            }

            const data = { ...this.wipReport, block }
            this.alert = null
            this.wipReport = null
            try {
                await this.reportToon(data)
                this.alert = data.action === 'Report' ? "Thank you for your report, we'll check it out!" : 'They have been kicked.'
            } catch (e) {
                this.alert = this.$drf.parseError(e).squashed
            }
        },

        async invalidateReport() {
            this.offerInvalidate = false
            await axios.post(`/api/groups/reports/${this.reportId}/invalidate_report/`)
        },

        attemptReboarding() {
            this.boardingFailed = false
            this.retryBoarding(this.membershipToon.id)
        },

        promptLinkToon,
        ...mapActions(['joinGroup', 'leaveGroup', 'changeDistrict', 'reportToon', 'retryBoarding']),
        ...mapMutations(['openGroup', 'closeGroup']),
    },

    watch: {
        groupIsActive(active) {
            if (!active && !this.isMod) this.$vuedals.close(this)
        },

        'group.boarding': function onBoardingStatusChange(val) {
            if (!val) this.boardingFailed = false
        },
    },

    created() {
        // Notify the store
        this.openGroup(this.groupId)

        // Check if the user was in the middle of joining a group
        if (this.resumeJoin) {
            if (this.roomLeft) {
                this.promptSize = true
            } else {
                this.alert = "Whoops, looks like this group is full! On the bright side, your toon is all set so you're ready to hop into a different one!"
            }
        }

        // Add the group to the URL
        this.$router.safePush(gameUrl(this.gameId, `/groups/${this.groupId}/`))

        // Listen for report link clicks
        this.$listenFor('startReport', report => this.startReport(report))

        // Listen for failures to join the group
        this.$listenFor('socket:boarding_fail', (data) => {
            this.boardingFailed = data
        })
    },

    beforeDestroy() {
        // Notify the store
        this.closeGroup()

        // Remove the group from the URL (unless we're deferring to signing in/creating a toon)
        if (!this.deferringJoin) {
            this.$router.safeReplace(gameUrl(this.gameId, '/groups/'))
        } else {
            // We're deferring to the require toon wrapper, let's leave it in the URL but
            // indicate that we're in the middle of joining a group
            window.location.hash = 'join'
        }
    },
}
