//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex'
import _ from 'lodash'

import { ModalFormMixin } from 'core/components/forms/mixin'
import { promptLinkToon } from 'core/util/toons'
import LoginForm from 'accounts/components/login-form'
import ToonForm from 'accounts/components/toon-form'
import GroupDetails from './group-details'

export default {
    name: 'GroupForm',

    mixins: [ModalFormMixin],

    components: { LoginForm, ToonForm },

    props: {
        initialBoardingGroup: {
            type: Object,
            required: false,
        },
    },

    fields: [
        'toon',
        'type',
        'district',
        'location',
        { name: 'options', default: () => ({}) },
        'max_players',
        { name: 'num_players', default: 1 },
    ],

    dispatch: 'createGroup',

    data() {
        return {
            bgStatus: null,
            bgPlayerOffset: 0,
            skipLoadingBoardingGroup: !!this.initialBoardingGroup,
        }
    },

    computed: {
        type() {
            return this.groupTypes[this.model.type.value]
        },

        availableLocations() {
            return this.locationsForType(this.type)
        },

        availableFieldOffices() {
            if (this.fieldOfficeList === null) return []
            return _.map(this.fieldOfficeList, (fo) => {
                const location = _.find(this.availableLocations, { id: fo.location })
                return {
                    id: fo.location,
                    name: `${location.name} (${fo.difficulty} star)`,
                }
            })
        },

        effectiveMaxPlayers() {
            if (this.model.max_players.value) return this.model.max_players.value
            return this.type.max_players
        },

        maxPlayerOptions() {
            if (!this.type.max_player_options) return null
            return _.map(this.type.max_player_options, n => ({ text: n, value: n }))
        },

        partySizeOptions() {
            if (!this.effectiveMaxPlayers) return null
            const options = []
            for (let n = 0; n < this.effectiveMaxPlayers - 1; n++) {
                if (this.bgPlayerOffset && n < this.bgPlayerOffset) continue
                options.push({ text: n, value: n + 1 })
            }
            return options
        },

        fieldOfficeMode() {
            if (!this.type) return false
            return this.type.name === 'Sellbot Field Office'
        },

        availableDistricts() {
            // Special DG 4 star field office event that only takes place in Kaboomberg
            if (this.model.type.value === 49) return _.filter(this.districtList, { name: 'Kaboomberg' })

            return _.filter(this.districtList, d => d.name !== 'Kaboomberg')
        },

        toonCouldSupportBoarding() {
            if (!this.model.toon.value) return false
            const toon = this.toons[this.model.toon.value]
            return toon.game === 1
        },

        toonSupportsBoarding() {
            // Ensure we have a TTR ToonSync Toon selected
            if (!this.model.toon.value) return false
            const toon = this.toons[this.model.toon.value]
            if (!toon.external || toon.game !== 1) return false

            return true
        },

        ...mapState({
            groupTypes: state => state.groups.groupTypes,
            toons: state => state.accounts.toons,
        }),
        ...mapGetters(['game', 'locationList', 'districtList', 'groupTypeList', 'keyedActiveInvasions', 'cogs', 'fieldOfficeList']),
    },

    created() {
        if (!this.initialBoardingGroup) {
            this.model.type.value = this.groupTypeList[0].id
            this.model.district.value = this.availableDistricts[0].id
            this.model.location.value = this.availableLocations[0].id
        } else {
            this.setOptionsFromBoardingGroup(this.initialBoardingGroup)
        }
    },

    methods: {
        optionOptions(option) {
            const options = _.map(option.values, (v) => {
                let text = v.name
                if (text.includes(' Story')) text = text.substr(0, text.length - 6)
                return { text, value: v.id }
            })
            if (option.optional) {
                // TODO: Eventually we should just add default_text to option or something (which will usually, not always be Any)
                if (option.id === 37) return [{ text: 'Standard', value: null }].concat(options)
                return [{ text: 'Any', value: null }].concat(options)
            }
            return options
        },

        showGroupOption(option) {
            if (this.fieldOfficeMode && option.name === 'Stars') return false
            return true
        },

        locationsForType(type) {
            if (type.implied_location) return [_.find(this.locationList, { id: type.implied_location })]
            if (!type.required_location_type && !type.possible_locations) return this.locationList
            return _.filter(this.locationList, (l) => {
                if (l.type !== type.required_location_type) return false
                if (type.possible_locations && !type.possible_locations.includes(l.id)) return false
                return true
            })
        },

        clean(data) {
            data.game = this.game.id

            if (!this.effectiveMaxPlayers) {
                data.num_players = 1
            } else if (this.bgPlayerOffset) {
                data.num_players -= this.bgPlayerOffset
            }
            if (data.num_players < 1) data.num_players = 1

            // Set the starts for field offices automatically
            if (this.fieldOfficeMode) {
                const fo = _.find(this.fieldOfficeList, { location: data.location })
                if (!fo) return this.setAlert('Please choose a field office.')
                const stars = _.find(this.type.options, { name: 'Stars' })
                data.options[stars.id] = stars.values[fo.difficulty - 1].id
            }

            return data
        },

        onSubmitSuccess(data, response) {
            ModalFormMixin.methods.onSubmitSuccess.call(this, data, response)
            this.$store.dispatch('switchToon', data.members[0].toon.id)
            setTimeout(() => {
                this.$present(GroupDetails, { groupId: data.id })
            }, 0)
        },

        setOptionsFromBoardingGroup(bg) {
            this.bgStatus = 'active'
            this.model.type.value = bg.type
            this.model.district.value = bg.district
            this.model.location.value = bg.location
            this.bgPlayerOffset = bg.num_players - 1

            // Check if this is Kaboomberg
            const district = _.find(this.districtList, { id: bg.district })
            if (district.name === 'Kaboomberg') {
                this.model.type.value = _.find(this.groupTypes, { name: 'Four Star Field Office' }).id
                return
            }

            this.$nextTick(() => {
                this.model.options.value = bg.options
                this.model.num_players.value = bg.num_players
            })
        },

        async checkForBoardingGroup() {
            this.bgPlayerOffset = 0

            if (this.skipLoadingBoardingGroup) {
                // We don't want to load if we were directly passed a boarding group
                this.skipLoadingBoardingGroup = false
                return
            }

            if (!this.toonSupportsBoarding) {
                this.bgStatus = null
                return
            }

            console.log(`Checking if toon ${this.model.toon.value} is in a boarding group...`)
            this.bgStatus = 'loading'
            const toonId = this.model.toon.value
            let bg = null
            try {
                bg = await this.loadExistingBoardingGroup(this.model.toon.value)
            } catch (e) {
                this.bgStatus = e.message
                return
            }

            // Ensure the Toon hasn't changed in the time we were waiting
            if (this.model.toon.value !== toonId) return

            // Process the result
            console.log(bg)
            if (bg === null) {
                this.bgStatus = 'offline'
            } else if (!bg.type) {
                // No group, but we have their presence info
                if (bg.location) {
                    this.model.district.value = bg.district
                    this.model.location.value = bg.location
                    this.bgStatus = 'noGroup'

                    // Check if this is Kaboomberg
                    const district = _.find(this.districtList, { id: bg.district })
                    if (district.name === 'Kaboomberg') {
                        this.model.type.value = _.find(this.groupTypes, { name: 'Four Star Field Office' }).id
                        return
                    }

                    // Pick the first group that is applicable for this location
                    _.each(this.groupTypes, (type) => {
                        const typeLocations = _.map(this.locationsForType(type), 'id')
                        if (typeLocations.includes(bg.location)) {
                            this.model.type.value = type.id
                            return false
                        }
                    })
                } else {
                    this.model.district.value = bg.district
                    this.bgStatus = 'noLocation'
                }
            } else {
                this.setOptionsFromBoardingGroup(bg)
            }
        },

        promptLinkToon,
        ...mapActions(['trackFieldOffices', 'stopTrackingFieldOffices', 'loadExistingBoardingGroup']),
    },

    watch: {
        'model.type.value': function onTypeChange() {
            this.model.options.value = {}
            const availableLocationIds = _.map(this.availableLocations, 'id')
            if (availableLocationIds.indexOf(this.model.location.value) === -1) this.model.location.value = this.availableLocations[0].id

            this.model.num_players.value = 1
            if (this.type.max_player_options) {
                this.model.max_players.value = _.max(this.type.max_player_options)
            } else {
                this.model.max_players.value = null
            }

            // Set any required options to their first possible value
            _.each(this.type.options, (option) => {
                if (!option.optional) this.$set(this.model.options.value, option.id, option.values[0].id)
            })

            // Reset the district if it is no longer available
            if (!_.map(this.availableDistricts, 'id').includes(this.model.district.value)) {
                this.model.district.value = this.availableDistricts[0].id
            }
        },

        'model.max_players.value': function onMaxPlayersChange(max) {
            if (!max) {
                this.model.num_players.value = 1
            } else if (this.model.num_players.value > max - 1) {
                this.model.num_players.value = max - 1
            }
        },

        'model.toon.value': function onToonChange() {
            // Note that this also fires when the modal first opens!
            this.checkForBoardingGroup()
        },

        async fieldOfficeMode(val) {
            if (val) {
                await this.trackFieldOffices('groups')
            } else {
                this.stopTrackingFieldOffices('groups')
            }
        },
    },

    beforeDestroy() {
        if (this.fieldOfficeMode) this.stopTrackingFieldOffices('groups')
    },
}
