//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ModalFormMixin } from 'core/components/forms/mixin'

export default {
    name: 'ChangeEmailForm',

    mixins: [ModalFormMixin],

    modalClass: 'modal-content modal-content--sm',

    fields: [
        'email',
    ],

    dispatch: 'startChangeEmail',

    methods: {
        onSubmitSuccess() {
            this.submitted = true
        },
    },
}
