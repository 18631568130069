import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios from 'axios'
import _ from 'lodash'

import games from 'core/util/games'
import radio from 'core/util/radio'
import accountsStore from 'accounts/store'
import socketStore from 'core/socket'
import fishingStore from 'fishing/store'
import fosStore from 'fos/store'
import invasionsStore from 'invasions/store'
import groupsStore, { registerGroupsWatchers } from 'groups/store'
import guildsStore from 'guilds/store'
import gardeningStore from 'gardening/store'
import { notificationsStore } from './notifications'

const initialState = window.STATE
const globals = window.GLOBALS

export const coreStore = {
    state: {
        theme: null,
        lastGame: 1,

        toonSpecies: _.keyBy(globals.toon_species, 'id'),

        cogs: initialState.cogs ? _.keyBy(initialState.cogs, 'id') : null,
        locations: initialState.locations ? _.keyBy(initialState.locations, 'id') : null,
        districts: initialState.districts ? _.keyBy(initialState.districts, 'id') : null,

        announcementSnoozes: {},

        nostalgie: {
            loading: false,
            metadata: null,
        },
    },

    getters: {
        gameId(state, getters, rootState) {
            // Check if the current route has an explicit game set
            if (rootState.route && rootState.route.meta.game) return rootState.route.meta.game

            // Use the last game we were in (defaults to TTR)
            return state.lastGame
        },

        game(state, getters) {
            if (!getters.gameId) return null
            return games[getters.gameId]
        },

        cogs: (state, getters) => _.pickBy(state.cogs, { game: getters.game.id }),
        cogList: (state, getters) => _.sortBy(getters.cogs, 'order'),
        cogTypes: (state, getters) => _.uniq(_.map(getters.cogList, 'type')),
        locations: (state, getters) => _.pickBy(state.locations, { game: getters.game.id }),
        districts: (state, getters) => _.pickBy(state.districts, { game: getters.game.id }),

        locationList: (state, getters) => _.sortBy(getters.locations, 'name'),
        districtList: (state, getters) => _.sortBy(getters.districts, 'name'),
    },

    mutations: {
        set(state, { model, data }) {
            state[model] = data
        },

        setTheme(state, theme) {
            state.theme = theme || 'generic'
        },

        setLastGame(state, game) {
            state.lastGame = game
        },

        updateCoreInvasionCountForInvasion(state, invasion) {
            state.cogs[invasion.cog].num_invasions += 1
            state.districts[invasion.district].num_invasions += 1
        },

        snoozeAnnouncement(state, announcement) {
            state.announcementSnoozes = { ...state.announcementSnoozes, [announcement]: Date.now() }
        },
    },

    actions: {
        load({ commit, state }, model) {
            if (state[model]) return true
            return axios.get(`/api/${model}/`).then((response) => {
                const data = _.keyBy(response.data, 'id')
                commit('set', { model, data })
            })
        },

        async loadNostalgieMetadata({ state }) {
            if (state.nostalgie.loading || state.nostalgie.metadata !== null) return

            let response
            try {
                response = await axios.get('https://cdn.toonhq.org/nostalgie/nostalgie.json')
            } catch (e) {
                state.nostalgie.loading = false
                return
            }

            state.nostalgie.metadata = response.data
            state.nostalgie.loading = false
        },
    },
}

// Set up persistent objects
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,

    reducer: state => ({
        core: {
            lastGame: state.core.lastGame,
            announcementSnoozes: state.core.announcementSnoozes,
        },

        accounts: {
            currentToon: state.accounts.currentToon,
            lastToonForGame: state.accounts.lastToonForGame,
        },

        gardening: {
            jellybeanHint: state.gardening.jellybeanHint,
        },

        invasions: {
            notifyAllCogs: state.invasions.notifyAllCogs,
            notifyCogs: state.invasions.notifyCogs,
            notifySounds: state.invasions.notifySounds,
        },

        notifications: {
            notificationTypes: state.notifications.notificationTypes,
        },
    }),

    filter: mutation => [
        'switchToon',
        'setLastGame',
        'snoozeAnnouncement',

        'toggleNotifyAllCogs',
        'setCogNotificationsForGame',
        'toggleNotifySounds',

        'updateNotificationPreference',
        'resetNotificationPreferences',

        'setJellybeanHint',
    ].includes(mutation.type),
})

// Set up the root store
Vue.use(Vuex)
export const store = new Vuex.Store({
    modules: {
        core: coreStore,
        accounts: accountsStore,
        fishing: fishingStore,
        fos: fosStore,
        invasions: invasionsStore,
        groups: groupsStore,
        guilds: guildsStore,
        gardening: gardeningStore,
        notifications: notificationsStore,
        socket: socketStore,
    },
    plugins: [vuexLocal.plugin],
})

// Listen for events
radio.$on('invasions:new', (invasion) => {
    store.commit('updateCoreInvasionCountForInvasion', invasion)
})

// Notify stores when the game changes so they can reset their data
let gameLoaded = false
setTimeout(() => { gameLoaded = true }, 0)
store.watch((state, getters) => getters.gameId, (game) => {
    if (store.state.core.lastGame !== game) store.commit('setLastGame', game)

    if (!gameLoaded && game > 1) {
        // Skip the first game changed notification if it isn't 1 as that's just the page loading
    } else {
        store.dispatch('gameChanged', game)
    }
    gameLoaded = true
})

// Register watchers for stores
registerGroupsWatchers(store)

// Initialize notification settings
store.dispatch('initNotifications')
