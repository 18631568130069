//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { mapGetters } from 'vuex'

import { assetPath } from 'core/util/filters'
import InvasionDetails from 'invasions/components/invasion-details'

export default {
    props: ['invasion'],

    data() {
        return {
            progressKey: 1,
        }
    },

    computed: {
        cog() {
            return this.$store.state.core.cogs[this.invasion.cog]
        },

        district() {
            return this.$store.state.core.districts[this.invasion.district]
        },

        image() {
            return this.cog.image ? assetPath(`cogs/${this.cog.image}`) : null
        },

        active() {
            return this.$store.getters.isActiveInvasion(this.invasion)
        },

        duration() {
            return this.$store.getters.invasionDuration(this.invasion)
        },

        progress() {
            if (this.gameId === 1 && this.invasion.total === 1000000) {
                // This is a TTR mega invasion
                const remaining = (this.invasion.start_time + 10800) - moment().unix()
                return (1 - (remaining / 10800)) * 100
            }

            if (this.progressKey && this.invasion.time_remaining) {
                // Invasion is based on time
                const endTime = this.invasion.as_of + this.invasion.time_remaining
                const totalTime = endTime - this.invasion.start_time
                return (1 - (this.invasion.time_remaining / totalTime)) * 100
            }

            // Invasion is based on cogs
            return (this.invasion.defeated / this.invasion.total) * 100
        },

        ...mapGetters(['gameId']),
    },

    methods: {
        showDetails() {
            this.$present(InvasionDetails, { id: this.invasion.id })
        },
    },

    created() {
        // Force the bar to update every second for time based invasions
        if (this.invasion.time_remaining) {
            this.$listenFor('TICK_SEC', () => {
                this.progressKey += 1
            })
        }
    },
}
