import radio from 'core/util/radio'

class Timer {
    constructor() {
        setInterval(() => radio.$emit('TICK_SEC'), 1000)
        setInterval(() => radio.$emit('TICK_10SEC'), 10000)
        setInterval(() => radio.$emit('TICK_MIN'), 60000)
    }
}

const timer = new Timer()

export default timer
