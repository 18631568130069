//
//
//
//
//
//
//
//
//

export default {
    id: 8,
    version: '3.7.3',
    date: '2022-05-27',
    notify: true,
}
