//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

import { ModalFormMixin } from 'core/components/forms/mixin'

export default {
    name: 'DiscordFollowupForm',

    mixins: [ModalFormMixin],

    modalClass: 'modal-content modal-content--sm',

    fields: [],

    dispatch: 'updateUser',

    data() {
        return {
            display: true,
            join: this.$store.getters.gameId === 1,
        }
    },

    computed: {
        action() {
            return this.user.has_password ? 'linked up' : 'signed in'
        },

        ...mapState({
            user: state => state.accounts.user,
        }),
    },

    methods: {
        clean() {
            return {
                discord_public: this.display,
                discord_join: this.join,
            }
        },
    },
}
