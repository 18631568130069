//
//
//
//
//
//
//
//
//

export default {
    id: 7,
    version: '3.7.2',
    date: '2022-04-01',
    notify: true,
}
