import _ from 'lodash'

import NitroPayAdController from './NitroPayAdController'
import PlaywireAdController from './PlaywireAdController'

export const providers = [
    {
        id: 'pw',
        name: 'Playwire',
        privacyPolicyUrl: 'https://www.playwire.com/privacy-policy/',
        controller: PlaywireAdController,
    },
    {
        id: 'np',
        name: 'NitroPay',
        privacyPolicyUrl: 'https://www.nitropay.com/privacy/',
        controller: NitroPayAdController,
    },
]

export const keyedProviders = _.keyBy(providers, 'id')

export const getCurrentProvider = () => {
    if (window.ramp) return keyedProviders.pw
    if (window.nitroAds) return keyedProviders.np
    return null
}
