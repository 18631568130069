//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

import ToonForm from 'accounts/components/toon-form'
import GroupMemberToon from './group-member-toon'

export default {
    props: ['member', 'userIsOwner', 'suit'],

    components: { GroupMemberToon },

    computed: {
        toon() {
            return this.member.toon
        },

        isUser() {
            return this.userGroupMembership?.membership.id === this.member.id
        },

        ...mapGetters(['userGroupMembership']),
    },

    methods: {
        updateToon() {
            this.$present(ToonForm, { values: this.$store.state.accounts.toons[this.member.toon.id] })
        },
    },
}
