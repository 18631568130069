import axios from 'axios'
import _ from 'lodash'

const initialState = window.STATE

export default {
    state: initialState.fish ? {
        species: _.keyBy(initialState.species, 'id'),
        fish: _.keyBy(initialState.fish, 'id'),
        ponds: _.keyBy(initialState.pond, 'id'),
        fishPonds: _.keyBy(initialState.fish_pond, 'id'),
    } : {
        species: null,
        fish: null,
        pond: null,
        fishPond: null,
    },

    getters: {
        speciesFishList: (state) => {
            if (!state.species) return []

            return _.orderBy(_.map(state.species, s => ({
                ...s,
                fish: _.orderBy(_.filter(state.fish, { species: s.id }), 'sort_order'),
            })), 'sort_order')
        },
    },

    mutations: {
        updateFishingData(state, data) {
            state.species = _.keyBy(data.species, 'id')
            state.fish = _.keyBy(data.fish, 'id')
            state.ponds = _.keyBy(data.pond, 'id')
            state.fishPonds = _.keyBy(data.fish_pond, 'id')
        },
    },

    actions: {
        async loadFishingCoreData({ state, commit, getters }) {
            if (state.species || getters.game.id === 2) return

            const response = await axios.get(`/api/fishing/core_data/${getters.game.id}/`)
            commit('updateFishingData', response.data)
        },
    },
}
