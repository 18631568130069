//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import _ from 'lodash'

import { secsToTime, timeToLongString } from 'core/util/time'

export default {
    computed: {
        averageInvasionRate() {
            return this.$store.state.invasions.statistics.avg_invasion_rate
        },

        averageInvasionTime() {
            return timeToLongString(secsToTime(this.$store.state.invasions.statistics.avg_invasion_time))
        },

        topCog() {
            let name = null
            let count = -1
            _.each(this.sortedCogs, (cog) => {
                if (cog.num_invasions > count) {
                    name = cog.name // eslint-disable-line prefer-destructuring
                    count = cog.num_invasions
                }
            })
            return { name, count }
        },

        topDistrict() {
            let name = null
            let count = -1
            _.each(this.sortedDistricts, (district) => {
                if (district.num_invasions > count) {
                    name = district.name // eslint-disable-line prefer-destructuring
                    count = district.num_invasions
                }
            })
            return { name, count }
        },

        sortedCogs() {
            return _.sortBy(this.cogs, c => -c.num_invasions)
        },

        sortedDistricts() {
            return _.sortBy(this.districts, c => -c.num_invasions)
        },

        ...mapGetters(['cogs', 'districts']),
    },

    methods: {
        prepare() {
            return this.$store.dispatch('loadInvasionStatistics')
        },
    },
}
