//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'AppsPage',

    metaInfo() {
        return {
            title: 'Apps',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: 'iPhone and Android apps for Toontown Rewritten and Corporate Clash. Get TTR news as well as helpful cog and fishing guides!',
            }],
        }
    },
}
