//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'

import { FormMixin } from 'core/components/forms/mixin'

export default {
    name: 'RearrangeToonsPage',

    metaInfo() {
        return {
            title: 'Rearrange Toons',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: "Want to rearrange your toons? You've come to the right place!",
            }],
        }
    },

    mixins: [FormMixin],

    fields: [],

    endpoint: '/api/toons/reorder/',

    data() {
        const toons = _.sortBy(this.$store.state.accounts.toons, ['order', 'id'])
        return { toons }
    },

    methods: {
        toggleHidden(toon) {
            this.$set(toon, 'hidden', !toon.hidden)
        },

        clean() {
            return {
                toons: _.map(this.toons, t => ({ id: t.id, hidden: t.hidden })),
            }
        },

        onSubmitSuccess() {
            _.each(this.toons, (toon, idx) => {
                this.$set(toon, 'order', idx + 1)
            })
            this.$router.safePush('/settings/')
        },
    },
}
