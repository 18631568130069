//
//
//
//
//
//
//
//
//

const sources = {
    1: {
        name: 'Note',
        icon: 'fa-info-circle',
        color: '#747474',
    },
    2: {
        name: 'ToonHQ Admin',
        icon: 'fa-shield-alt',
        color: '#d84444',
    },
    3: {
        name: 'ToonHQ Mod',
        icon: 'fa-shield-alt',
        color: '#4c7dc5',
    },
}

export default {
    name: 'SpeedchatAlert',

    props: ['message'],

    computed: {
        source() {
            return sources[this.message.source]
        },
    },
}
