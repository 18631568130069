//
//
//
//
//
//
//
//
//
//
//
//

import FlowerRow from './flower-row'

export default {
    name: 'FlowerTable',

    components: { FlowerRow },

    props: {
        name: {
            type: String,
            required: true,
        },

        flowers: {
            type: Array,
            required: true,
        },
    },
}
