//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'

import { mapState, mapGetters } from 'vuex'

export default {
    computed: {
        groupTypeOptions() {
            return this.groupTypeList
        },

        districtOptions() {
            const options = [
                { id: null, name: 'Any' },
                { id: 'invaded', name: 'Invaded District' },
                { id: 'noninvaded', name: 'Non-Invaded District' },
            ]

            if (this.gameId === 1) options.push({ id: 'speedchatonly', name: 'Speedchat Only District' })
            if (this.gameId === 1) options.push({ id: 'nonspeedchatonly', name: 'Speedchat Plus District' })

            return options
        },

        areaOptions() {
            const options = []
            _.each(this.locationList, (l) => {
                if (l.hood === l.id) {
                    let areaName = l.name
                    if (areaName.indexOf('Playground') > -1) areaName = areaName.substr(0, areaName.length - 10)
                    options.push({ id: l.hood, name: areaName })
                }
            })
            return options
        },

        fullnessOptions() {
            return [
                { id: true, name: 'Show Full Groups' },
                { id: false, name: 'Hide Full Groups' },
            ]
        },

        sortOptions() {
            return [
                { id: 'oldest', name: 'Oldest' },
                { id: 'newest', name: 'Newest' },
                { id: 'most_toons', name: 'Most Toons' },
                { id: 'least_toons', name: 'Least Toons' },
            ]
        },

        ...mapGetters(['gameId', 'groupTypeList', 'locationList']),
        ...mapState({
            filters: state => state.groups.filters,
        }),
    },

    methods: {
        val(value, options) {
            return _.find(options, { id: value })
        },

        valMultiple(values, options) {
            return _.filter(options, o => values.includes(o.id))
        },

        updateFilter(name, value) {
            this.$store.commit('setGroupFilters', { ...this.filters, [name]: value })
        },

        updateMultipleFilter(name, value) {
            this.$store.commit('setGroupFilters', { ...this.filters, [name]: _.map(value, 'id') })
        },
    },
}
