//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ModalFormMixin } from 'core/components/forms/mixin'

export default {
    name: 'DeleteAccountForm',

    mixins: [ModalFormMixin],

    fields: [],

    modalClass: 'modal-content modal-content--sm',

    dispatch: 'deleteAccount',

    methods: {
        onSubmitSuccess() {
            this.$router.push('/')
        },
    },
}
