//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'

import PulseMixin from 'core/util/pulse'
import FieldOfficesList from 'fos/components/field-offices-list'

export default {
    name: 'FieldOfficesPage',

    components: { FieldOfficesList },

    metaInfo() {
        return {
            title: 'Field Office Tracker',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: 'A beautiful TTR field office tracker. Find field offices, check how many annexes are left, and see if you can hop in!',
            }],
        }
    },

    theme: 'fos',

    mixins: [PulseMixin],

    data() {
        return {
        }
    },

    computed: {
        ...mapGetters(['fieldOfficeList', 'socketIssue', 'socketOutdated']),
    },

    methods: {
        async prepare() {
            return Promise.all([
                this.load('locations'),
                this.trackFieldOffices('fos'),
            ])
        },

        ...mapActions(['load', 'trackFieldOffices', 'stopTrackingFieldOffices']),
    },

    beforeDestroy() {
        this.stopTrackingFieldOffices('fos')
    },
}
