//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        text: {
            type: String,
            required: false,
        },
        prompt: {
            type: String,
            required: false,
        },
        image: {
            type: Boolean,
            default: false,
        },
        close: {
            type: Boolean,
            default: true,
        },
    },
}
