//
//
//
//
//

import FieldOfficeCard from './field-office-card'

export default {
    props: ['fieldOffices'],

    components: { FieldOfficeCard },
}
