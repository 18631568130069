import ToonSyncCodeFlowForm from 'accounts/components/toonsync-code-flow-form'
import TTRParallax from 'core/img/home/parallax.jpg'
import CCParallax from 'core/img/home/cc_parallax.jpg'

export default {
    1: {
        id: 1,
        name: 'Toontown Rewritten',
        shortName: 'TTR',
        prefix: 'ttr',
        buttonClass: 'ttr',
        parallax: TTRParallax,
        parallaxWidth: 9223,
        maxInvasionTimeSeconds: totalCogs => totalCogs * 0.7,
        toonSync: true,
        toonSyncFlow: ToonSyncCodeFlowForm,
        toonSyncOauthUrl: '/oauth/link/ttr/',
        toonSyncForceSyncAction: 'syncTTRAccount',
        poses: [
            { id: 'relaxed', name: 'Relaxed' },
            { id: 'delighted', name: 'Delighted' },
            { id: 'sleep', name: 'Sleepy' },
            { id: 'surprise', name: 'Surprised' },
            { id: 'thinking', name: 'Thinking' },
            { id: 'birthday', name: 'Birthday' },

            { id: 'cake_top', name: 'Cake Topper' },
        ],
        toonPhotoDnaRegex: /https:\/\/rendition\.toontownrewritten\.com\/render\/(\w+)\//,
        toonPhotoUrl: (dna, pose) => {
            const pose_keys = {
                relaxed: 'portrait',
                cake_top: 'cake-topper',
            }
            const pose_key = pose_keys[pose] || `portrait-${pose}`
            return `https://rendition.toontownrewritten.com/render/${dna}/${pose_key}/256x256.webp`
        },
    },

    2: {
        id: 2,
        name: 'Corporate Clash',
        shortName: 'CC',
        prefix: 'cc',
        buttonClass: 'clash',
        parallax: CCParallax,
        parallaxWidth: 11298,
        toonSync: true,
        toonSyncFlow: null,
        toonSyncOauthUrl: '/oauth/link/cc/',
        toonSyncForceSyncAction: 'syncCcAccount',
        poses: [],
        toonPhotoDnaRegex: null,
        toonPhotoUrl: null,
    },
}

export const gameUrl = (gameId, path) => {
    if (gameId === 1) return path
    if (gameId === 2) return `/cc${path}`
    throw new Error('Unkown game')
}
