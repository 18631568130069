//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

import { gameTracks } from 'core/util/gags'

export default {
    name: 'OrganicGagsPage',

    metaInfo() {
        return {
            title: 'Organic Gags',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: `Check out the different bonuses offered from growing your own organic gags in ${this.game.name}.`,
            }],
        }
    },

    computed: {
        gagTracks() {
            return gameTracks[this.game.id]
        },

        ...mapGetters(['game']),
    },
}
