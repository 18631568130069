import axios from 'axios'
import _ from 'lodash'
import Vue from 'vue'

const initialState = window.STATE

export default {
    state: {
        inUseBy: null,
        fieldOffices: initialState.field_offices ? _.keyBy(initialState.field_offices, 'id') : null,
    },

    getters: {
        fieldOfficeList: state => _.sortBy(state.fieldOffices, ['difficulty', 'id']),
    },

    mutations: {
        setFieldOffice(state, fo) {
            state.fieldOffices[fo.id] = fo
        },

        archiveFieldOffice(state, id) {
            Vue.delete(state.fieldOffices, id)
        },

        setFieldOffices(state, fos) {
            state.fieldOffices = fos
        },
    },

    actions: {
        trackFieldOffices({ dispatch, state }, controller) {
            const loadInitialData = !state.inUseBy
            state.inUseBy = controller
            dispatch('joinRoom', { room: 'fos', service: 'fosStore' })
            if (!loadInitialData) return

            if (initialState.field_offices) {
                initialState.field_offices = null
            } else {
                return dispatch('loadFieldOffices')
            }
        },

        stopTrackingFieldOffices({ dispatch, state }, controller) {
            if (controller !== state.inUseBy) {
                console.log('[fos] ignoring stop because we are in use by a different controller')
                return
            }

            dispatch('leaveRoom', { room: 'fos', service: 'fosStore' })
            state.inUseBy = null
        },

        registerSocketHandlers({ commit }, { socket }) {
            socket.on('fo_updated', fo => commit('setFieldOffice', fo))
            socket.on('fo_archived', fo => commit('archiveFieldOffice', fo.id))
        },

        roomReconnected({ dispatch }, { room }) {
            if (room.room === 'fos') dispatch('loadFieldOffices')
        },

        async loadFieldOffices({ commit }) {
            const response = await axios.get('/api/field_offices/')
            commit('setFieldOffices', response.data.field_offices)
            return response.data.field_offices
        },
    },
}
