//
//
//
//

import { getCurrentProvider } from './providers'

export default {
    name: 'AdController',

    data() {
        const controller = getCurrentProvider()?.controller
        return { controller }
    },
}
