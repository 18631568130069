//
//
//
//

import { isSafari } from 'core/util/browsers'

export default {
    props: {
        value: {
            type: String,
            default: '',
        },

        maxHeight: {
            type: Number,
            default: 100,
        },
    },

    methods: {
        resize(e) {
            if (!isSafari) {
                const { textarea } = this.$refs
                textarea.style.height = 'auto'
                textarea.style.height = `${Math.min(textarea.scrollHeight, this.maxHeight)}px`
            }
            if (e) this.$emit('input', e.target.value)
        },
    },

    mounted() {
        this.$nextTick(() => this.resize())
    },

    watch: {
        value(val) {
            // Reset to default when the user sends a message
            if (val === '') this.$nextTick(() => this.resize())
        },
    },
}
