import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

import games from 'core/util/games'
import radio from 'core/util/radio'
import { secsToTime, timeToLongString } from 'core/util/time'

const initialState = window.STATE

const notifyCogs = _.mapValues(games, () => [])

const previouslyProcessedInvasions = new Set()
const warnedEndingSoon = new Set()

const trackPromiseData = {
    promise: null,
    resolve: null,
}

const resolveTrackPromise = () => {
    if (!trackPromiseData.promise) return
    trackPromiseData.resolve()
    trackPromiseData.promise = null
    trackPromiseData.resolve = null
}

export default {
    state: {
        invasions: initialState.invasions ? _.keyBy(initialState.invasions, 'id') : null,
        activeInvasions: _.map(initialState.invasions || [], 'id'),
        meta: initialState.invasions ? { invasion_server_working: initialState.invasion_server_working } : null,
        pastInvasionsUrl: null,
        noMorePastInvasions: false,
        statistics: null,

        notifyAllCogs: true,
        notifyCogs,
        notifySounds: true,

        inUseBy: null,
    },

    getters: {
        activeInvasions: state => _.sortBy(_.map(state.activeInvasions, id => state.invasions[id]), 'id'),

        pastInvasions: state => _.sortBy(_.filter(state.invasions, invasion => state.activeInvasions.indexOf(invasion.id) === -1), i => -i.end_time),

        keyedActiveInvasions: (state, getters) => _.keyBy(getters.activeInvasions, 'district'),

        isActiveInvasion: state => invasion => state.activeInvasions.indexOf(invasion.id) > -1,

        invasionDuration: () => invasion => `Lasted ${timeToLongString(secsToTime(invasion.end_time - invasion.start_time, true))}`,

        timeRemaining: (state, getters) => (invasion, longFormat) => {
            // Check for mega invasion
            // (This might still be for CC? Not sure. It can be removed if not! TTR is handled below.)
            if (invasion.total === 100000) return 'HAVE FUN!'

            // Calculate the time remaining
            const unix = moment().unix()
            let totalSeconds
            if (getters.gameId === 1 && invasion.total === 1000000) {
                totalSeconds = (invasion.start_time + 10800) - unix
            } else {
                if (invasion.time_remaining === null) {
                    const defeatRate = invasion.defeat_rate
                    const cogsLeft = invasion.total - invasion.defeated
                    totalSeconds = cogsLeft / defeatRate
                } else {
                    totalSeconds = invasion.time_remaining
                }

                // Account for time since we loaded the data
                const timeSince = unix - invasion.as_of
                totalSeconds -= timeSince
            }

            // Cap the remaining time if the game has a max invasion time
            if (getters.game.maxInvasionTimeSeconds) {
                const maxTime = getters.game.maxInvasionTimeSeconds(invasion.total)
                const maxEnd = invasion.start_time + maxTime
                const maxLeft = maxEnd - unix
                totalSeconds = Math.min(totalSeconds, maxLeft)
            }

            // Broadcast ending soon if under 3 minutes
            if (totalSeconds <= 180 && !warnedEndingSoon.has(invasion.id)) {
                if (previouslyProcessedInvasions.has(invasion.id)) {
                    radio.$emit('invasions:ending-soon', invasion)
                }
                warnedEndingSoon.add(invasion.id)
            }
            if (!previouslyProcessedInvasions.has(invasion.id)) previouslyProcessedInvasions.add(invasion.id)

            if (totalSeconds < 0) return 'Ending very soon...'

            const est = secsToTime(totalSeconds, longFormat)
            if (!est) return 'Calculating estimate...'

            if (!longFormat) {
                est.hours = est.hours > 0 ? `${est.hours}:` : ''
                return `${est.hours}${est.minutes}:${est.seconds} remaining`
            }
            return timeToLongString(est)
        },

        timeSinceLastInvasion: (state, getters) => {
            const lastInvasion = getters.pastInvasions[0]
            if (!lastInvasion) return null
            const secondsSince = moment().unix() - lastInvasion.end_time
            const timeSince = secsToTime(secondsSince, true)
            return timeToLongString(timeSince)
        },

        notifyCogNames: (state, getters) => state.notifyCogs[getters.game.id],

        shouldNotifyForCog: (state, getters) => (cog) => {
            if (state.notifyAllCogs) return true
            const searchValues = [cog.name]
            if (cog.name.includes('Skelecog')) searchValues.push(`__${cog.type}__SKELECOG__`)
            if (cog.name.includes('Version 2.0')) searchValues.push(`__${cog.type}__VERSION20__`)
            return !!_.find(getters.notifyCogNames, cogName => _.find(searchValues, searchVal => searchVal.includes(cogName)))
        },
    },

    mutations: {
        setActiveInvasions(state, { invasions, invasion_server_working }) { // eslint-disable-line camelcase
            // Determine which invasions are brand new
            let brandNewInvasions = []
            if (state.meta) {
                brandNewInvasions = _.filter(invasions, i => !!state.invasions[i.id] === false)
            }

            // Determine the new invasions and merge the invasions data
            const newActiveInvasions = _.map(invasions, 'id')
            state.invasions = { ...state.invasions, ..._.keyBy(invasions, 'id') }

            // Add an end date to any previously active invasions (that aren't)
            const expiredInvasions = _.difference(state.activeInvasions, newActiveInvasions)
            _.each(expiredInvasions, (id) => {
                state.invasions[id].end_time = moment().unix()
                radio.$emit('invasions:ended', state.invasions[id])
            })

            // Set metadata
            state.activeInvasions = newActiveInvasions
            state.meta = { invasion_server_working }

            // Notify about new invasions
            _.each(brandNewInvasions, (invasion) => {
                radio.$emit('invasions:started', invasion)
            })
        },

        setPastInvasions(state, { results, next }) {
            state.invasions = { ...state.invasions, ..._.keyBy(results, 'id') }
            state.pastInvasionsUrl = next || false
        },

        setInvasionStatistics(state, statistics) {
            state.statistics = statistics
        },

        toggleNotifyAllCogs(state) {
            state.notifyAllCogs = !state.notifyAllCogs
        },

        setCogNotificationsForGame(state, { game, cogs }) {
            state.notifyCogs[game] = cogs
            state.notifyAllCogs = false
        },

        toggleNotifySounds(state) {
            state.notifySounds = !state.notifySounds
        },
    },

    actions: {
        loadPastInvasions({ commit, state, getters }, initial) {
            if (state.pastInvasionsUrl === null) state.pastInvasionsUrl = `/api/past_invasions/${getters.gameId}/`
            if (state.pastInvasionsUrl === false) {
                state.noMorePastInvasions = true
                return true
            }
            if (initial && state.pastInvasionsUrl.indexOf('?') > -1) return true
            return axios.get(state.pastInvasionsUrl).then((response) => {
                commit('setPastInvasions', response.data)
            })
        },

        loadInvasionStatistics({ commit, state, getters }) {
            if (state.statistics) return true
            return axios.get(`/api/invasion_statistics/${getters.gameId}/`).then((response) => {
                commit('setInvasionStatistics', response.data)
            })
        },

        trackInvasions({ commit, dispatch, state, getters }, controller) {
            console.log(`[invasions] trackInvsions called for game ${getters.gameId}`)
            trackPromiseData.promise = new Promise((resolve) => {
                trackPromiseData.resolve = resolve
            })
            state.inUseBy = controller
            dispatch('joinRoom', { room: 'invasions', game: getters.gameId, service: 'invasionsStore' })

            // Immediately resolve if we are using initial data
            if (initialState.invasions) {
                console.log('[invasions] using initialState for data')
                initialState.invasions = null
                return resolveTrackPromise()
            }

            if (getters.roomConnectedToGame('invasions', getters.gameId)) {
                // We're already connected and loaded for this game
                console.log('[invasions] trackInvasions is already configured for the right game')
                return resolveTrackPromise()
            }

            // Load the initial data
            console.log('[invasions] loading invasions from API...')
            axios.get(`/api/invasions/${getters.gameId}/`).then((response) => {
                commit('setActiveInvasions', response.data)
                commit('updateGameOpen', response.data.game_open)
                resolveTrackPromise()
            })

            return trackPromiseData.promise
        },

        stopTrackingInvasions({ dispatch, state }, controller) {
            if (controller !== state.inUseBy) {
                console.log('[invasions] ignoring stop because we are in use by a different controller')
                return
            }

            dispatch('leaveRoom', { room: 'invasions', service: 'invasionsStore' })
            state.inUseBy = null
        },

        registerSocketHandlers({ commit, getters }, { socket }) {
            socket.on('invasions', (data) => {
                if (!getters.roomConnectedToGame('invasions', getters.gameId)) {
                    console.log('[invasions] Ignoring update data because the socket has not acknowledged our game change yet')
                    return
                }

                console.log('[invasions]', data)
                commit('setActiveInvasions', data)
                commit('updateGameOpen', data.game_open)
                resolveTrackPromise()
            })
        },

        gameChanged({ state }) {
            console.log('[invasions] game changed, clearing data...')
            state.invasions = null
            state.activeInvasions = []
            state.meta = null
            state.pastInvasionsUrl = null
            state.noMorePastInvasions = false
            state.statistics = null
        },
    },
}
