//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

import Jellybean from 'gardening/components/jellybean'
import ShapesInfo from 'gardening/components/shapes-info'
import { statues } from 'gardening/statues'

export default {
    name: 'StatuesPage',

    metaInfo() {
        return {
            title: 'Statues',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: `Make your ${this.game.name} garden stand out with one of the many statues available!`,
            }],
        }
    },

    components: { Jellybean, ShapesInfo },

    data() {
        return {
            statues,
        }
    },

    computed: {
        ...mapGetters(['game']),
    },
}
