//
//
//
//
//
//
//

export default {
    props: ['cover', 'white'],
}
