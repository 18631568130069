//
//
//
//
//

import GroupCard from './group-card'

export default {
    props: ['groups'],

    components: { GroupCard },
}
