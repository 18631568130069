//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ModalFormMixin } from 'core/components/forms/mixin'

export default {
    props: ['resetToken'],

    mixins: [ModalFormMixin],

    modalClass: 'modal-content modal-content--sm',

    fields: [
        'password',
    ],

    dispatch: 'completePasswordReset',

    methods: {
        clean(data) {
            data.token = this.resetToken
            return data
        },

        onSubmitSuccess() {
            ModalFormMixin.methods.onSubmitSuccess.call(this)
            this.$router.safePush('/')
        },
    },
}
