import { render, staticRenderFns } from "./3.8.20.vue?vue&type=template&id=c316ff08&lang=pug&"
import script from "./3.8.20.vue?vue&type=script&lang=js&"
export * from "./3.8.20.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports