import { audioManager } from 'core/util/audio'

const { Notification } = window

const hasPermission = (permission) => {
    if (!permission) return null
    if (permission === 'granted') return true
    if (permission === 'denied') return false
    return null
}

const defaultNotificationTypes = {
    invasionStarted: false,
    invasionEndingSoon: false,
    invasionEnded: false,
}

export const notificationsStore = {
    state: {
        supported: !!Notification,
        permission: hasPermission(Notification?.permission),
        notificationTypes: { ...defaultNotificationTypes },
    },

    getters: {
        notificationsSupported: state => state.supported,
        notificationsApproved: state => state.permission,
    },

    mutations: {
        updateNotificationPermission(state, permission) {
            state.permission = permission
        },

        updateNotificationPreference(state, { type, value }) {
            state.notificationTypes[type] = value
        },

        resetNotificationPreferences(state) {
            state.notificationTypes = { ...defaultNotificationTypes }
        },

        markUnsupported(state) {
            state.supported = false
        },
    },

    actions: {
        initNotifications({ commit, state }) {
            if (!state.permission) commit('resetNotificationPreferences')
        },

        async requestNotificationPermission({ commit }) {
            try {
                const result = await Notification.requestPermission()
                const hasPerm = hasPermission(result)
                commit('updateNotificationPermission', hasPerm)
                return hasPerm
            } catch (e) {
                commit('markUnsupported')
                return null
            }
        },

        async setNotificationPreference({ commit, dispatch, getters }, { type, value }) {
            // Check if we already have permission for notifications
            if (!getters.notificationsApproved) {
                // We don't have permission yet, ask the user for permission before toggling the value
                const result = await dispatch('requestNotificationPermission')

                // If the user said no, we can stop now
                if (!result) return false
            }

            // We have permission, go ahead and set the value
            commit('updateNotificationPreference', { type, value })
            return true
        },
    },
}

export const showNotification = (title, body, icon, sound) => {
    const notification = new Notification(title, { body, icon })
    if (sound) audioManager.play(sound)
    return notification
}
