//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'

import ToonsPreview from 'accounts/components/toons-preview'
import UnlinkOAuthForm from 'accounts/components/unlink-oauth-form'
import { datetime } from 'core/util/filters'
import games from 'core/util/games'

export default {
    name: 'GameToonSyncSummary',

    components: { ToonsPreview },

    props: {
        gameId: {
            type: Number,
            required: true,
        },
    },

    computed: {
        game() {
            return games[this.gameId]
        },

        accounts() {
            return this.accountsForGame(this.gameId)
        },

        linkUrl() {
            if (this.game.toonSyncFlow) return null
            return `${this.game.toonSyncOauthUrl}?next=/settings`
        },

        eventHandlers() {
            if (!this.game.toonSyncFlow) return
            return {
                click: () => {
                    this.$present(this.game.toonSyncFlow)
                },
            }
        },

        ...mapGetters(['accountsForGame']),
        ...mapState({
            toons: state => state.accounts.toons,
        }),
    },

    methods: {
        toonsForAccount(account) {
            return _.sortBy(_.filter(this.toons, { oauth_id: account.id }), 'order')
        },

        async syncAccount(account) {
            this.$set(account, 'syncing', true)
            try {
                await this.$store.dispatch(this.game.toonSyncForceSyncAction, account.id)
            } catch (e) {
                this.$set(account, 'syncError', this.$drf.parseError(e).squashed)
            }
        },

        unlink(account) {
            this.$present(UnlinkOAuthForm, { service: games[account.game].prefix, account: account.id })
        },

        datetime,
    },
}
