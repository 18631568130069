//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ModalFormMixin } from 'core/components/forms/mixin'
import celebrate from 'core/util/celebrate'

export default {
    name: 'RedeemSpecialCodeForm',

    mixins: [ModalFormMixin],

    modalClass: 'modal-content modal-content--sm',

    fields: [
        'code',
    ],

    customErrorTranslations: {
        'This value does not match the required pattern.': "That doesn't look like a valid code.",
    },

    dispatch: 'redeemSpecialCode',

    data() {
        return {
            unlocked: null,
        }
    },

    methods: {
        clean(data) {
            // Clean up the code
            if (!data.code?.length) return false
            data.code = data.code.toUpperCase().replace(/[^A-Z0-9]/g, '')

            return data
        },

        onSubmitSuccess(data, response) {
            this.submitted = true
            this.unlocked = response.unlockable
            celebrate()
        },
    },
}
