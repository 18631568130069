//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from 'vuex'

import { BeanColor } from 'gardening/flowers'

export default {
    name: 'ShapesInfo',

    data() {
        return {
            BeanColor,
            hintOptions: [
                { text: 'Plain', value: null },
                { text: 'Numbers', value: 'pos' },
                { text: 'Shapes', value: 'shape' },
            ],
        }
    },

    computed: {
        ...mapState({
            jellybeanHint: state => state.gardening.jellybeanHint,
        }),
    },

    methods: {
        ...mapMutations(['setJellybeanHint']),
    },
}
