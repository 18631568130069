import _ from 'lodash'

const ttrTracks = [
    {
        id: 'toonup',
        name: 'Toon-Up',
        color: '#c659e8',
        metric: 'laff',
        gags: [
            {
                name: 'Feather',
                image: 'ttr/feather.png',
                base: 10,
                organic: 11,
            },
            {
                name: 'Megaphone',
                image: 'ttr/megaphone.png',
                base: 18,
                organic: 19,
            },
            {
                name: 'Lipstick',
                image: 'ttr/lipstick.png',
                base: 30,
                organic: 33,
            },
            {
                name: 'Bamboo Cane',
                image: 'ttr/bamboo_cane.png',
                base: 45,
                organic: 49,
            },
            {
                name: 'Pixie Dust',
                image: 'ttr/pixie_dust.png',
                base: 60,
                organic: 66,
            },
            {
                name: 'Juggling Balls',
                image: 'ttr/juggling_cubes.png',
                base: 105,
                organic: 115,
            },
            {
                name: 'High Dive',
                image: 'ttr/high_dive.png',
                base: 210,
                organic: 231,
            },
        ],
    },

    {
        id: 'trap',
        name: 'Trap',
        color: '#e8e65a',
        metric: 'damage',
        gags: [
            {
                name: 'Banana Peel',
                image: 'ttr/banana_peel.png',
                base: 12,
                organic: 13,
            },
            {
                name: 'Rake',
                image: 'ttr/rake.png',
                base: 20,
                organic: 22,
            },
            {
                name: 'Marbles',
                image: 'ttr/marbles.png',
                base: 35,
                organic: 38,
            },
            {
                name: 'Quicksand',
                image: 'ttr/quicksand.png',
                base: 50,
                organic: 55,
            },
            {
                name: 'Trapdoor',
                image: 'ttr/trapdoor.png',
                base: 85,
                organic: 94,
            },
            {
                name: 'TNT',
                image: 'ttr/tnt.png',
                base: 180,
                organic: 198,
            },
            {
                name: 'Railroad',
                image: 'ttr/railroad.png',
                base: 200,
                organic: 220,
            },
        ],
    },

    {
        id: 'lure',
        name: 'Lure',
        color: '#31be35',
        metric: 'base accuracy',
        metricSuffix: '%',
        gags: [
            {
                name: '$1 Bill',
                image: 'ttr/1_bill.png',
                base: 50,
                organic: 60,
            },
            {
                name: 'Small Magnet',
                image: 'ttr/small_magnet.png',
                base: 50,
                organic: 60,
            },
            {
                name: '$5 Bill',
                image: 'ttr/5_bill.png',
                base: 60,
                organic: 70,
            },
            {
                name: 'Big Magnet',
                image: 'ttr/big_magnet.png',
                base: 60,
                organic: 70,
            },
            {
                name: '$10 Bill',
                image: 'ttr/10_bill.png',
                base: 70,
                organic: 80,
            },
            {
                name: 'Hypno Goggles',
                image: 'ttr/hypno_goggles.png',
                base: 70,
                organic: 80,
            },
            {
                name: 'Presentation',
                image: 'ttr/presentation.png',
                base: 95,
                organic: 95,
            },
        ],
    },

    {
        id: 'sound',
        name: 'Sound',
        color: '#3e5ceb',
        metric: 'damage',
        gags: [
            {
                name: 'Bike Horn',
                image: 'ttr/bike_horn.png',
                base: 4,
                organic: 5,
            },
            {
                name: 'Whistle',
                image: 'ttr/whistle.png',
                base: 7,
                organic: 8,
            },
            {
                name: 'Bugle',
                image: 'ttr/bugle.png',
                base: 11,
                organic: 12,
            },
            {
                name: 'Aoogah',
                image: 'ttr/aoogah.png',
                base: 16,
                organic: 17,
            },
            {
                name: 'Elephant Trunk',
                image: 'ttr/elephant_trunk.png',
                base: 21,
                organic: 23,
            },
            {
                name: 'Foghorn',
                image: 'ttr/foghorn.png',
                base: 50,
                organic: 55,
            },
            {
                name: 'Opera Singer',
                image: 'ttr/opera_singer.png',
                base: 90,
                organic: 99,
            },
        ],
    },

    {
        id: 'throw',
        name: 'Throw',
        color: '#eda032',
        metric: 'damage',
        gags: [
            {
                name: 'Cupcake',
                image: 'ttr/cupcake.png',
                base: 6,
                organic: 7,
            },
            {
                name: 'Fruit Pie Slice',
                image: 'ttr/fruit_pie_slice.png',
                base: 10,
                organic: 11,
            },
            {
                name: 'Cream Pie Slice',
                image: 'ttr/cream_pie_slice.png',
                base: 17,
                organic: 18,
            },
            {
                name: 'Whole Fruit Pie',
                image: 'ttr/whole_fruit_pie.png',
                base: 27,
                organic: 29,
            },
            {
                name: 'Whole Cream Pie',
                image: 'ttr/whole_cream_pie.png',
                base: 40,
                organic: 44,
            },
            {
                name: 'Birthday Cake',
                image: 'ttr/birthday_cake.png',
                base: 100,
                organic: 110,
            },
            {
                name: 'Wedding Cake',
                image: 'ttr/wedding_cake.png',
                base: 120,
                organic: 132,
            },
        ],
    },

    {
        id: 'squirt',
        name: 'Squirt',
        color: '#f55bd6',
        metric: 'damage',
        gags: [
            {
                name: 'Squirting Flower',
                image: 'ttr/squirting_flower.png',
                base: 4,
                organic: 5,
            },
            {
                name: 'Glass of Water',
                image: 'ttr/glass_of_water.png',
                base: 8,
                organic: 9,
            },
            {
                name: 'Squirt Gun',
                image: 'ttr/squirt_gun.png',
                base: 12,
                organic: 13,
            },
            {
                name: 'Seltzer Bottle',
                image: 'ttr/seltzer_bottle.png',
                base: 21,
                organic: 23,
            },
            {
                name: 'Fire Hose',
                image: 'ttr/fire_hose.png',
                base: 30,
                organic: 33,
            },
            {
                name: 'Storm Cloud',
                image: 'ttr/storm_cloud.png',
                base: 80,
                organic: 88,
            },
            {
                name: 'Geyser',
                image: 'ttr/geyser.png',
                base: 105,
                organic: 115,
            },
        ],
    },

    {
        id: 'drop',
        name: 'Drop',
        color: '#35eaed',
        metric: 'damage',
        gags: [
            {
                name: 'Flower Pot',
                image: 'ttr/flower_pot.png',
                base: 10,
                organic: 11,
            },
            {
                name: 'Sandbag',
                image: 'ttr/sandbag.png',
                base: 18,
                organic: 19,
            },
            {
                name: 'Anvil',
                image: 'ttr/anvil.png',
                base: 30,
                organic: 33,
            },
            {
                name: 'Big Weight',
                image: 'ttr/big_weight.png',
                base: 45,
                organic: 49,
            },
            {
                name: 'Safe',
                image: 'ttr/safe.png',
                base: 70,
                organic: 77,
            },
            {
                name: 'Grand Piano',
                image: 'ttr/grand_piano.png',
                base: 170,
                organic: 187,
            },
            {
                name: 'Toontanic',
                image: 'ttr/toontanic.png',
                base: 180,
                organic: 198,
            },
        ],
    },
]

const ccTracks = [
    {
        id: 'toonup',
        name: 'Toon-Up',
        color: '#c687f0',
        gags: [
            {
                name: 'Feather',
                image: 'ttcc/feather.png',
            },
            {
                name: 'Megaphone',
                image: 'ttcc/megaphone.png',
            },
            {
                name: 'Lipstick',
                image: 'ttcc/lipstick.png',
            },
            {
                name: 'Bamboo Cane',
                image: 'ttcc/cane.png',
            },
            {
                name: 'Pixie Dust',
                image: 'ttcc/pixie_dust.png',
            },
            {
                name: 'Juggling Balls',
                image: 'ttcc/juggling_balls.png',
            },
            {
                name: 'Confetti Cannon',
                image: 'ttcc/confetti_cannon.png',
            },
            {
                name: 'High Dive',
                image: 'ttcc/ladder.png',
            },
        ],
    },

    {
        id: 'trap',
        name: 'Trap',
        color: '#f04a46',
        gags: [
            {
                name: 'Banana Peel',
                image: 'ttcc/banana.png',
            },
            {
                name: 'Rake',
                image: 'ttcc/rake.png',
            },
            {
                name: 'Springboard',
                image: 'ttcc/spring.png',
            },
            {
                name: 'Marbles',
                image: 'ttcc/marbles.png',
            },
            {
                name: 'Quicksand',
                image: 'ttcc/sandtrap.png',
            },
            {
                name: 'Trapdoor',
                image: 'ttcc/trapdoor.png',
            },
            {
                name: 'Wrecking Ball',
                image: 'ttcc/wrecking_ball.png',
            },
            {
                name: 'TNT',
                image: 'ttcc/tnt.png',
            },
        ],
    },

    {
        id: 'lure',
        name: 'Lure',
        color: '#46b240',
        gags: [
            {
                name: '$1 Bill',
                image: 'ttcc/1_bill.png',
            },
            {
                name: 'Small Magnet',
                image: 'ttcc/red_magnet.png',
            },
            {
                name: '$5 Bill',
                image: 'ttcc/5_bill.png',
            },
            {
                name: 'Big Magnet',
                image: 'ttcc/blue_magnet.png',
            },
            {
                name: '$10 Bill',
                image: 'ttcc/10_bill.png',
            },
            {
                name: 'Hypno Goggles',
                image: 'ttcc/hypno_goggles.png',
            },
            {
                name: '$50 Bill',
                image: 'ttcc/50_bill.png',
            },
            {
                name: 'Presentation',
                image: 'ttcc/presentation.png',
            },
        ],
    },

    {
        id: 'sound',
        name: 'Sound',
        color: '#5761e3',
        gags: [
            {
                name: 'Kazoo',
                image: 'ttcc/kazoo.png',
            },
            {
                name: 'Bike Horn',
                image: 'ttcc/bike_horn.png',
            },
            {
                name: 'Whistle',
                image: 'ttcc/whistle.png',
            },
            {
                name: 'Bugle',
                image: 'ttcc/bugle.png',
            },
            {
                name: 'Aoogah',
                image: 'ttcc/aoogah.png',
            },
            {
                name: 'Elephant Trunk',
                image: 'ttcc/trunk.png',
            },
            {
                name: 'Foghorn',
                image: 'ttcc/foghorn.png',
            },
            {
                name: 'Opera Singer',
                image: 'ttcc/opera.png',
            },
        ],
    },

    {
        id: 'squirt',
        name: 'Squirt',
        color: '#f134bc',
        gags: [
            {
                name: 'Squirting Flower',
                image: 'ttcc/flower.png',
            },
            {
                name: 'Glass of Water',
                image: 'ttcc/glass.png',
            },
            {
                name: 'Squirt Gun',
                image: 'ttcc/water_gun.png',
            },
            {
                name: 'Water Balloon',
                image: 'ttcc/water_balloon.png',
            },
            {
                name: 'Seltzer Bottle',
                image: 'ttcc/seltzer.png',
            },
            {
                name: 'Fire Hose',
                image: 'ttcc/hose.png',
            },
            {
                name: 'Storm Cloud',
                image: 'ttcc/cloud.png',
            },
            {
                name: 'Geyser',
                image: 'ttcc/geyser.png',
            },
        ],
    },

    {
        id: 'zap',
        name: 'Zap',
        color: '#e8f048',
        gags: [
            {
                name: 'Joy Buzzer',
                image: 'ttcc/buzzer.png',
            },
            {
                name: 'Rug',
                image: 'ttcc/carpet.png',
            },
            {
                name: 'Balloon',
                image: 'ttcc/balloon.png',
            },
            {
                name: 'Kart Battery',
                image: 'ttcc/battery.png',
            },
            {
                name: 'Taser',
                image: 'ttcc/taser.png',
            },
            {
                name: 'Broken TV',
                image: 'ttcc/tv.png',
            },
            {
                name: 'Tesla Coil',
                image: 'ttcc/tesla_coil.png',
            },
            {
                name: 'Lightning',
                image: 'ttcc/thunder.png',
            },
        ],
    },

    {
        id: 'throw',
        name: 'Throw',
        color: '#f08833',
        gags: [
            {
                name: 'Cupcake',
                image: 'ttcc/cupcake.png',
            },
            {
                name: 'Fruit Pie Slice',
                image: 'ttcc/fruit_pie_slice.png',
            },
            {
                name: 'Cream Pie Slice',
                image: 'ttcc/cream_pie_slice.png',
            },
            {
                name: 'Birthday Cake Slice',
                image: 'ttcc/birthday_cake_slice.png',
            },
            {
                name: 'Whole Fruit Pie',
                image: 'ttcc/fruit_pie.png',
            },
            {
                name: 'Whole Cream Pie',
                image: 'ttcc/cream_pie.png',
            },
            {
                name: 'Birthday Cake',
                image: 'ttcc/birthday_cake.png',
            },
            {
                name: 'Wedding Cake',
                image: 'ttcc/wedding_cake.png',
            },
        ],
    },

    {
        id: 'drop',
        name: 'Drop',
        color: '#32e3f0',
        gags: [
            {
                name: 'Flower Pot',
                image: 'ttcc/flower_pot.png',
            },
            {
                name: 'Sandbag',
                image: 'ttcc/sandbag.png',
            },
            {
                name: 'Bowling Ball',
                image: 'ttcc/bowling_ball.png',
            },
            {
                name: 'Anvil',
                image: 'ttcc/anvil.png',
            },
            {
                name: 'Big Weight',
                image: 'ttcc/big_weight.png',
            },
            {
                name: 'Safe',
                image: 'ttcc/safe.png',
            },
            {
                name: 'Boulder',
                image: 'ttcc/boulder.png',
            },
            {
                name: 'Grand Piano',
                image: 'ttcc/piano.png',
            },
        ],
    },
]

export const keyedTtrTracks = _.keyBy(ttrTracks, 'id')

export const keyedCcTracks = _.keyBy(ccTracks, 'id')

export const gameTracks = {
    1: ttrTracks,
    2: ccTracks,
}

export const keyedGameTracks = {
    1: keyedTtrTracks,
    2: keyedCcTracks,
}
