//
//
//
//
//
//
//
//
//
//
//

import { mixin as clickaway } from 'vue-clickaway'

export default {
    props: ['right'],

    mixins: [clickaway],

    data() {
        return {
            open: false,
        }
    },

    methods: {
        close() {
            this.open = false
        },
    },
}
