//
//
//
//
//

/**
 * There's a bug with router-link right now where using a tag other than anchor causes
 * game links to not work. It's incredibly bizarre, but as long as anchor tags are used
 * everything is fine.
 */
import { mapGetters } from 'vuex'

import { gameUrl } from 'core/util/games'

export default {
    props: {
        to: {
            type: String,
            required: true,
        },
    },

    computed: {
        calculatedTo() {
            return gameUrl(this.gameId, this.to)
        },

        ...mapGetters(['gameId']),
    },
}
