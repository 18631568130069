//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex'

import DiscordFollowupForm from 'accounts/components/discord-followup-form'
import { latestChangelogId, latestNotifyId } from 'core/changelog'
import PageFooter from 'core/components/footer'
import WhatsNew from 'core/components/whats-new'
import AdController from 'core/components/ad-controllers/AdController'
import { Vuedal } from 'core/util/modals'

export default {
    name: 'ToonHqApp',

    metaInfo() {
        const { game } = this
        return {
            title: 'Welcome',
            titleTemplate: chunk => `${chunk} | ToonHQ for ${game.name}`,
            meta: [{
                vmid: 'description',
                name: 'description',
                content: 'Your one stop for helpful guides, apps and services for Toontown Rewritten and Corporate Clash. Check out our awesome TTR invasion tracker and group tracker!',
            }],
        }
    },

    components: { PageFooter, Vuedal, AdController },

    computed: {
        ...mapGetters(['discordLinked', 'game', 'isAuthenticated', 'socketOutdated']),
        ...mapState({
            theme: state => state.core.theme,
            user: state => state.accounts.user,
        }),
    },

    methods: {
        checkChangelog(fromPageLoad) {
            if (this.user.changelog === 9001) {
                // This is a brand new user, let's set them to whatever the latest ID is
                this.ackChangelog(latestChangelogId)
            } else if (this.user.changelog < latestNotifyId && fromPageLoad) {
                // Show the user the latest changes
                setTimeout(() => {
                    this.$present(WhatsNew, { sinceId: this.user.changelog })

                    // Acknowledge the changes
                    this.ackChangelog(latestChangelogId)
                }, 100)
            }
        },

        ...mapActions(['initSocketIO', 'ackChangelog', 'startAccountsSocket', 'stopAccountsSocket']),
    },

    created() {
        this.initSocketIO()

        if (this.discordLinked && this.user.discord_public === null) {
            setTimeout(() => {
                this.$present(DiscordFollowupForm)
            }, 100)
        }

        if (this.isAuthenticated) {
            this.checkChangelog(true)
        }
    },

    watch: {
        isAuthenticated(authed) {
            if (authed) {
                this.startAccountsSocket()
                this.checkChangelog(false)
            } else {
                this.stopAccountsSocket()
            }
        },

        socketOutdated() {
            this.$toast.warning('An update is available for ToonHQ! Click here to refresh and load the update.', {
                duration: 0,
                onDismiss() {
                    window.location.reload()
                },
            })
        },
    },
}
