//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    id: 1,
    version: '3.6.0',
    date: '2022-01-18',
    notify: true,
}
