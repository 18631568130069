//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    id: 41,
    version: '3.8.22',
    date: '2024-05-25',
    notify: true,
}
