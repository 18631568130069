import invasionEndedMp3 from 'invasions/sounds/end.mp3'
import invasionEndedOgg from 'invasions/sounds/end.ogg'
import invasionEndingSoonMp3 from 'invasions/sounds/ending_soon.mp3'
import invasionEndingSoonOgg from 'invasions/sounds/ending_soon.ogg'
import invasionStartedMp3 from 'invasions/sounds/start.mp3'
import invasionStartedOgg from 'invasions/sounds/start.ogg'

import memberJoinedMp3 from 'groups/sounds/member-joined.mp3'
import memberJoinedOgg from 'groups/sounds/member-joined.ogg'
import memberLeftMp3 from 'groups/sounds/member-left.mp3'
import memberLeftOgg from 'groups/sounds/member-left.ogg'
import newMessageMp3 from 'groups/sounds/new-message.mp3'
import newMessageOgg from 'groups/sounds/new-message.ogg'

export const invasionSounds = {
    invasionEnded: {
        mp3: invasionEndedMp3,
        ogg: invasionEndedOgg,
    },

    invasionEndingSoon: {
        mp3: invasionEndingSoonMp3,
        ogg: invasionEndingSoonOgg,
    },

    invasionStarted: {
        mp3: invasionStartedMp3,
        ogg: invasionStartedOgg,
    },
}

export const groupSounds = {
    memberJoined: {
        mp3: memberJoinedMp3,
        ogg: memberJoinedOgg,
    },

    memberLeft: {
        mp3: memberLeftMp3,
        ogg: memberLeftOgg,
    },

    newMessage: {
        mp3: newMessageMp3,
        ogg: newMessageOgg,
    },
}

class AudioManager {
    constructor() {
        // Prepare a spot to store which sounds we've loaded
        this.preloaded = new Set()

        // Determine which format we're going to use
        this.format = AudioManager.detectFormat()
    }

    static detectFormat() {
        const audio = new Audio()
        if (audio.canPlayType('audio/ogg')) return 'ogg'
        if (audio.canPlayType('audio/mp3')) return 'mp3'
        return false
    }

    getSoundUrl(sound) {
        return sound[this.format]
    }

    preload(sound) {
        const soundUrl = this.getSoundUrl(sound)
        if (!soundUrl || this.preloaded.has(soundUrl)) return
        new Audio(soundUrl) // eslint-disable-line no-new
        this.preloaded.add(soundUrl)
    }

    preloadMultiple(sounds) {
        Object.keys(sounds).forEach(key => this.preload(sounds[key]))
    }

    play(sound) {
        const soundUrl = this.getSoundUrl(sound)
        if (soundUrl) new Audio(soundUrl).play()
    }
}

export const audioManager = new AudioManager()

export default audioManager
