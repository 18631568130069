export default {
    admin: {
        name: 'ToonHQ Admin',
        description: 'Creator of ToonHQ.org',
        badge: {
            background: '#d84444',
            color: 'white',
        },
        groupCorner: {
            icon: 'fas fa-star fa-spin',
            color: '#d84444',
        },
    },

    mod: {
        name: 'ToonHQ Mod',
        description: 'Moderators help keep ToonHQ.org safe, be sure to thank them!',
        badge: {
            background: '#4c7dc5',
            color: 'white',
        },
        groupCorner: {
            icon: 'fas fa-star fa-spin',
            color: '#4c7dc5',
        },
    },

    ttrd_mod: {
        name: 'TTRD Mod',
        description: 'Moderator of the TTR Community Discord (our partner Discord)',
        badge: {
            background: '#889ee0',
            color: 'white',
        },
        groupCorner: {
            icon: 'fas fa-star fa-spin',
            color: '#889ee0',
        },
    },

    ttr_staff: {
        name: 'TTR Staff',
        description: 'Member of the Toontown Rewritten Team',
        badge: {
            background: '#1a5493',
            color: 'white',
        },
        groupCorner: {
            icon: 'fas fa-star fa-spin',
            color: '#1a5493',
        },
    },

    cc_staff: {
        name: 'CC Staff',
        description: 'Member of the Corporate Clash Crew',
        badge: {
            background: '#4992db',
            color: 'white',
        },
        groupCorner: {
            icon: 'fas fa-star fa-spin',
            color: '#4992db',
        },
    },

    morgan: {
        name: 'Morgan',
        description: "She's Morgan™!",
        badge: {
            background: '#4c7dc5',
            color: 'white',
            'font-size': '2px',
        },
        groupCorner: {
            icon: 'fas fa-star fa-spin',
            color: '#4c7dc5',
        },
    },
}
