//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'

import PulseMixin from 'core/util/pulse'
import BossRunClock from 'guilds/components/boss-run-clock'

export default {
    name: 'GuildsPage',

    metaInfo() {
        if (this.game.id === 1) {
            return {
                title: "CCG Schedule | TTR Cold Caller's Guild Schedule",
                meta: [{
                    vmid: 'description',
                    name: 'description',
                    content: 'A fun and dynamic TTR CCG schedule with countdowns. See when the next CCG boss runs are!',
                }],
            }
        }
        return {
            title: 'Boss Run Schedule | Guilds',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: `A fun and dynamic ${this.game.shortName} boss run schedule with countdowns. See when the next boss runs in ${this.game.name} are!`,
            }],
        }
    },

    theme: 'guilds',

    mixins: [PulseMixin],

    components: { BossRunClock },

    data() {
        return {
            dayIndex: null,
            daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            now: moment(),
        }
    },

    computed: {
        ...mapGetters(['game']),
        ...mapState({
            guild: state => state.guilds.guilds.ccg,
            actualDayIndex: state => state.guilds.actualDayIndex,
            timezone: state => state.guilds.timezone,
        }),
    },

    methods: {
        async prepare() {
            await this.loadGuildsCoreData()
            this.dayIndex = this.actualDayIndex
        },

        ...mapActions(['loadGuildsCoreData']),
    },

    created() {
        this.$listenFor('TICK_SEC', () => {
            this.now = moment()
        })
    },
}
