//
//
//
//
//
//
//
//
//
//

export default {
    id: 10,
    version: '3.7.5',
    date: '2022-10-16',
    notify: true,
}
