//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
    props: ['method', 'reloadOnGameChange', 'page'],

    data() {
        return {
            ready: false,
            error: false,
        }
    },

    computed: {
        ...mapGetters(['gameId']),
    },

    methods: {
        prepare() {
            const promise = this.method ? this.method() : this.$parent.prepare()
            if (promise === true) {
                this.ready = true
            } else {
                promise.then(() => {
                    this.ready = true
                }, () => {
                    this.error = true
                })
            }
        },
    },

    created() {
        this.prepare()
    },

    watch: {
        gameId() {
            if (this.reloadOnGameChange) {
                this.ready = false
                this.error = false
                this.prepare()
            }
        },
    },
}
