//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

import ResendVerification from './resend-verification'
import ToonForm from './toon-form'

export default {
    props: ['token'],

    components: { ResendVerification, ToonForm },

    modalClass: 'modal-content modal-content--sm',

    data() {
        return {
            mode: 'verify',
            result: null,
            errorMessage: null,
        }
    },

    computed: {
        hasToon() {
            return !!Object.keys(this.toons).length
        },

        ...mapState({
            toons: state => state.accounts.toons,
        }),
    },

    async mounted() {
        try {
            await this.$store.dispatch('verifyEmail', this.token)
            if (this.hasToon) {
                this.result = true
            } else {
                // Take them right to the create toon form
                this.$swapModal(ToonForm, { verified: true })
            }
        } catch (e) {
            this.result = false
            this.errorMessage = this.$drf.parseError(e).squashed
        }
        this.$router.safePush('/')
    },
}
