//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    id: 18,
    version: '3.8.6',
    date: '2023-06-01',
    notify: true,
}
