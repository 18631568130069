//
//
//

export default {
    name: 'PlaywireAdController',

    methods: {
        /**
         * This is called by the router whenever the page is changed. It is not
         * called when the site is first loaded, or when the router detects the URL
         * changed but the page hasn't (i.e. when clicking on different groups).
         */
        pageChanged(to, from) { // eslint-disable-line no-unused-vars
            // Notify Playwire of page changes if we're using them in SPA mode
            // Note that window.pwUnits is only set when in SPA mode
            if (window.pwUnits) {
                try {
                    window.ramp.destroyUnits('all').then(() => {
                        window.ramp.addUnits(window.pwUnits).then(() => window.ramp.displayUnits())
                    })
                } catch (e) {
                    console.error(e)
                }
            }
        },
    },

    created() {
        this.$listenFor('pageChanged', this.pageChanged)
    },
}
