import confetti from 'canvas-confetti'

const confettiColors = [
    '#26ccff',
    '#a25afd',
    '#ff5e7e',
    '#88ff5a',
    '#fcff42',
    '#ffa62d',
    '#ff36ff',
]

const twoRandomColors = () => [
    confettiColors[Math.floor(Math.random() * 7)],
    confettiColors[Math.floor(Math.random() * 7)],
]

export default () => {
    const end = Date.now() + (2 * 1000)

    function frame() {
        confetti({
            particleCount: 2,
            angle: 60,
            spread: 55,
            origin: { x: 0 },
            colors: twoRandomColors(),
        })
        confetti({
            particleCount: 2,
            angle: 120,
            spread: 55,
            origin: { x: 1 },
            colors: twoRandomColors(),
        })

        if (Date.now() < end) {
            requestAnimationFrame(frame)
        }
    }

    frame()
}
