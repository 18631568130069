//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

import FlowerTable from 'gardening/components/flower-table'
import ShapesInfo from 'gardening/components/shapes-info'
import { flowerTypes, flowersByJellybeans } from 'gardening/flowers'

export default {
    name: 'FlowersPage',

    metaInfo() {
        return {
            title: 'Flowers',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: `Learn about the different jellybean combinations for each flower in ${this.game.name} with our easy to understand Gardening Guide!`,
            }],
        }
    },

    components: { FlowerTable, ShapesInfo },

    data() {
        return {
            albumView: false,
            jellybeanCounts: ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight'],
            flowerTypes,
            flowersByJellybeans,
            toon: null,
        }
    },

    computed: {
        ...mapGetters(['currentToon', 'game']),
    },

    watch: {
        toon(toonId) {
            if (toonId && this.currentToon?.id !== toonId) this.$store.dispatch('switchToon', toonId)
        },
    },
}
