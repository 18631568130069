import { render, staticRenderFns } from "./report-link.vue?vue&type=template&id=827aa20c&scoped=true&lang=pug&"
import script from "./report-link.vue?vue&type=script&lang=js&"
export * from "./report-link.vue?vue&type=script&lang=js&"
import style0 from "./report-link.vue?vue&type=style&index=0&id=827aa20c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "827aa20c",
  null
  
)

export default component.exports