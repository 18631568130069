//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CircleStencil, Cropper } from 'vue-advanced-cropper'
import { mapActions, mapState, mapGetters } from 'vuex'
import _ from 'lodash'

import { ModalFormMixin } from 'core/components/forms/mixin'
import { toonColors } from 'core/util/colors'
import games from 'core/util/games'
import { gameTracks } from 'core/util/gags'
import { gameSuits } from 'core/util/cogs'
import { gameBackgrounds, ringCategories, keyedRingCategories, keyedRings } from 'core/util/toonatars'
import labels from 'core/util/labels'
import { unlockablePoses, unlockableBackdrops, canUseUnlockable } from 'core/util/unlockables'

export default {
    name: 'ToonForm',

    mixins: [ModalFormMixin],

    components: { Cropper, CircleStencil },

    props: {
        verified: {
            type: Boolean,
            default: false,
        },

        requiredGame: {
            type: Boolean,
            default: false,
        },

        forceCurrentGame: {
            type: Boolean,
            default: false,
        },
    },

    fields: [
        {
            name: 'game',
            default() {
                return this.$store.getters.gameId
            },
        },
        'name',
        'species',
        'label',
        {
            name: 'color',
            default() {
                return Object.values(toonColors)[0]
            },
        },
        'laff',
        'photo',
        'photo_bg',
        'ring',
        'photo_pose',

        { name: 'toonup', default: 0 },
        { name: 'trap', default: 0 },
        { name: 'lure', default: 0 },
        { name: 'sound', default: 0 },
        { name: 'throw', default: 0 },
        { name: 'squirt', default: 0 },
        { name: 'drop', default: 0 },
        { name: 'zap', default: 0 },

        {
            name: 'prestiges',
            default() {
                return []
            },
        },

        { name: 'sellbot', default: '0000' },
        { name: 'cashbot', default: '0000' },
        { name: 'lawbot', default: '0000' },
        { name: 'bossbot', default: '0000' },

        { name: 'external', default: false },
    ],

    dispatch: 'saveToonAndSwitchIfNew',

    dispatchDestroy: 'destroyToon',

    data() {
        const currentRing = keyedRings[this.values?.ring]
        return {
            gameOptions: _.map(games, game => ({
                text: game.name,
                value: game.id,
            })),

            toonColors: Object.values(toonColors),
            ringCategory: currentRing?.categoryName || ringCategories[0].name,

            expanded: false,

            photoFile: false,
            rawPhotoData: null,
            croppedPhotoBlob: null,
            croppedPhotoDataURL: null,

            gameHideSync: false,
            gameSyncStarted: false,
            gameSyncError: null,
        }
    },

    computed: {
        gameSpecies() {
            return _.sortBy(_.filter(this.species, s => s.game === this.model.game.value), 'name')
        },

        photoSrc() {
            return this.croppedPhotoDataURL || this.model.photo.value
        },

        updatedPhotoSrc() {
            if (!this.selectedGame.toonPhotoDnaRegex || this.values.photo_pose === this.model.photo_pose.value || !this.model.photo.value) return this.photoSrc

            // We can dynamically update for games that support it
            const dna = this.model.photo.value.match(this.selectedGame.toonPhotoDnaRegex)[1]
            return this.selectedGame.toonPhotoUrl(dna, this.model.photo_pose.value)
        },

        gagTracks() {
            return gameTracks[this.model.game.value]
        },

        suits() {
            return gameSuits[this.model.game.value]
        },

        backgrounds() {
            return this.filterUnlockables(gameBackgrounds[this.model.game.value], unlockableBackdrops)
        },

        ringCategories() {
            return _.map(ringCategories, c => ({ text: c.name, value: c.name }))
        },

        rings() {
            return keyedRingCategories[this.ringCategory].rings
        },

        toonatarPreview() {
            if (!this.model.species.value) return null
            return {
                game: this.model.game.value,
                photo: this.updatedPhotoSrc,
                photo_bg: this.model.photo_bg.value,
                ring: this.model.ring.value,
                species: this.model.species.value,
                color: this.model.color.value,
            }
        },

        restricted() {
            return this.hasBan('no_edit_toons')
        },

        poseOptions() {
            return _.map(this.filterUnlockables(this.selectedGame.poses, unlockablePoses), p => ({ text: p.name, value: p.id }))
        },

        labelOptions() {
            return [{ text: 'None', value: null }].concat(_.map(this.user.labels, id => ({ text: labels[id].name, value: id })))
        },

        external() {
            return this.model.external.value
        },

        selectedGame() {
            return games[this.model.game.value]
        },

        supportsToonSync() {
            return this.selectedGame.toonSync
        },

        ...mapState({
            user: state => state.accounts.user,
            species: state => state.core.toonSpecies,
        }),
        ...mapGetters(['game', 'hasBan']),
    },

    methods: {
        setTrackLevel(track, level) {
            if (this.model[track].value === level) {
                this.model[track].value = 0
            } else {
                this.model[track].value = level
            }
        },

        togglePrestige(track) {
            const trackIndex = this.model.prestiges.value.indexOf(track)
            if (trackIndex !== -1) {
                this.model.prestiges.value.splice(trackIndex, 1)
            } else {
                this.model.prestiges.value.push(track)
            }
        },

        filterUnlockables(allItems, unlockableItems) {
            return _.filter(allItems, (item) => {
                if (!(item.id in unlockableItems)) return true
                return canUseUnlockable(this.user.unlocks, unlockableItems[item.id])
            })
        },

        async crop() {
            const cropperCanvas = this.$refs.cropper.getResult().canvas
            const canvas = await this.resizeCanvas(cropperCanvas, 256)
            this.croppedPhotoDataURL = canvas.toDataURL('image/jpeg', 1.0)
            canvas.toBlob((blob) => {
                this.croppedPhotoBlob = blob
                this.rawPhotoData = null
            })
        },

        resizeCanvas(canvas, size) {
            return new Promise((resolve) => {
                // Prepare a canvas of the right size
                const compressedCanvas = document.createElement('canvas')
                compressedCanvas.width = size
                compressedCanvas.height = size

                // Create an image from the source canvas and draw it at the new size
                const img = new window.Image()
                const context = compressedCanvas.getContext('2d')
                img.addEventListener('load', () => {
                    context.drawImage(img, 0, 0, size, size)
                    resolve(compressedCanvas)
                })
                img.setAttribute('src', canvas.toDataURL('image/jpeg'))
            })
        },

        async sync() {
            this.gameHideSync = true
            this.gameSyncError = null
            try {
                await this.syncToonWithGame(this.id)
                this.gameSyncStarted = true
            } catch (e) {
                this.gameSyncError = this.$drf.parseError(e).squashed
            }
        },

        clean(data) {
            _.each(['name', 'laff'], (f) => {
                if (!data[f]) delete data[f]
            })

            if (this.restricted) data.name = 'noop'
            if (data.label === null) data.label = ''

            // Don't send photo data
            delete data.photo

            return data
        },

        ...mapActions(['syncToonWithGame']),
    },

    watch: {
        photoFile(f) {
            const vue = this
            const fr = new FileReader()
            fr.onload = function onLoadSelectedPhoto() {
                vue.rawPhotoData = this.result
            }
            fr.readAsDataURL(f)
        },

        'model.game.value': function onGameChanged(gameId) {
            if (gameId === 1) {
                _.each(['toonup', 'trap', 'lure', 'sound', 'throw', 'squirt', 'drop'], (t) => {
                    if (this.model[t].value === 8) this.model[t].value = 7
                })
                if (this.model.zap.value) this.model.zap.value = 0
            }
        },
    },

    mounted() {
        if (this.id) this.expanded = true
    },
}
