import router from 'core/router'
import { store } from 'core/store'
import games from 'core/util/games'
import { present } from 'core/util/modals'

export const selectToon = (id) => {
    const toon = store.state.accounts.toons[id]
    const toonGame = games[toon.game]
    const routeGame = router.currentRoute.meta.game

    if (routeGame && routeGame !== toonGame.id) {
        router.safePush(router.currentRoute.meta.mappings[toonGame.shortName.toLowerCase()])
    } else {
        store.commit('setLastGame', toon.game)
    }
    store.dispatch('switchToon', id)
}

export const promptLinkToon = (gameId) => {
    present(games[gameId].toonSyncFlow)
}
