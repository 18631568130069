import Vue from 'vue'

import Toonatar from 'accounts/components/toonatar'
import ToonSelectBox from 'accounts/components/toon-select-box'

import ReportLink from 'groups/components/report-link'

import Announcement from './announcements/announcement'
import Btn from './btn'
import Error from './error'
import FancyCheckbox from './fancy-checkbox'
import Header from './header'
import GrowingTextarea from './growing-textarea'
import Prepare from './prepare'
import Splash from './splash'
import FitText from './fit-text'
import ResponsiveText from './responsive-text'
import InfoCard from './info-card'
import TimeFromNow from './time-from-now'

import Asset from './asset'
import Spinner from './spinner'
import Dropdown from './dropdown'

import VForm from './forms/v-form'
import VField from './forms/v-field'
import VSlot from './forms/v-slot'

import GameLink from './game-link'

import ModalHeader from './modal-header'

Vue.component('toonatar', Toonatar)
Vue.component('toon-select-box', ToonSelectBox)

Vue.component('report-link', ReportLink)

Vue.component('announcement', Announcement)
Vue.component('btn', Btn)
Vue.component('error', Error)
Vue.component('fancy-checkbox', FancyCheckbox)
Vue.component('header-view', Header)
Vue.component('growing-textarea', GrowingTextarea)
Vue.component('prepare', Prepare)
Vue.component('splash', Splash)
Vue.component('fit-text', FitText)
Vue.component('responsive-text', ResponsiveText)
Vue.component('info-card', InfoCard)
Vue.component('time-from-now', TimeFromNow)

Vue.component('asset', Asset)
Vue.component('spinner', Spinner)
Vue.component('dropdown', Dropdown)

Vue.component('v-form', VForm)
Vue.component('v-field', VField)
Vue.component('v-slot', VSlot)

Vue.component('game-link', GameLink)

Vue.component('modal-header', ModalHeader)
