//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { mapActions, mapMutations, mapState } from 'vuex'

let lastImg = null

export default {
    name: 'Nostalgie',

    data() {
        return {
            announcement: null,
        }
    },

    computed: {
        snoozeId() {
            if (!this.metadata) return null
            return `nostalgie::${this.metadata.snooze.id}`
        },

        lastSnooze() {
            return this.snoozes[this.snoozeId]
        },

        snoozeDuration() {
            return 1000 * 60 * 60 * 24 * this.metadata?.snooze?.durationDays
        },

        isSnoozed() {
            if (!this.lastSnooze) return false
            return (this.lastSnooze + this.snoozeDuration) > Date.now()
        },

        ...mapState({
            snoozes: state => state.core.announcementSnoozes,
            metadata: state => state.core.nostalgie.metadata,
        }),
    },

    methods: {
        prepAnnouncement() {
            if (this.announcement) return

            // Start by choosing an announcement
            const announcement = _.cloneDeep(_.sample(this.metadata.announcements))

            // Choose one of the photos
            if (announcement) {
                let possibleImages = _.uniqBy(announcement.photos, 'name')
                if (possibleImages.length >= 2 && lastImg) possibleImages = possibleImages.filter(img => img.name !== lastImg?.name)
                announcement.image = _.sample(possibleImages)
                lastImg = announcement.image
            }

            this.announcement = announcement
        },

        trackClick(e) {
            e.stopImmediatePropagation()
            this.$gtag.event('nostalgie', { category: this.announcement.image.name })
        },

        close() {
            this.snoozeAnnouncement(this.snoozeId)
        },

        ...mapActions(['loadNostalgieMetadata']),
        ...mapMutations(['snoozeAnnouncement']),
    },

    created() {
        this.loadNostalgieMetadata()
        if (this.metadata) this.prepAnnouncement()
    },

    watch: {
        metadata: 'prepAnnouncement',
    },
}
