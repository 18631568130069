//
//
//
//
//

export default {
    methods: {
        timeSinceLastInvasion() {
            return this.$store.getters.timeSinceLastInvasion
        },
    },

    created() {
        this.$listenFor('TICK_10SEC', () => this.$forceUpdate())
    },
}
