//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { mapGetters, mapMutations } from 'vuex'

export default {
    props: ['cogType', 'disabled'],

    computed: {
        cogIds() {
            return _.map(this.cogType.cogs, 'id')
        },

        allCogsEnabled() {
            const relevantCogs = _.filter(this.notifyCogNames, name => this.cogIds.includes(name))
            return relevantCogs.length === this.cogIds.length
        },

        ...mapGetters(['game', 'notifyCogNames']),
    },

    methods: {
        enableCogs(cogs) {
            this.setCogNotificationsForGame({
                game: this.game.id,
                cogs: _.uniq(this.notifyCogNames.concat(cogs)),
            })
        },

        disableCogs(cogs) {
            this.setCogNotificationsForGame({
                game: this.game.id,
                cogs: _.filter(this.notifyCogNames, n => !cogs.includes(n)),
            })
        },

        toggleCogType() {
            if (this.allCogsEnabled) {
                this.disableCogs(this.cogIds)
            } else {
                this.enableCogs(this.cogIds)
            }
        },

        toggleCog(id) {
            if (this.notifyCogNames.includes(id)) {
                this.disableCogs([id])
            } else {
                this.enableCogs([id])
            }
        },

        ...mapMutations(['setCogNotificationsForGame']),
    },
}
