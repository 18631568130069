//
//
//
//
//
//
//
//

export default {
    name: 'ToonsPreview',

    props: {
        toons: {
            type: Array,
            required: true,
        },

        size: {
            type: Number,
            default: 96,
        },
    },
}
