//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex'

import AuthForm from 'accounts/components/auth-form'
import ToonForm from 'accounts/components/toon-form'
import GroupDetails from 'groups/components/group-details'

export default {
    props: ['target', 'props'],

    components: { AuthForm, ToonForm, GroupDetails },

    computed: {
        mode() {
            if (this.user && this.gameToons.length && this.currentToon) return 'target'
            if (!this.user) return 'auth'
            return 'toon'
        },

        modalClass() {
            if (this.mode === 'target') {
                if (typeof this.target === 'string') return this.$options.components[this.target].modalClass
                return this.target.modalClass
            }
            if (this.mode === 'auth') return AuthForm.modalClass
            return ToonForm.modalClass
        },

        ...mapState({
            user: state => state.accounts.user,
        }),
        ...mapGetters(['gameToons', 'currentToon']),
    },

    created() {
        this.$updateModalClass(this.modalClass)
    },

    watch: {
        modalClass(val) {
            this.$updateModalClass(val)
        },
    },
}
