//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ContactPage',

    metaInfo() {
        return {
            title: 'Contact',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: 'Find out how to connect with the ToonHQ community! Check out our Discord and Facebook!',
            }],
        }
    },
}
