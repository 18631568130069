//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['model', 'field', 'label', 'horizontal', 'labelCols', 'hint', 'cls', 'options'],
}
