//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex'
import Q from 'q'

import RequireToonWrapper from 'accounts/components/require-toon-wrapper'
import { audioManager, groupSounds } from 'core/util/audio'
import { gameUrl } from 'core/util/games'
import PulseMixin from 'core/util/pulse'
import GroupDetails from './group-details'
import GroupsList from './groups-list'
import GroupFilters from './group-filters'
import GroupForm from './group-form'

export default {
    name: 'GroupsPage',

    metaInfo() {
        return {
            title: 'Group Tracker',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: `The beautiful ${this.game.shortName} group tracker that everyone is using. Find other toons in ${this.game.name} to fight the cogs with!`,
            }],
        }
    },

    theme: 'groups',

    mixins: [PulseMixin],

    components: { GroupsList, GroupFilters },

    computed: {
        ...mapGetters(['groupsList', 'activeGroups', 'groupsAreFiltered', 'game', 'userGroupId', 'isMod', 'socketIssue', 'socketOutdated']),
        ...mapState({
            groups: state => state.groups.groups,
            gameOpen: state => state.groups.gameOpen,
        }),
    },

    methods: {
        async prepare() {
            await Q.all([
                this.$store.dispatch('loadGroupsCoreData'),
                this.$store.dispatch('trackGroups'),
            ])

            // Done preloading important data, start preloading audio files and tracking invasions
            this.$store.dispatch('trackInvasions', 'groups')
            audioManager.preloadMultiple(groupSounds)

            // Check if there's a group in the URL we should open
            if (this.$route.params.id) {
                this.resumeGroup(parseInt(this.$route.params.id))
            } else if (this.$route.params.reportId) {
                setTimeout(() => {
                    this.openReport(this.$route.params.reportId)
                }, 1000)
            } else if (this.$route.params.ttrId) {
                setTimeout(() => {
                    this.openGroup({ ttr_id: this.$route.params.ttrId })
                }, 1000)
            }
        },

        createGroup() {
            this.$present(RequireToonWrapper, { target: GroupForm })
        },

        resumeGroup(groupId) {
            // Verify the group still exists
            if (!this.groups[groupId]) {
                this.$router.safeReplace(gameUrl(this.game.id, '/groups/'))
                if (this.isMod) this.openGroup({ id: groupId })
                return
            }

            // Check if the user was trying to join a group
            let resumeJoin = false
            if (window.location.hash === '#join') {
                resumeJoin = true
                this.$router.safeReplace(gameUrl(this.game.id, `/groups/${groupId}/`))
            }

            // Bring up the group
            if (resumeJoin) {
                this.$present(RequireToonWrapper, {
                    target: GroupDetails,
                    props: { groupId, resumeJoin },
                })
            } else {
                this.$present(GroupDetails, { groupId, resumeJoin })
            }
        },

        showUserGroup() {
            this.$present(GroupDetails, { groupId: this.userGroupId })
        },

        async openGroup(params) {
            const group = await this.loadGroup(params)
            setTimeout(() => {
                this.$present(GroupDetails, { groupId: group.id, forceShowAll: true })
            }, 10)
        },

        async openReport(reportId) {
            const report = await this.loadReport(reportId)
            setTimeout(() => {
                this.$present(GroupDetails, { groupId: report.group.id, reportId: parseInt(reportId) })
            }, 10)
        },

        ...mapActions(['loadGroupsCoreData', 'trackGroups', 'loadReport', 'loadGroup']),
    },

    beforeDestroy() {
        this.$store.dispatch('stopTrackingGroups')
        this.$store.dispatch('stopTrackingInvasions', 'groups')
    },
}
