//
//
//
//
//
//
//
//

export default {
    id: 37,
    version: '3.8.18',
    date: '2024-03-09',
    notify: true,
}
