//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ModalFormMixin } from 'core/components/forms/mixin'

export default {
    name: 'UnlinkOAuthForm',

    mixins: [ModalFormMixin],

    props: ['service', 'account'],

    fields: [],

    modalClass: 'modal-content modal-content--sm',

    dispatch: 'unlinkOAuth',

    methods: {
        clean() {
            return { service: this.service, account: this.account }
        },
    },
}
