//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapState({
            noMorePastInvasions: state => state.invasions.noMorePastInvasions,
        }),

        ...mapGetters({
            invasions: 'pastInvasions',
        }),
    },

    methods: {
        prepare() {
            return this.$store.dispatch('loadPastInvasions', true)
        },

        loadPastInvasions($state) {
            if (this.noMorePastInvasions) {
                $state.complete()
                return
            }
            this.$store.dispatch('loadPastInvasions').then(() => $state.loaded())
        },
    },
}
