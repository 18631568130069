import _ from 'lodash'
import io from 'socket.io-client'
import Vue from 'vue'
import { mapGetters } from 'vuex'

import { pluralize } from 'core/util/filters'
import radio from 'core/util/radio'

export const ConnectionStatus = {
    // Making an initial connection attempt to the server
    CONNECTING: 'connecting',

    // Connected to the server
    CONNECTED: 'connected',

    // Attempting to reconnect after a connection interruption
    RECONNECTING: 'reconnecting',

    // Explicitly disconnected by the server
    DISCONNECTED: 'disconnected',
}

export const DisconnectedReason = {
    OUTDATED: 'outdated',
    UNKNOWN: 'unknown',
}

export const RoomStatus = {
    WAITING: 'waiting',
    JOINING: 'joining',
    CONNECTED: 'connected',
}

export const RoomMixin = {
    created() {
        console.log('rmc')
        this.joinRooms()
    },

    destroyed() {
        _.each(this.$options.rooms, (room) => {
            this.$store.dispatch('leaveRoom', { room, service: this.$options.name })
        })
    },

    methods: {
        joinRooms() {
            console.log('joining')
            _.each(this.$options.rooms, (room) => {
                this.$store.dispatch('joinRoom', { room, service: this.$options.name })
            })
        },
    },
}

export const GameRoomMixin = {
    ...RoomMixin,

    computed: {
        ...mapGetters(['gameId']),
    },

    methods: {
        joinRooms() {
            _.each(this.$options.rooms, (room) => {
                this.$store.dispatch('joinRoom', {
                    room,
                    game: this.$store.getters.gameId,
                    service: this.$options.name,
                })
            })
        },
    },

    watch: {
        gameId: 'joinRooms',
    },
}

const markRoomAsPending = (room) => {
    // If this room hasn't made progress and already has a pending promise, we're good
    if (room.status === RoomStatus.WAITING && room.promise) return

    // Notate that this is a reconnect if we were previously connected
    if (room.status === RoomStatus.CONNECTED) room._isReconnect = true

    // Set the room back  to waiting and create a new promise
    room.status = RoomStatus.WAITING
    room.promise = new Promise((resolve) => {
        room.resolve = resolve
    })
}

let socket = null

export default {
    state: {
        status: null,
        sid: null,
        disconnectedReason: null,

        rooms: {},
    },

    getters: {
        socketActive: state => state.status === ConnectionStatus.CONNECTED,
        socketIssue: state => [ConnectionStatus.RECONNECTING, ConnectionStatus.DISCONNECTED].includes(state.status),
        socketOutdated: state => state.status === ConnectionStatus.DISCONNECTED && state.disconnectedReason === DisconnectedReason.OUTDATED,

        roomConnected: state => room => state.rooms[room]?.status === RoomStatus.CONNECTED,
        roomConnectedToGame: state => (roomKey, gameId) => {
            const room = state.rooms[roomKey]
            return room?.status === RoomStatus.CONNECTED && room.game === gameId
        },
        currentGameForRoom: state => room => state.rooms[room]?.game,
    },

    mutations: {
        setSocketStatus(state, status) {
            state.status = status
            state.disconnectedReason = null
            if (state.status !== ConnectionStatus.CONNECTED) state.sid = null
        },

        setDisconnected(state, reason) {
            state.status = ConnectionStatus.DISCONNECTED
            state.disconnectedReason = reason
            state.sid = null
        },

        setSid(state, sid) {
            state.sid = sid
        },
    },

    actions: {
        initSocketIO({ state, commit, dispatch }) {
            if (state.status && state.status !== ConnectionStatus.DISCONNECTED) {
                // This won't really happen, but just in case
                console.warn('[socket] Ignoring initSocketIO call because SocketIO is already active.')
                return
            }

            // Set up the socket connection, and wire up event handling
            commit('setSocketStatus', ConnectionStatus.CONNECTING)
            socket = io(`${process.env.VUE_APP_SOCKETIO_SERVER}/`, {
                transports: ['websocket'],
                query: `protocol=${process.env.VUE_APP_TOONHQ_PROTOCOL_VERSION}`,
            })

            socket.on('connect', () => {
                console.log('[socket] connected')
                commit('setSocketStatus', ConnectionStatus.CONNECTED)
                commit('setSid', socket.id)
                dispatch('socketConnected')
            })

            socket.on('connect_error', (err) => {
                if (err?.message === '__protocol__') {
                    console.warn("[socket] We're using an outdated version of the ToonHQ protocol")
                    commit('setDisconnected', DisconnectedReason.OUTDATED)
                } else {
                    console.warn('[socket] Failed to connect to Socket.IO server, retrying...')
                    commit('setSocketStatus', ConnectionStatus.RECONNECTING)
                }
                dispatch('socketLost')
            })

            socket.on('disconnect', (reason) => {
                if (reason === 'io server disconnect') {
                    console.warn('[socket] Disconnected from Socket.IO by the server')
                    commit('setDisconnected', DisconnectedReason.UNKNOWN)
                } else {
                    console.warn(`[socket] Disconnected from server: ${reason}, reconnecting...`)
                    commit('setSocketStatus', ConnectionStatus.RECONNECTING)
                }
                dispatch('socketLost')
            })

            // Set up our internal handlers
            socket.on('log', message => console.warn(`[Message from server]: ${message}`))
            socket.on('refresh', () => dispatch('forceRefresh'))
            socket.on('toast', message => Vue.$toast.open(message))

            dispatch('registerSocketHandlers', { socket })

            // Broadcast events over the radio
            socket.onAny((event, ...args) => {
                console.log('[socket]', event, args)
                radio.$emit(`socket:${event}`, ...args)
            })
        },

        joinRoom({ dispatch, state }, { room, service, game }) {
            const existingRoom = state.rooms[room]

            const withAddedService = () => [..._.without(existingRoom.services, service), service]

            // Determine if we need to actually join, and set up the room data
            const targetRoom = { room, game, status: RoomStatus.WAITING }
            if (!existingRoom) {
                // We don't have any connection to this room started yet
                targetRoom.services = [service]
            } else if (existingRoom.game !== game) {
                // We're already connected to this room, but with a different game
                targetRoom.services = withAddedService()
            } else {
                // We're already connected to this room
                existingRoom.services = withAddedService()
                return existingRoom.promise
            }

            // We need to actually join this room
            markRoomAsPending(targetRoom)
            Vue.set(state.rooms, room, targetRoom)
            dispatch('_joinRoom', { room: state.rooms[room] })
            return state.rooms[room].promise
        },

        async _joinRoom({ dispatch, getters }, { room }) {
            // Ensure we're connected, and make sure we haven't already joined (or are actively joining)
            if (!getters.socketActive || room.status !== RoomStatus.WAITING) return

            // Join the room
            console.log(`[socket] Joining room: ${room.room} (game: ${room.game})`)
            room.status = RoomStatus.JOINING
            const joinArgs = room.game ? [room.room, room.game] : [room.room]
            await socket.emitWithAck('track', ...joinArgs)

            // Mark as connected and notify anyone waiting
            console.log('[socket] Joined room!', room.room)
            room.status = RoomStatus.CONNECTED
            room.resolve()

            // If this was a reconnect, notify about the reconnection
            if (room._isReconnect) {
                dispatch('roomReconnected', { room })
                radio.$emit(`roomReconnected:${room.room}`, room)
                delete room._isReconnect
            }
        },

        leaveRoom({ state }, { room, service }) {
            const existingRoom = state.rooms[room]
            if (!existingRoom) return

            // Remove the service from the room
            existingRoom.services = _.without(existingRoom.services, service)

            // If we're the last service, leave the room
            if (existingRoom.services.length === 0) {
                console.log(`[socket] Leaving room: ${room}`)
                socket.emit('untrack', room)
                delete state.rooms[room]
            }
        },

        socketConnected({ dispatch, state }) {
            // Join any rooms we are supposed to be in
            _.each(state.rooms, (room) => {
                dispatch('_joinRoom', { room })
            })
        },

        socketLost({ state }) {
            // Mark any rooms we're in as pending (they'll be rejoined after)
            _.each(state.rooms, (room) => {
                markRoomAsPending(room)
            })
        },

        socketEmit({ getters }, { event, args }) {
            if (!getters.socketActive) throw new Error('Socket is not connected')
            console.log('[emit]', event, args)
            socket.emit(event, ...(args || []))
        },

        socketEmitWithAck({ getters }, { event, args }) {
            if (!getters.socketActive) throw new Error('Socket is not connected')
            console.log('[emitWithAck]', event, args)
            return socket.emitWithAck(event, ...(args || []))
        },

        forceRefresh() {
            // Determine how many seconds to wait (between 60 and 300)
            const delay = Math.floor(Math.random() * 240) + 60
            console.log(`Refreshing in ${delay} seconds...`)
            Vue.$toast.info(`A new version of ToonHQ is available! Your page will automatically refresh in ${pluralize(Math.round(delay / 60), 'minute')}.`, {
                duration: 0,
                dismissible: false,
            })
            setTimeout(() => {
                window.location.reload()
            }, delay * 1000)
        },
    },
}
