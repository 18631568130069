//
//
//
//

const nitro = window.nitroAds

export default {
    name: 'NitroPayAdController',

    methods: {
        /**
         * This is called by the router whenever the page is changed. It is not
         * when the router detects the URL changed but the page hasn't
         * (i.e. when clicking on different groups).
         */
        pageChanged(to, from) { // eslint-disable-line no-unused-vars
            this.$ads.forEach((ad) => {
                try {
                    ad.onNavigate()
                } catch (e) {
                    console.error('Error while refreshing ad', ad, e)
                }
            })
        },

        async createAd(type, params) {
            try {
                const ad = await nitro.createAd(type, params)
                this.$ads.push(ad)
            } catch (e) {
                console.error(`Error while creating ${type} ad`, e)
            }
        },
    },

    mounted() {
        this.$ads = []

        // Outstream video
        this.createAd('video', {
            refreshLimit: 0,
            refreshTime: 60,
            format: 'floating',
            mediaQuery: '(min-width: 0px)',
            report: {
                enabled: true,
                icon: true,
                wording: 'Report Ad',
                position: 'top-right',
            },
            floating: {
                position: 'right',
                reduceMobileSize: true,
            },
        })

        // Bottom Anchor
        this.createAd('stickyfooter', {
            refreshLimit: 0,
            refreshTime: 30,
            format: 'anchor',
            anchor: 'bottom',
            anchorBgColor: 'rgba(245,245,245,.15)',
            anchorPersistClose: false,
            mediaQuery: '(min-width: 0px)',
            report: {
                enabled: true,
                icon: true,
                wording: 'Report Ad',
                position: 'top-right',
            },
        })

        this.$listenFor('pageChanged', this.pageChanged)
    },
}
