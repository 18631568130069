//
//
//
//
//
//
//
//
//

export default {
    id: 20,
    version: '3.8.7',
    date: '2023-06-15',
    notify: true,
}
