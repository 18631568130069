//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'
import _ from 'lodash'
import { mapGetters } from 'vuex'

import celebrate from 'core/util/celebrate'
import Jellybean from './jellybean'

export default {
    name: 'FlowerRow',

    components: { Jellybean },

    props: {
        flower: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            saving: false,
        }
    },

    computed: {
        harvested() {
            return this.currentToon && this.currentToon.flowers.includes(this.flower.id)
        },

        ...mapGetters(['currentToon']),
    },

    methods: {
        async updateFlower(val) {
            if (!this.currentToon) return
            if (val) {
                this.currentToon.flowers.push(this.flower.id)
            } else {
                this.currentToon.flowers = _.without(this.currentToon.flowers, this.flower.id)
            }
            if (this.currentToon.flowers.length === 40) celebrate()
            this.saving = true
            await axios.post(`/api/toons/${this.currentToon.id}/patch/`, { flowers: this.currentToon.flowers })
            this.saving = false
        },
    },
}
