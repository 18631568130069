import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

import { store } from 'core/store'
import radio from 'core/util/radio'

import HomePage from 'core/components/home-page'
import AppsPage from 'core/pages/apps-page'
import ContactPage from 'core/pages/contact-page'
import FaqPage from 'core/pages/faq-page'
import PrivacyPage from 'core/pages/privacy-page'
import ChangelogPage from 'core/pages/changelog-page'
import NotFound from 'core/components/not-found'
import AppleResolution from 'core/pages/apple-resolution'

import OAuthErrorPage from 'accounts/pages/oauth-error-page'
import SettingsPage from 'accounts/pages/settings-page'
import RearrangeToonsPage from 'accounts/pages/rearrange-toons-page'

import InvasionsPage from 'invasions/components/invasions-page'
import ActiveInvasionsList from 'invasions/components/active-invasions-list'
import PastInvasionsList from 'invasions/components/past-invasions-list'
import InvasionNotifications from 'invasions/components/invasion-notifications'
import InvasionStatistics from 'invasions/components/invasion-statistics'

import GardeningPage from 'gardening/pages/gardening-page'
import FlowersPage from 'gardening/pages/flowers-page'
import StatuesPage from 'gardening/pages/statues-page'
import OrganicGagsPage from 'gardening/pages/organic-gags-page'
import GardeningTutorialPage from 'gardening/pages/tutorial-page'

import GroupsPage from 'groups/components/groups-page'

import FishingPage from 'fishing/pages/fishing-page'

import FieldOfficesPage from 'fos/pages/field-offices-page'

import DoodlesPage from 'doodles/pages/doodles-page'

import GuildPage from 'guilds/pages/guild-page'

Vue.use(VueRouter)
Vue.use(Meta)

const gameMeta = (game, mappings) => ({
    meta: { game, mappings },
})

const samePageRegexes = [
    // Groups page
    /^(\/cc)?\/groups\//,
]

const areSamePage = (to, from) => {
    for (const samePageRegex of samePageRegexes) {
        if (samePageRegex.test(to?.path) && samePageRegex.test(from?.path)) return true
    }
    return false
}

const router = new VueRouter({
    mode: 'history',

    linkActiveClass: 'active',

    scrollBehavior(to, from) {
        if (areSamePage(to, from)) {
            // Prevent the page from jumping when opening/closing a group, etc.
            return null
        }

        if (to?.hash?.length > 1) {
            // Allow jumping to specific IDs on the page
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ selector: to.hash })
                }, 50)
            })
        }

        return { x: 0, y: 0 }
    },

    routes: [
        { path: '/', component: HomePage },
        { path: '/apps', component: AppsPage },
        { path: '/contact', component: ContactPage },
        { path: '/faq', component: FaqPage },
        { path: '/privacy', component: PrivacyPage },
        { path: '/changelog', component: ChangelogPage },
        { path: '/apple-resolution', component: AppleResolution },

        { path: '/auth/verify/:token', component: HomePage },
        { path: '/auth/reset/:resetToken', component: HomePage },
        { path: '/auth/error/:error', component: OAuthErrorPage },

        { path: '/settings/rearrange-toons', component: RearrangeToonsPage },
        { path: '/settings', component: SettingsPage },

        {
            path: '/invasions',
            component: InvasionsPage,
            children: [
                { path: '', component: ActiveInvasionsList, ...gameMeta(1, { cc: '/cc/invasions/' }) },
                { path: 'past/', component: PastInvasionsList, ...gameMeta(1, { cc: '/cc/invasions/past/' }) },
                { path: 'notifications/', component: InvasionNotifications, ...gameMeta(1, { cc: '/cc/invasions/notifications/' }) },
                { path: 'statistics/', component: InvasionStatistics, ...gameMeta(1, { cc: '/cc/invasions/statistics/' }) },
            ],
        },
        {
            path: '/cc/invasions',
            component: InvasionsPage,
            children: [
                { path: '', component: ActiveInvasionsList, ...gameMeta(2, { ttr: '/invasions/' }) },
                { path: 'past/', component: PastInvasionsList, ...gameMeta(2, { ttr: '/invasions/past/' }) },
                { path: 'notifications/', component: InvasionNotifications, ...gameMeta(2, { ttr: '/invasions/notifications/' }) },
                { path: 'statistics/', component: InvasionStatistics, ...gameMeta(2, { ttr: '/invasions/statistics/' }) },
            ],
        },

        { path: '/groups', component: GroupsPage, ...gameMeta(1, { cc: '/cc/groups/' }) },
        { path: '/cc/groups', component: GroupsPage, ...gameMeta(2, { ttr: '/groups/' }) },
        { path: '/groups/:id', component: GroupsPage, ...gameMeta(1, { cc: '/cc/groups/' }) },
        { path: '/cc/groups/:id', component: GroupsPage, ...gameMeta(2, { ttr: '/groups/' }) },
        { path: '/groups/ttrid/:ttrId', component: GroupsPage, ...gameMeta(1, { cc: '/cc/groups/' }) },
        { path: '/groups/reports/:reportId', component: GroupsPage, ...gameMeta(1, { cc: '/cc/groups/' }) },
        { path: '/cc/groups/reports/:reportId', component: GroupsPage, ...gameMeta(2, { ttr: '/groups/' }) },

        { path: '/field-offices', component: FieldOfficesPage, ...gameMeta(1, { cc: '/cc/field-offices/' }) },
        { path: '/cc/field-offices', redirect: '/cc/groups/' },

        { path: '/fishing', component: FishingPage, ...gameMeta(1, { cc: '/cc/fishing/' }) },
        { path: '/cc/fishing', component: FishingPage, ...gameMeta(2, { ttr: '/fishing/' }) },

        {
            path: '/gardening',
            component: GardeningPage,
            children: [
                { path: '', component: FlowersPage, ...gameMeta(1, { cc: '/cc/gardening/' }) },
                { path: 'statues/', component: StatuesPage, ...gameMeta(1, { cc: '/cc/gardening/statues/' }) },
                { path: 'organic/', component: OrganicGagsPage, ...gameMeta(1, { cc: '/cc/gardening/organic/' }) },
                { path: 'tutorial/', component: GardeningTutorialPage, ...gameMeta(1, { cc: '/cc/gardening/tutorial/' }) },
            ],
        },
        { path: '/cc/gardening', redirect: '/cc/groups/' },
        { path: '/cc/gardening/statues', redirect: '/cc/groups/' },
        { path: '/cc/gardening/organic', redirect: '/cc/groups/' },
        { path: '/cc/gardening/tutorial', redirect: '/cc/groups/' },

        { path: '/ccg', component: GuildPage, ...gameMeta(1, { cc: '/cc/ccg/' }) },
        { path: '/cc/ccg', component: GuildPage, ...gameMeta(2, { ttr: '/ccg/' }) },

        { path: '/doodles', component: DoodlesPage, ...gameMeta(1, { cc: '/cc/doodles/' }) },
        { path: '/cc/doodles', component: DoodlesPage, ...gameMeta(2, { ttr: '/doodles/' }) },

        { path: '*', component: NotFound },
    ],
})

router.safePush = (path) => {
    if (router.currentRoute.path !== path) router.push(path)
}

router.safeReplace = (path) => {
    if (router.currentRoute.path !== path) router.replace(path)
}

router.beforeEach((to, from, next) => {
    store.dispatch('selectToonForCurrentGame', to.meta.game)
    next()
})

router.afterEach((to, from) => {
    store.commit('setTheme', to.matched[0].components.default._Ctor[0].extendOptions.theme) // eslint-disable-line no-underscore-dangle

    if (!areSamePage(to, from)) {
        // Skip if the page just loaded
        if (!from.matched?.length) return

        radio.$emit('pageChanged', to, from)
    }
})

export default router
