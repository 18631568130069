import _ from 'lodash'

import { gameTracks } from 'core/util/gags'
import aa from 'core/img/toonatar-backgrounds/aa.jpg'
import bbhq from 'core/img/toonatar-backgrounds/bbhq.jpg'
import cbhq from 'core/img/toonatar-backgrounds/cbhq.jpg'
import dd from 'core/img/toonatar-backgrounds/dd.jpg'
import ddl from 'core/img/toonatar-backgrounds/ddl.jpg'
import dg from 'core/img/toonatar-backgrounds/dg.jpg'
import estate from 'core/img/toonatar-backgrounds/estate.jpg'
import gs from 'core/img/toonatar-backgrounds/gs.jpg'
import lbhq from 'core/img/toonatar-backgrounds/lbhq.jpg'
import mml from 'core/img/toonatar-backgrounds/mml.jpg'
import sm from 'core/img/toonatar-backgrounds/sm.jpg'
import sbhq from 'core/img/toonatar-backgrounds/sbhq.jpg'
import tb from 'core/img/toonatar-backgrounds/tb.jpg'
import tf23 from 'core/img/toonatar-backgrounds/tf23.jpg'
import tf24 from 'core/img/toonatar-backgrounds/tf24.jpg'
import ttc from 'core/img/toonatar-backgrounds/ttc.jpg'
import pride from 'core/img/toonatar-backgrounds/pride.jpg'
import trans from 'core/img/toonatar-backgrounds/trans.jpg'

export const ttrBackgrounds = [
    { id: 'ttc', name: 'Toontown Central', image: ttc },
    { id: 'dd', name: "Donald's Dock", image: dd },
    { id: 'dg', name: 'Daisy Gardens', image: dg },
    { id: 'mml', name: "Minnie's Melodyland", image: mml },
    { id: 'tb', name: 'The Brrrgh', image: tb },
    { id: 'ddl', name: "Donald's Dreamland", image: ddl },
    { id: 'estate', name: 'Estate', image: estate },
    { id: 'aa', name: 'Acorn Acres', image: aa },
    { id: 'gs', name: 'Goofy Speedway', image: gs },
    { id: 'sbhq', name: 'Sellbot HQ', image: sbhq },
    { id: 'cbhq', name: 'Cashbot HQ', image: cbhq },
    { id: 'lbhq', name: 'Lawbot HQ', image: lbhq },
    { id: 'bbhq', name: 'Bossbot HQ', image: bbhq },
    { id: 'sm', name: 'Silly Meter', image: sm },

    { id: 'tf23', name: 'ToonFest \'23', image: tf23 },
    { id: 'tf24', name: 'ToonFest \'24', image: tf24 },

    { id: 'pride', name: 'Rainbow', image: pride },
    { id: 'trans', name: 'Soft Scarf', image: trans },
]

export const ccBackgrounds = [
    { id: 'ttc', name: 'Toontown Central', image: ttc },
    { id: 'bb', name: 'Barnacle Boatyard', image: dd },
    // { id: 'yott', name: "Ye Olde Toontowne", image:  },
    { id: 'dg', name: 'Daffodil Gardens', image: dg },
    { id: 'mml', name: 'Mezzo Melodyland', image: mml },
    { id: 'tb', name: 'The Brrrgh', image: tb },
    { id: 'aa', name: 'Acorn Acres', image: aa },
    { id: 'ddl', name: 'Drowsy Dreamland', image: ddl },
    { id: 'estate', name: 'Estate', image: estate },
    { id: 'rr', name: 'Roadster Raceway', image: gs },
    { id: 'sbhq', name: 'Sellbot HQ', image: sbhq },
    { id: 'cbhq', name: 'Cashbot HQ', image: cbhq },
    { id: 'lbhq', name: 'Lawbot HQ', image: lbhq },
    { id: 'bbhq', name: 'Bossbot HQ', image: bbhq },
]

export const keyedTtrBackgrounds = _.keyBy(ttrBackgrounds, 'id')

export const keyedCcBackgrounds = _.keyBy(ccBackgrounds, 'id')

export const gameBackgrounds = {
    1: ttrBackgrounds,
    2: ccBackgrounds,
}

export const keyedGameBackgrounds = {
    1: keyedTtrBackgrounds,
    2: keyedCcBackgrounds,
}

export const conic = (...colors) => {
    const pctPerColor = 100 / colors.length
    const conicParts = []
    _.each(colors, (c, idx) => {
        conicParts.push(`${c} ${(idx * pctPerColor).toFixed(2)}%`)
        conicParts.push(`${c} ${((idx + 1) * pctPerColor).toFixed(2)}%`)
    })
    const initialOffset = (360 / colors.length) / 2
    return `conic-gradient(from -${initialOffset.toFixed(2)}deg, ${conicParts.slice(1, -1).join(', ')})`
}


export const ringCategories = [
    {
        name: 'Gags',
        rings: _.map(gameTracks[1], t => ({ id: t.id, name: t.name, background: t.color })),
    },
    {
        name: 'Pride',
        rings: [
            {
                id: 'pride',
                name: 'Pride',
                background: conic('#E40303', '#FF8C00', '#FFED00', '#008026', '#24408E', '#732982', '#FFFFFF', '#FFAFC8', '#74D7EE', '#613915', '#000000'),
            },
            {
                id: 'trans',
                name: 'Trans',
                background: conic('#FFAFC8', '#74D7EE', '#FFFFFF'),
            },
            {
                id: 'nonbin',
                name: 'Nonbinary',
                background: conic('#FCF434', '#FFFFFF', '#9C59D1', '#2C2C2C'),
            },
            {
                id: 'neuro',
                name: 'Neurodivergent',
                background: 'conic-gradient(rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%)',
            },
            {
                id: 'les',
                name: 'Lesbian',
                background: conic('#D52D00', '#EF7627', '#FF9A56', '#FFFFFF', '#D162A4', '#B55690', '#A30262'),
            },
            {
                id: 'gay',
                name: 'Gay',
                background: conic('#078D70', '#26CEAA', '#98E8C1', '#FFFFFF', '#7BADE2', '#5049CC', '#3D1A78'),
            },
            {
                id: 'bi',
                name: 'Bi',
                background: conic('#D60270', '#9B4F96', '#0038A8'),
            },
            {
                id: 'pan',
                name: 'Pan',
                background: conic('#FF218C', '#FFD800', '#21B1FF'),
            },
            {
                id: 'ace',
                name: 'Ace / Demi',
                background: conic('#000000', '#A3A3A3', '#FFFFFF', '#800080'),
            },
            {
                id: 'aro',
                name: 'Aro',
                background: conic('#3DA542', '#A7D379', '#FFFFFF', '#A9A9A9', '#000000'),
            },
            {
                id: 'aroace',
                name: 'Aroace',
                background: conic('#EF9007', '#F6D317', '#FFFFFF', '#45BCEE', '#1E3F54'),
            },
        ],
    },
]

export const keyedRingCategories = _.keyBy(ringCategories, 'name')

export const keyedRings = _.reduce(ringCategories, (result, category) => _.assign(result, _.keyBy(category.rings, (r) => {
    r.categoryName = category.name
    return r.id
})), {})
