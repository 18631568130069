//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import { cogTypeImages } from 'core/util/cogs'
import CogPicker from './invasion-cog-picker'

export default {
    components: { CogPicker },

    computed: {
        anyNotificationsEnabled() {
            return this.notificationTypes.invasionStarted || this.notificationTypes.invasionEndingSoon || this.notificationTypes.invasionEnded
        },

        cogsByType() {
            return _.map(this.cogTypes, (type) => {
                // Get all the "base" cogs (not V2.0, Skelecog, etc.) and use their names as their IDs
                const baseCogs = _.map(
                    _.filter(this.cogList, c => c.type === type && c.name.indexOf('Version 2.0') === -1 && c.name.indexOf('Skelecog') === -1),
                    cog => ({ ...cog, id: cog.name }),
                )

                const specialCogs = []
                if (this.game.id === 1) {
                    specialCogs.push({ id: `__${type}__SKELECOG__`, name: 'Skelecogs', image: 'skelecog.png' })
                    specialCogs.push({ id: `__${type}__VERSION20__`, name: 'Version 2.0 Cogs', image: 'skelecog.png' })
                }

                return {
                    name: type,
                    image: cogTypeImages[type],
                    cogs: [...baseCogs, ...specialCogs],
                }
            })
        },

        ...mapGetters(['cogList', 'cogTypes', 'game', 'notificationsSupported', 'notificationsApproved']),
        ...mapState({
            notifyAllCogs: state => state.invasions.notifyAllCogs,
            notifyCogs: state => state.invasions.notifyCogs,
            notifySounds: state => state.invasions.notifySounds,
            notificationTypes: state => state.notifications.notificationTypes,
        }),
    },

    methods: {
        toggleNotificationType(name, e) {
            this.setNotificationPreference({ type: name, value: e.value })
        },

        ...mapActions(['setNotificationPreference']),
        ...mapMutations(['toggleNotifyAllCogs', 'toggleNotifySounds']),
    },
}
