//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'

import { ModalFormMixin } from 'core/components/forms/mixin'

export default {
    name: 'ModerateForm',

    props: ['target', 'reportId'],

    mixins: [ModalFormMixin],

    fields: [
        { name: 'no_create_group', default: () => false },
        { name: 'no_join_group', default: () => false },
        { name: 'no_chat', default: () => false },
        { name: 'no_edit_toons', default: () => false },
        { name: 'no_report', default: () => false },

        'reason',
        'expires',
    ],

    endpoint: '/api/groups/moderate/',

    data() {
        return {
            reasonOptions: [
                { text: 'Your toon name was inappropriate.', value: 'Your toon name was inappropriate.' },
                { text: 'You sent inappropriate messages.', value: 'You sent inappropriate messages.' },
                { text: 'You were spamming.', value: 'You were spamming.' },
                { text: 'You were extremely inappropriate.', value: 'You were extremely inappropriate.' },
                { text: 'Other', value: '__other__' },
            ],
            reason: null,
            otherReason: null,

            ipBanOptions: [
                { text: 'Auto (Recommended)', value: null },
                { text: 'Force', value: true },
            ],
            ipBan: null,

            banLengthOptions: [
                { text: 'Day', value: 1 },
                { text: 'Week', value: 7 },
                { text: 'Month', value: 30 },
                { text: 'Forever', value: null },
            ],
            banLength: 1,
        }
    },

    methods: {
        clean(data) {
            if (this.target.member) data.target_member = this.target.member.id
            if (this.target.message) data.target_message = this.target.message.id
            data.report = this.target.reportId
            data.reason = this.reason === '__other__' ? this.otherReason : this.reason
            if (this.banLength !== null) data.expires = moment().add(this.banLength, 'days').format()
            if (this.ipBan) data.ip_ban = true
            data.report = this.reportId

            return data
        },
    },

    mounted() {
        if (this.target.message) this.reason = 'You sent inappropriate messages.'
    },

    watch: {
        reason: function onReasonChange(val) {
            if (val === 'Your toon name was inappropriate.') {
                this.banLength = 7
                this.model.no_create_group.value = false
                this.model.no_join_group.value = false
                this.model.no_edit_toons.value = true
                this.model.no_chat.value = true
                this.model.no_report.value = false
            } else if (val === 'You sent inappropriate messages.') {
                this.banLength = 7
                this.model.no_create_group.value = false
                this.model.no_join_group.value = false
                this.model.no_edit_toons.value = false
                this.model.no_chat.value = true
                this.model.no_report.value = false
            } else if (val === 'You were spamming.') {
                this.banLength = 7
                this.model.no_create_group.value = true
                this.model.no_join_group.value = true
                this.model.no_edit_toons.value = true
                this.model.no_chat.value = true
                this.model.no_report.value = true
            } else if (val === 'You were extremely inappropriate.') {
                this.banLength = null
                this.model.no_create_group.value = true
                this.model.no_join_group.value = true
                this.model.no_edit_toons.value = true
                this.model.no_chat.value = true
                this.model.no_report.value = true
            }
        },
    },
}
