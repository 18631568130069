//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ModalFormMixin } from 'core/components/forms/mixin'

export default {
    name: 'ChangePasswordForm',

    mixins: [ModalFormMixin],

    modalClass: 'modal-content modal-content--sm',

    fields: [
        'old_password',
        'new_password1',
        'new_password2',
    ],

    dispatch: 'changePassword',
}
