import Vue from 'vue'
import Vuedals, { Component as VuedalsComponent, Bus } from 'vuedals'

Vue.use(Vuedals)

export const Vuedal = VuedalsComponent

export const present = (component, props, className) => {
    let { closeOnBackdrop } = component
    if (closeOnBackdrop === undefined) {
        // Auto detect whether this modal can be closed on backdrop based on whether form is in its name
        closeOnBackdrop = !(component.name && component.name.toLowerCase().includes('form'))
    }

    Bus.$emit('new', {
        name: className || component.modalClass || 'modal-content',
        component,
        props,
        dismissable: false,
        closeOnBackdrop,
    })
}

export const updateModalClass = function updateModalClass(className) {
    const vuedalComponent = this.$root.$children[0].$refs.vuedal
    const targetModal = vuedalComponent.vuedals[vuedalComponent.vuedals.length - 1]
    targetModal.name = className || 'modal-content'
}

export const swapModal = function swapModal(component, props, className) {
    const vuedalComponent = this.$root.$children[0].$refs.vuedal
    const targetModal = vuedalComponent.vuedals[vuedalComponent.vuedals.length - 1]
    targetModal.name = className || component.modalClass || 'modal-content'
    targetModal.component = component
    targetModal.props = props
}

Vue.prototype.$present = present

Vue.prototype.$updateModalClass = updateModalClass

Vue.prototype.$swapModal = swapModal
