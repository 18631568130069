//
//
//
//
//
//
//
//
//

import _ from 'lodash'

const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

export default {
    props: {
        xs: { type: String, required: false },
        sm: { type: String, required: false },
        md: { type: String, required: false },
        lg: { type: String, required: false },
        xl: { type: String, required: false },

        displayType: {
            type: String,
            default: 'inline-block',
        },
    },

    computed: {
        spans() {
            // Figure out which points we were passed
            const pointsWithValues = []
            _.each(breakpoints, (b) => {
                if (this[b]) pointsWithValues.push({ breakpoint: b, text: this[b] })
            })

            return _.map(pointsWithValues, (point, idx) => {
                const bp = point.breakpoint
                const prefix = bp !== 'xs' ? `d-${bp}` : 'd-'
                const classes = [`${prefix}-${this.displayType}`]
                if (bp !== 'xs') classes.push('d-none')
                const next = pointsWithValues[idx + 1]
                if (next) classes.push(`d-${next.breakpoint}-none`)

                return { classes, text: point.text }
            })
        },
    },
}
