//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'

import { decodeFullCogDisguise } from 'core/util/cogs'
import { gameTracks } from 'core/util/gags'
import labels from 'core/util/labels'

export default {
    props: ['toon', 'suit'],

    data() {
        return {
            gagTracks: gameTracks[this.toon.game],
        }
    },

    computed: {
        hasGags() {
            return !!_.find(this.gagTracks, track => this.toon[track.id])
        },

        disguise() {
            // Pull up our cog disguise if we have one
            if (!this.suit) return null
            const encoded = this.toon[this.suit.id]
            const disguise = decodeFullCogDisguise(this.toon.game, this.suit.id, encoded)
            if (!disguise) return null

            // Build the tooltip
            let title = disguise.cog.name
            if (disguise.level) title += `<br />Level ${disguise.level}`
            if (disguise.version !== 1) title += ` v${disguise.version}.0`

            // Calculate our progress towards completing this portion
            const cogLevels = disguise.cog.max - disguise.cog.min + 1
            const progress = Math.max((disguise.level - disguise.cog.min + 1) / cogLevels, 0.075)

            return {
                ...disguise,
                title,
                progress: `${Math.round(progress * 100)}%`,
            }
        },

        label() {
            return labels[this.toon.label]
        },

        styleForSuit() {
            const colorsByVersion = {
                1: ['#dcdcdc', '#5555dd'],
                2: ['#dcdcdc', '#339933'],
            }
            const versionColors = colorsByVersion[this.disguise.version]

            const style = {
                background: `conic-gradient(${versionColors[1]} ${this.disguise.progress}, ${versionColors[0]} ${this.disguise.progress})`,
            }
            return style
        },
    },

    methods: {
        classesForTrack(track) {
            const classes = []
            if (this.toon[track.id] === 0) classes.push('toon__track--missing')
            if (this.toon.prestiges.includes(track.id)) classes.push('toon__track--prestiged')
            return classes
        },

        styleForTrack(track) {
            const style = {
                background: `${track.color}cc`,
            }
            if (this.toon.prestiges.includes(track.id)) {
                style['box-shadow'] = `0px 0px 5px 5px ${track.color}`
            }
            return style
        },

        gagForTrack(track) {
            if (this.toon[track.id] === 0) return track.gags[0]
            return track.gags[this.toon[track.id] - 1]
        },

        verbalLevelForTrack(track) {
            if (this.toon[track.id] === 0) return `${track.name}less`
            let gagName = track.gags[this.toon[track.id] - 1].name
            if (this.toon.prestiges.includes(track.id)) {
                if (this.toon.game === 1) {
                    gagName += ' (Organic)'
                } else {
                    gagName += ' (Prestiged)'
                }
            }
            return gagName
        },
    },
}
