import _ from 'lodash'

import { BeanColor } from 'gardening/flowers'

export const statues = [
    {
        name: 'Donald Statue',
        cost: 125,
        combination: [BeanColor.GREEN, BeanColor.GREEN],
    },

    {
        name: 'Mickey Statue',
        cost: 250,
        combination: [BeanColor.GREY, BeanColor.GREY, BeanColor.GREY, BeanColor.GREY],
    },

    {
        name: 'Minnie Statue',
        cost: 500,
        combination: [BeanColor.VIOLET, BeanColor.VIOLET, BeanColor.VIOLET, BeanColor.VIOLET, BeanColor.VIOLET, BeanColor.VIOLET],
    },

    {
        name: 'Mickey Fountain',
        cost: 1000,
        combination: [BeanColor.ORANGE, BeanColor.ORANGE, BeanColor.ORANGE, BeanColor.ORANGE, BeanColor.ORANGE, BeanColor.ORANGE, BeanColor.ORANGE, BeanColor.ORANGE],
    },

    {
        name: 'Toon Wave Statue',
        cost: 5000,
        combination: [BeanColor.RED, BeanColor.RED, BeanColor.RED, BeanColor.RED, BeanColor.RED, BeanColor.RED, BeanColor.RED, BeanColor.RED],
    },

    {
        name: 'Toon Victory Statue',
        cost: 5000,
        combination: [BeanColor.GREEN, BeanColor.GREEN, BeanColor.GREEN, BeanColor.GREEN, BeanColor.GREEN, BeanColor.GREEN, BeanColor.GREEN, BeanColor.GREEN],
    },

    {
        name: 'Toon Authority Statue',
        cost: 5000,
        combination: [BeanColor.BLUE, BeanColor.BLUE, BeanColor.BLUE, BeanColor.BLUE, BeanColor.BLUE, BeanColor.BLUE, BeanColor.BLUE, BeanColor.BLUE],
    },

    {
        name: 'Toon Embrace Statue',
        cost: 5000,
        combination: [BeanColor.GREY, BeanColor.GREY, BeanColor.GREY, BeanColor.GREY, BeanColor.GREY, BeanColor.GREY, BeanColor.GREY, BeanColor.GREY],
    },

    {
        name: 'Melting Snowman',
        cost: 25,
        combination: [BeanColor.GREY],
    },

    {
        name: 'Melting Snowdoodle',
        cost: 50,
        combination: [BeanColor.GREY],
    },

    {
        name: 'Flappy Cog',
        cost: 50,
        combination: [BeanColor.GREY],
    },
]

_.each(statues, (s) => {
    s.image = `${_.snakeCase(s.name)}.png`
})
