//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { mapState, mapGetters } from 'vuex'

import ToonForm from 'accounts/components/toon-form'
import games from 'core/util/games'
import { selectToon } from 'core/util/toons'

export default {
    props: {
        mobile: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return { games }
    },

    computed: {
        toonList() {
            return _.sortBy(_.filter(this.toons, t => !t.hidden), ['order', 'id'])
        },

        ...mapState({
            user: state => state.accounts.user,
            toons: state => state.accounts.toons,
        }),
        ...mapGetters(['isAuthenticated', 'currentToon']),
    },

    methods: {
        editToon(id) {
            this.$present(ToonForm, { values: this.toons[id] })
        },

        selectToon,
    },
}
