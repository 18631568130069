//
//
//
//
//
//
//

import ResizeObserver from 'resize-observer-polyfill'

const parallaxHeight = 600

export default {
    props: ['game'],

    data() {
        return {
            width: 0,
            height: 0,
            forward: true,
            interval: null,
            ro: null,
        }
    },

    computed: {
        bgStyle() {
            return {
                transform: `translateX(${this.forward ? this.translationTarget : 0}px)`,
            }
        },

        translationTarget() {
            if (!this.width) return 0
            const ratio = this.height / parallaxHeight
            return -((this.game.parallaxWidth * ratio) - this.width)
        },
    },

    mounted() {
        this.width = this.$refs.parallax.offsetWidth
        this.height = this.$refs.parallax.offsetHeight

        // Set up bouncing
        this.interval = setInterval(() => {
            this.forward = !this.forward
        }, 150000)

        // Adjust the target if the window resizes
        this.ro = new ResizeObserver(() => {
            this.width = this.$refs.parallax.offsetWidth
            this.height = this.$refs.parallax.offsetHeight
        })
        this.ro.observe(this.$refs.parallax)
    },

    beforeDestroy() {
        clearInterval(this.interval)
        this.ro.disconnect()
    },
}
