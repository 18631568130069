//
//
//
//
//
//
//
//
//

export default {
    props: {
        icon: {
            type: String,
            required: true,
        },

        title: {
            type: String,
        },

        message: {
            type: String,
        },

        inline: {
            type: Boolean,
            default: false,
        },

        placeholder: {
            type: Boolean,
            default: false,
        },
    },
}
