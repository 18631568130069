//
//
//
//
//
//
//
//
//
//
//

import { traitScores, traitScoreColors } from 'doodles/traits'

export default {
    name: 'DoodleCard',

    props: ['doodle'],

    computed: {
        image() {
            return `https://rendition.toontownrewritten.com/render/${this.doodle.dna}/doodle/200x200.webp`
        },
    },

    methods: {
        colorForTrait(trait) {
            return { color: traitScoreColors[traitScores[trait] || 0] }
        },
    },
}
