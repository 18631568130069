import Vue from 'vue'

import ActiveInvasionsList from './active-invasions-list'
import InvasionCard from './invasion-card'
import InvasionsList from './invasions-list'
import InvasionTimeRemaining from './invasion-time-remaining'
import InvasionChart from './invasion-chart'
import TimeSinceLastInvasion from './time-since-last-invasion'

Vue.component('active-invasions-list', ActiveInvasionsList)
Vue.component('invasion-card', InvasionCard)
Vue.component('invasions-list', InvasionsList)
Vue.component('invasion-time-remaining', InvasionTimeRemaining)
Vue.component('invasion-chart', InvasionChart)
Vue.component('time-since-last-invasion', TimeSinceLastInvasion)
