//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

import PulseMixin from 'core/util/pulse'

export default {
    name: 'GardeningPage',

    metaInfo() {
        const { game } = this
        return {
            titleTemplate: chunk => `${chunk} | Gardening Guide | ToonHQ for ${game.name}`,
        }
    },

    theme: 'gardening',

    mixins: [PulseMixin],

    computed: {
        ...mapGetters(['game']),
    },
}
