//
//
//
//
//
//
//
//
//

export default {
    id: 23,
    version: '3.8.10',
    date: '2023-08-05',
    notify: true,
}
