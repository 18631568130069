//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'
import _ from 'lodash'
import { mapGetters } from 'vuex'

import { assetPath } from 'core/util/filters'
import PulseMixin from 'core/util/pulse'
import DoodleCard from 'doodles/components/doodle-card'
import { attributes, buildDna, valuesToSubstringFilter } from 'doodles/dna'
import { doodleScore } from 'doodles/traits'

export default {
    name: 'DoodlePage',

    components: { DoodleCard },

    metaInfo() {
        return {
            title: 'Pet Shop Doodles',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: `Our pet shop tracker will help you find the perfect doodle for ${this.game.name} in record time! See each pet shop's doodles in one convenient spot!`,
            }],
        }
    },

    theme: 'shop',

    mixins: [PulseMixin],

    data() {
        const attributeFilters = {}
        _.each(attributes, (attr) => {
            attributeFilters[attr.id] = []
        })

        return {
            doodles: null,

            hideDuplicates: true,
            traits: [],
            showing: 30,
            filtersExpanded: false,
            attributeFilters,
        }
    },

    computed: {
        filteredDoodles() {
            let doodles = _.filter(this.doodles, (d) => {
                if (this.traits.length && _.intersection(d.traits, this.traits).length !== this.traits.length) return false
                if (this.substringFilters.length && !this.doodleMatchesSubstringFilters(d)) return false
                return true
            })
            if (this.hideDuplicates) doodles = _.uniqBy(doodles, d => `${d.dna}${d.traits.join('-')}${d.cost}`)
            return doodles
        },

        showDoodles() {
            const doodles = _.sortBy(this.filteredDoodles, d => -d.score)
            return _.slice(doodles, 0, this.showing)
        },

        filterPermutations() {
            return _.map(_.orderBy(_.filter(attributes, a => a.show), 'order'), attr => ({
                ...attr,
                options: _.map(attr.values, value => ({
                    value,
                    dna: buildDna({ [attr.id]: value }),
                })),
            }))
        },

        optionStyling: () => (attr, option, optionIdx) => {
            let image = `https://rendition.toontownrewritten.com/render/${option.dna}/doodle/${attr.resolution}x${attr.resolution}.webp`
            if (!attr.resolution) image = assetPath(`doodles/${attr.id}/${optionIdx}.png`)
            return {
                backgroundImage: `url("${image}")`,
                ...attr.style,
            }
        },

        substringFilters() {
            const filters = []
            _.each(this.attributeFilters, (values, attrId) => {
                if (!values.length) return
                filters.push(valuesToSubstringFilter(attrId, values))
            })
            return filters
        },

        ...mapGetters(['game']),
    },

    methods: {
        async prepare() {
            await this.loadDoodles()
        },

        async loadDoodles() {
            const response = await axios.get(`/api/doodles/${this.game.id}/`)
            this.doodles = []
            _.each(response.data, (hoods, district) => {
                _.each(hoods, (doodles, hood) => {
                    _.each(doodles, (doodle) => {
                        this.doodles.push({ ...doodle, district, hood, score: doodleScore(doodle) })
                    })
                })
            })
        },

        showMoreDoodles($state) {
            this.showing = Math.min(this.filteredDoodles.length, this.showing + 30)
            if (this.showing === this.filteredDoodles.length) {
                $state.complete()
            } else {
                setTimeout(() => $state.loaded(), 0)
            }
        },

        toggleAttributeFilter(attr, option) {
            const attrValues = this.attributeFilters[attr.id]
            const idx = attrValues.indexOf(option.value)
            if (idx !== -1) {
                attrValues.splice(idx, 1)
            } else {
                attrValues.push(option.value)
            }
        },

        doodleMatchesSubstringFilters(d) {
            let matches = true
            _.each(this.substringFilters, (sf) => {
                if (!sf.values.includes(d.dna.substr(sf.index, 2))) {
                    matches = false
                    return false
                }
            })
            return matches
        },

        resetFilters() {
            _.each(attributes, (attr) => {
                this.$set(this.attributeFilters, attr.id, [])
            })
        },
    },
}
