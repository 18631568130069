//
//
//
//
//
//
//
//
//
//
//
//

import { changelog } from 'core/changelog'
import ChangelogList from 'core/components/changelog-list'

export default {
    name: 'ChangelogPage',

    components: { ChangelogList },

    metaInfo() {
        return {
            title: 'Changelog',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: "Find out what's new with ToonHQ! You'll find all the latest release notes here.",
            }],
        }
    },

    data() {
        return {
            changelog,
        }
    },
}
