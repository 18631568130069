//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCurrentProvider } from 'core/components/ad-controllers/providers'

export default {
    name: 'PrivacyPage',

    metaInfo() {
        return {
            title: 'Privacy Policy',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: "Privacy is a top priority for us, and we won't do anything with your information that we wouldn't want done with ours.",
            }],
        }
    },

    data() {
        return {
            provider: getCurrentProvider(),
            npCcpa: false,
        }
    },

    methods: {
        waitForNpCcpaLink() {
            const linkContainer = this.$refs.npCcpaLink
            const nestedSpan = linkContainer.querySelector('a > span')
            if (!nestedSpan) return

            nestedSpan.innerText = 'over here.'
            this.npCcpa = true
            clearInterval(this.npCcpaInterval)
        },
    },

    mounted() {
        if (this.provider?.id === 'np') {
            this.npCcpaInterval = setInterval(this.waitForNpCcpaLink, 50)
            if (window.__uspapi) window.__uspapi('addLink', 1)
        }
    },

    beforeDestroy() {
        clearInterval(this.npCcpaInterval)
    },
}
