//
//
//
//

import moment from 'moment'

export default {
    props: ['date'],

    data() {
        return {
            moment: moment(this.date),
        }
    },

    methods: {
        fromNow() {
            const text = this.moment.fromNow()
            if (text === 'a few seconds ago') return 'just now'
            return text.replace('minutes', 'mins').replace('minute', 'min')
        },
    },

    created() {
        this.$listenFor('TICK_10SEC', () => {
            this.$forceUpdate()
        })
    },

    watch: {
        date() {
            this.moment = moment(this.date)
        },
    },
}
