//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

import { statues } from 'gardening/statues'

export default {
    name: 'GardeningTutorialPage',

    metaInfo() {
        return {
            title: 'Gardening Tutorial',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: `Learn how to garden in ${this.game.name}, and why you should start planting those flowers today!`,
            }],
        }
    },

    data() {
        return {
            statues,
        }
    },

    computed: {
        ...mapGetters(['game']),
    },
}
