//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'FaqPage',

    metaInfo() {
        return {
            title: 'FAQ',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: 'ToonHQ.org is the place to be for TTR and TTCC! Utilize our group tracker to instantly find Toons to fight bosses, take over cog buildings, or play a rousing game of minigolf!',
            }],
        }
    },
}
