import _ from 'lodash'

import Sellbot from 'core/img/cog-types/sellbot-hd.png'
import Cashbot from 'core/img/cog-types/cashbot-hd.png'
import Lawbot from 'core/img/cog-types/lawbot-hd.png'
import Bossbot from 'core/img/cog-types/bossbot-hd.png'
import Boardbot from 'core/img/cog-types/boardbot.png'

export const cogTypeImages = {
    Sellbot, Cashbot, Lawbot, Bossbot, Boardbot,
}

const ttrSuits = [
    {
        id: 'sellbot',
        name: 'Sellbot',
        image: Sellbot,
        ranks: [
            { name: 'Cold Caller', image: 'cold_caller.png', min: 1, max: 5 },
            { name: 'Telemarketer', image: 'telemarketer.png', min: 2, max: 6 },
            { name: 'Name Dropper', image: 'name_dropper.png', min: 3, max: 7 },
            { name: 'Glad Hander', image: 'glad_hander.png', min: 4, max: 8 },
            { name: 'Mover & Shaker', image: 'mover_and_shaker.png', min: 5, max: 9 },
            { name: 'Two-Face', image: 'two_face.png', min: 6, max: 10 },
            { name: 'The Mingler', image: 'the_mingler.png', min: 7, max: 11 },
            { name: 'Mr. Hollywood', image: 'mr_hollywood.png', min: 8, max: 50 },
        ],
    },

    {
        id: 'cashbot',
        name: 'Cashbot',
        image: Cashbot,
        ranks: [
            { name: 'Short Change', image: 'short_change.png', min: 1, max: 5 },
            { name: 'Penny Pincher', image: 'penny_pincher.png', min: 2, max: 6 },
            { name: 'Tightwad', image: 'tightwad.png', min: 3, max: 7 },
            { name: 'Bean Counter', image: 'bean_counter.png', min: 4, max: 8 },
            { name: 'Number Cruncher', image: 'number_cruncher.png', min: 5, max: 9 },
            { name: 'Money Bags', image: 'money_bags.png', min: 6, max: 10 },
            { name: 'Loan Shark', image: 'loan_shark.png', min: 7, max: 11 },
            { name: 'Robber Baron', image: 'robber_baron.png', min: 8, max: 50 },
        ],
    },

    {
        id: 'lawbot',
        name: 'Lawbot',
        image: Lawbot,
        ranks: [
            { name: 'Bottom Feeder', image: 'bottom_feeder.png', min: 1, max: 5 },
            { name: 'Bloodsucker', image: 'bloodsucker.png', min: 2, max: 6 },
            { name: 'Double Talker', image: 'double_talker.png', min: 3, max: 7 },
            { name: 'Ambulance Chaser', image: 'ambulance_chaser.png', min: 4, max: 8 },
            { name: 'Back Stabber', image: 'backstabber.png', min: 5, max: 9 },
            { name: 'Spin Doctor', image: 'spin_doctor.png', min: 6, max: 10 },
            { name: 'Legal Eagle', image: 'legal_eagle.png', min: 7, max: 11 },
            { name: 'Big Wig', image: 'big_wig.png', min: 8, max: 50 },
        ],
    },

    {
        id: 'bossbot',
        name: 'Bossbot',
        image: Bossbot,
        ranks: [
            { name: 'Flunky', image: 'flunky.png', min: 1, max: 5 },
            { name: 'Pencil Pusher', image: 'pencil_pusher.png', min: 2, max: 6 },
            { name: 'Yesman', image: 'yes_man.png', min: 3, max: 7 },
            { name: 'Micromanager', image: 'micromanager.png', min: 4, max: 8 },
            { name: 'Downsizer', image: 'downsizer.png', min: 5, max: 9 },
            { name: 'Head Hunter', image: 'head_hunter.png', min: 6, max: 10 },
            { name: 'Corporate Raider', image: 'corporate_raider.png', min: 7, max: 11 },
            { name: 'The Big Cheese', image: 'the_big_cheese.png', min: 8, max: 50 },
        ],
    },
]

const ccSuits = [
    {
        id: 'sellbot',
        name: 'Sellbot',
        image: Sellbot,
        ranks: [
            { name: 'Cold Caller', image: 'cold_caller.png', min: 1, max: 5 },
            { name: 'Telemarketer', image: 'telemarketer.png', min: 2, max: 6 },
            { name: 'Name Dropper', image: 'name_dropper.png', min: 3, max: 7 },
            { name: 'Glad Hander', image: 'glad_hander.png', min: 4, max: 8 },
            { name: 'Mover & Shaker', image: 'mover_and_shaker.png', min: 5, max: 9 },
            { name: 'Two-Face', image: 'two_face.png', min: 6, max: 10 },
            { name: 'The Mingler', image: 'the_mingler.png', min: 7, max: 11 },
            { name: 'Mr. Hollywood', image: 'cc_mr_hollywood.png', min: 8, max: 50 },
        ],
    },

    {
        id: 'cashbot',
        name: 'Cashbot',
        image: Cashbot,
        ranks: [
            { name: 'Short Change', image: 'short_change.png', min: 1, max: 5 },
            { name: 'Penny Pincher', image: 'penny_pincher.png', min: 2, max: 6 },
            { name: 'Tightwad', image: 'tightwad.png', min: 3, max: 7 },
            { name: 'Bean Counter', image: 'bean_counter.png', min: 4, max: 8 },
            { name: 'Number Cruncher', image: 'number_cruncher.png', min: 5, max: 9 },
            { name: 'Money Bags', image: 'money_bags.png', min: 6, max: 10 },
            { name: 'Loan Shark', image: 'loan_shark.png', min: 7, max: 11 },
            { name: 'Robber Baron', image: 'robber_baron.png', min: 8, max: 50 },
        ],
    },

    {
        id: 'lawbot',
        name: 'Lawbot',
        image: Lawbot,
        ranks: [
            { name: 'Bottom Feeder', image: 'cc_bottom_feeder.png', min: 1, max: 5 },
            { name: 'Bloodsucker', image: 'cc_bloodsucker.png', min: 2, max: 6 },
            { name: 'Double Talker', image: 'cc_double_talker.png', min: 3, max: 7 },
            { name: 'Ambulance Chaser', image: 'cc_ambulance_chaser.png', min: 4, max: 8 },
            { name: 'Back Stabber', image: 'cc_backstabber.png', min: 5, max: 9 },
            { name: 'Spin Doctor', image: 'cc_spin_doctor.png', min: 6, max: 10 },
            { name: 'Legal Eagle', image: 'cc_legal_eagle.png', min: 7, max: 11 },
            { name: 'Big Wig', image: 'cc_big_wig.png', min: 8, max: 50 },
            { name: 'Pettifogger', image: 'pettifogger_exe.png', min: 2, max: 7 },
            { name: 'Needlenose', image: 'needlenose_exe.png', min: 3, max: 10 },
            { name: 'Conveyancer', image: 'conveyancer_exe.png', min: 4, max: 8 },
            { name: 'Advocate', image: 'advocate_exe.png', min: 5, max: 15 },
            { name: 'Shyster', image: 'shyster_exe.png', min: 6, max: 12 },
            { name: 'Barrister', image: 'barrister_exe.png', min: 7, max: 15 },
        ],
    },

    {
        id: 'bossbot',
        name: 'Bossbot',
        image: Bossbot,
        ranks: [
            { name: 'Flunky', image: 'flunky.png', min: 1, max: 5 },
            { name: 'Pencil Pusher', image: 'pencil_pusher.png', min: 2, max: 6 },
            { name: 'Yesman', image: 'yes_man.png', min: 3, max: 7 },
            { name: 'Micromanager', image: 'micromanager.png', min: 4, max: 8 },
            { name: 'Downsizer', image: 'downsizer.png', min: 5, max: 9 },
            { name: 'Head Hunter', image: 'head_hunter.png', min: 6, max: 10 },
            { name: 'Corporate Raider', image: 'corporate_raider.png', min: 7, max: 11 },
            { name: 'The Big Cheese', image: 'the_big_cheese.png', min: 8, max: 50 },
        ],
    },
]

export const keyedTtrSuits = _.keyBy(ttrSuits, 'id')

export const keyedCcSuits = _.keyBy(ccSuits, 'id')

export const gameSuits = {
    1: ttrSuits,
    2: ccSuits,
}

export const keyedGameSuits = {
    1: keyedTtrSuits,
    2: keyedCcSuits,
}

export const decodeCogDisguise = (encoded) => {
    if (!encoded) return { suit: 0, version: 0, level: 0 }
    const suit = parseInt(encoded[0], 16)
    const version = parseInt(encoded[1], 16)
    const level = parseInt(encoded.substring(2, 4), 16)
    return { suit, version, level }
}

export const decodeFullCogDisguise = (gameId, cogType, encoded) => {
    // Get the basic disguise info
    const disguise = decodeCogDisguise(encoded)
    if (disguise.suit === 0) return null

    // Pull up the cog info
    let cog = { ...keyedGameSuits[gameId][cogType].ranks[disguise.suit - 1] }

    // Force all TTR v2.0+ disguises to go from 8-50
    if (gameId === 1 && disguise.version > 1) cog = { ...cog, min: 8, max: 50 }

    return { ...disguise, cog }
}

export const encodeCogDisguise = (suit, version, level) => `${suit.toString(16)}${version.toString(16)}${level.toString(16).padStart(2, '0')}`
