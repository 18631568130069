//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    id: 6,
    version: '3.7.1',
    date: '2022-02-19',
    notify: true,
}
