//
//
//
//

export default {
    props: ['invasion', 'long'],

    methods: {
        timeRemaining() {
            return this.$store.getters.timeRemaining(this.invasion, this.long)
        },
    },

    created() {
        this.$listenFor('TICK_SEC', () => this.$forceUpdate())
    },
}
