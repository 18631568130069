//
//
//
//
//
//
//
//
//

export default {
    id: 36,
    version: '3.8.17',
    date: '2024-03-08',
    notify: true,
}
