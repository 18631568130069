//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'
import Q from 'q'

import { showNotification } from 'core/notifications'
import { audioManager, invasionSounds } from 'core/util/audio'
import { assetPath } from 'core/util/filters'
import games from 'core/util/games'
import PulseMixin from 'core/util/pulse'

export default {
    name: 'InvasionsPage',

    metaInfo() {
        return {
            title: 'Invasion Tracker',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: `A beautiful ${this.game.shortName} invasion tracker. Track invasions, get notifications and more! Never miss a ${this.game.name} invasion!`,
            }],
        }
    },

    theme: 'invasions',

    mixins: [PulseMixin],

    data() {
        return { games }
    },

    computed: {
        ...mapGetters(['notificationsSupported', 'shouldNotifyForCog', 'timeRemaining', 'game', 'socketIssue', 'socketOutdated']),
        ...mapState({
            invasion_server_working: state => (state.invasions.meta ? state.invasions.meta.invasion_server_working : true),
            cogs: state => state.core.cogs,
            districts: state => state.core.districts,
            notificationTypes: state => state.notifications.notificationTypes,
            notifySounds: state => state.invasions.notifySounds,
        }),
    },

    methods: {
        async prepare() {
            await Q.all([
                this.$store.dispatch('load', 'cogs'),
                this.$store.dispatch('load', 'districts'),
                this.$store.dispatch('trackInvasions', 'invasions'),
            ])

            // Done preloading important data, start preloading audio files
            audioManager.preloadMultiple(invasionSounds)
        },

        handleInvasionNotification(invasion, kind) {
            // Bring up the cog's info and ensure we've been asked to notify about it
            const cog = this.cogs[invasion.cog]
            if (!this.shouldNotifyForCog(cog)) return

            // Check if the user has requested notifications of this kind
            if (!this.notificationTypes[`invasion${kind}`]) return

            // Notify the user
            let title
            let message
            let sound
            if (kind === 'Started') {
                title = `${cog.name} invasion started!`
                message = `${invasion.total.toLocaleString()} ${cog.name}s have just invaded ${this.districts[invasion.district].name}! It should last ${this.timeRemaining(invasion, true)}.`
                sound = invasionSounds.invasionStarted
            } else if (kind === 'EndingSoon') {
                title = `${cog.name} invasion ending soon!`
                message = 'There are less than three minutes left!'
                sound = invasionSounds.invasionEndingSoon
            } else {
                title = `${cog.name} invasion ended!`
                message = 'The toons have saved the day once again!'
                sound = invasionSounds.invasionEnded
            }

            if (!this.notifySounds) sound = null
            showNotification(title, message, assetPath(`cogs/${cog.image}`), sound)
        },
    },

    created() {
        this.$listenFor('invasions:started', invasion => this.handleInvasionNotification(invasion, 'Started'))
        this.$listenFor('invasions:ending-soon', invasion => this.handleInvasionNotification(invasion, 'EndingSoon'))
        this.$listenFor('invasions:ended', invasion => this.handleInvasionNotification(invasion, 'Ended'))
    },

    beforeDestroy() {
        this.$store.dispatch('stopTrackingInvasions', 'invasions')
    },
}
