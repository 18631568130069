//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'

import { assetPath } from 'core/util/filters'
import labels from 'core/util/labels'

import GroupDetails from './group-details'

export default {
    props: ['group'],

    computed: {
        type() {
            return this.groupType(this.group)
        },

        image() {
            const image = this.groupImage(this.group)
            return image ? assetPath(`groups/square/${image}`) : null
        },

        invasion() {
            return this.keyedActiveInvasions[this.group.district]
        },

        invasionCog() {
            if (!this.invasion) return null
            return this.cogs[this.invasion.cog]
        },

        invasionCogImage() {
            if (!this.invasionCog) return null
            return this.invasionCog.image ? `cogs/${this.invasionCog.image}` : null
        },

        label() {
            const memberWithLabel = this.group.members.find(member => !member.left && !!member.toon.label)
            if (memberWithLabel) return labels[memberWithLabel.toon.label]
            return null
        },

        membership() {
            return this.userMembershipInGroup(this.group)
        },

        ...mapState({
            districts: state => state.core.districts,
            locations: state => state.core.locations,
        }),
        ...mapGetters([
            'groupType', 'groupDisplayName', 'groupImage', 'groupPlayerCount', 'userGroupId', 'cogs',
            'keyedActiveInvasions', 'groupIsFull', 'userMembershipInGroup',
        ]),
    },

    methods: {
        showDetails() {
            this.$present(GroupDetails, { groupId: this.group.id })
        },
    },
}
