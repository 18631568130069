export const secsToTime = (secs, omitPadding = false) => {
    const hours = Math.floor(secs / 3600)
    secs %= 3600
    const minutes = Math.floor(secs / 60)
    const seconds = Math.floor(secs % 60)

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) return false // eslint-disable-line no-restricted-globals

    return {
        hours,
        minutes: (omitPadding ? minutes : (minutes < 10 ? `0${minutes}` : minutes)), // eslint-disable-line no-nested-ternary
        seconds: (omitPadding ? seconds : (seconds < 10 ? `0${seconds}` : seconds)), // eslint-disable-line no-nested-ternary
    }
}

export const timeToLongString = (time) => {
    if (!time) return 'an unknown amount of time'
    if (time.hours === 0 && time.minutes < 1) return 'less than a minute'
    return `${(time.hours > 0 ? `${time.hours} hour${time.hours === 1 ? '' : 's'} and ` : '') + time.minutes} minute${time.minutes === 1 ? '' : 's'}`
}
