//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { mapMutations } from 'vuex'

import ToonsPreview from 'accounts/components/toons-preview'
import { ModalFormMixin } from 'core/components/forms/mixin'
import { selectToon } from 'core/util/toons'

const statusOrder = [
    'loading',
    'accepted',
    'syncing',
    'synced',
    'unknown_error',
    'invalid_code',
]

export default {
    name: 'ToonSyncCodeFlowForm',

    components: { ToonsPreview },

    mixins: [ModalFormMixin],

    fields: [
        'code',
    ],

    endpoint: '/api/accounts/link_toonsync_code/',

    data() {
        return {
            status: null,
            toons: null,
        }
    },

    methods: {
        setStatus(status) {
            // Updates the status, ensuring we don't go "backwards" from
            // messages coming in out of order
            const newIdx = statusOrder.indexOf(status)
            if (this.status === null || statusOrder.indexOf(this.status) < newIdx) {
                this.status = status
            }
        },

        clean(data) {
            this.alert = null

            data.sid = this.$store.state.socket.sid
            if (!data.sid || !this.$store.getters.socketAuthed) {
                this.alert = 'Please wait a few seconds and try again. If this keeps happening, try refreshing the page.'
                return false
            }

            data.game = 1
            return data
        },

        stripCode(code) {
            return code.toLowerCase().replace(/\s/g, '')
        },

        onSubmitSuccess() {
            this.setStatus('loading')
        },

        ...mapMutations(['setGameAccount']),
    },

    mounted() {
        this.$listenFor('socket:link_toonsync_status', (data) => {
            if (this.stripCode(data.code) !== this.stripCode(this.model.code.value)) return
            this.setStatus(data.status)
            if (data.oauth) this.setGameAccount(data.oauth)
            if (data.toons) {
                this.toons = data.toons
                if (data.toons.length) selectToon(_.sortBy(data.toons, 'order')[0].id)
            }
        })
    },

    watch: {
        status(val) {
            if (val === 'invalid_code') {
                this.alert = "That code didn't work, please double check it."
                this.status = null
            }
        },
    },
}
