//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
    name: 'Jellybean',

    props: {
        bean: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapState({
            jellybeanHint: state => state.gardening.jellybeanHint,
        }),
    },
}
