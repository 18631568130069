import _ from 'lodash'

export const unlockablePoses = {
    cake_top: ['tf23', 'tf24'],
}

export const unlockableBackdrops = {
    tf23: ['tf23'],
    tf24: ['tf24'],
    pride: ['pride'],
    trans: ['pride'],
}

export const canUseUnlockable = (userUnlocks, grantingUnlocks) => _.intersection(userUnlocks, grantingUnlocks).length > 0
