//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import _ from 'lodash'

import ToonForm from 'accounts/components/toon-form'

export default {
    props: ['value'],

    computed: {
        toons() {
            return [..._.map(this.gameToons, t => ({ ...t, label: t.name, value: t.id })), {
                new: true,
            }]
        },

        keyedToons() {
            return _.keyBy(this.toons, 'id')
        },

        ...mapGetters(['currentToon', 'gameToons']),
    },

    methods: {
        onChange(toon) {
            if (!toon.id) {
                this.$present(ToonForm, {
                    forceCurrentGame: true,
                    submitSuccessCallback: (data) => {
                        this.$emit('input', data.id)
                    },
                })
            } else {
                this.$emit('input', toon.id)
            }
        },
    },

    mounted() {
        if (!this.value) this.$emit('input', this.currentToon.id)
    },
}
