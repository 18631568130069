//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'

import { assetPath } from 'core/util/filters'

export default {
    props: ['fieldOffice'],

    computed: {
        location() {
            return this.locations[this.fieldOffice.location]
        },

        image() {
            // We use the same logic we do for groups
            const hoodMapping = {
                7: 'dg',
                9: 'ddl',
                17: 'mm',
                27: 'tb',
            }
            return assetPath(`groups/square/sfo/${hoodMapping[this.location.hood]}_${(this.fieldOffice.id % 4) + 1}.jpg`)
        },

        difficulty() {
            if (this.fieldOffice.difficulty === 1) return 'One'
            if (this.fieldOffice.difficulty === 2) return 'Two'
            return 'Three'
        },

        ...mapState({
            locations: state => state.core.locations,
        }),

        ...mapGetters([
            'groupType', 'groupDisplayName', 'groupImage', 'groupPlayerCount', 'userGroupId', 'cogs',
            'keyedActiveInvasions',
        ]),
    },
}
