//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    mapActions, mapMutations, mapState, mapGetters,
} from 'vuex'

import CreatePasswordForm from 'accounts/components/create-password-form'
import ChangeEmailForm from 'accounts/components/change-email-form'
import ChangePasswordForm from 'accounts/components/change-password-form'
import GameToonSyncSummary from 'accounts/components/game-toonsync-summary'
import UnlinkOAuthForm from 'accounts/components/unlink-oauth-form'
import RedeemSpecialCodeForm from 'accounts/components/redeem-special-code-form'
import DeleteAccountForm from 'accounts/components/delete-account-form'

export default {
    name: 'SettingsPage',

    metaInfo() {
        return {
            title: 'Settings',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: "There's a bunch of knobs and switches you can hit here. We're not sure what all of them do, but you'll find out!",
            }],
        }
    },

    components: { GameToonSyncSummary },

    data() {
        return {
            displayDiscord: this.$store.state.accounts.user?.discord_public, // eslint-disable-line camelcase
            updatingDisplayDiscord: null,
        }
    },

    computed: {
        displayDiscordLabel() {
            let label = null
            if (this.updatingDisplayDiscord !== null) {
                label = this.updatingDisplayDiscord ? 'Saving...' : 'Saved!'
            }
            return label ? { checked: label, unchecked: label } : false
        },

        ...mapState({
            user: state => state.accounts.user,
        }),
        ...mapGetters(['isAuthenticated', 'discordLinked']),
    },

    methods: {
        createPassword() {
            this.$present(CreatePasswordForm)
        },

        changeEmail() {
            this.$present(ChangeEmailForm)
        },

        changePassword() {
            this.$present(ChangePasswordForm)
        },

        redeemSpecialCode() {
            this.$present(RedeemSpecialCodeForm)
        },

        unlinkOAuth(service, account) {
            this.$present(UnlinkOAuthForm, { service, account })
        },

        signOut() {
            this.logout()
            this.$router.safePush('/')
        },

        deleteAccount() {
            this.$present(DeleteAccountForm)
        },

        async updateDisplayDiscord() {
            this.updatingDisplayDiscord = true
            await this.updateUser({ instance: { discord_public: this.displayDiscord } })
            this.updatingDisplayDiscord = false
        },

        ...mapActions(['updateUser', 'syncCcAccount', 'syncTTRAccount']),
        ...mapMutations(['logout']),
    },

    watch: {
        user: {
            deep: true,
            handler() {
                this.displayDiscord = this.$store.state.accounts.user?.discord_public // eslint-disable-line camelcase
            },
        },
    },
}
