//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ModalFormMixin } from 'core/components/forms/mixin'

export default {
    name: 'CreatePasswordForm',

    mixins: [ModalFormMixin],

    modalClass: 'modal-content modal-content--sm',

    fields: [
        'password',
    ],

    dispatch: 'createPassword',
}
