import Vue from 'vue'
import VueGtag from 'vue-gtag'

import router from 'core/router'

Vue.use(VueGtag, {
    config: {
        id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    },
    includes: [
        {
            id: process.env.VUE_APP_GOOGLE_ANALYTICS_PLAYWIRE,
            params: {
                send_page_view: false,
            },
        },
    ],
}, router)

if (process.env.VUE_APP_GOOGLE_ANALYTICS_PLAYWIRE) {
    Vue.$gtag.event('ramp_js', {
        send_to: process.env.VUE_APP_GOOGLE_ANALYTICS_PLAYWIRE,
        pageview_id: window._pwGA4PageviewId,
    })
}
