//
//
//
//
//
//
//
//
//

import LoginForm from './login-form'
import RegisterForm from './register-form'
import ForgotPasswordForm from './forgot-password-form'

export default {
    name: 'AuthForm',

    components: { LoginForm, RegisterForm, ForgotPasswordForm },

    modalClass: 'modal-content modal-content--sm',

    props: {
        intent: {
            type: String,
            default: 'login',
        },

        skipClose: {
            type: Boolean,
            default: false,
        },

        callback: {
            type: Function,
        },

        prompt: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            mode: this.intent,
        }
    },

    computed: {
        headerAlt() {
            return {
                login: 'Sign in to ToonHQ',
                register: 'Sign up for ToonHQ',
                forgot: 'Reset your password',
            }[this.mode]
        },
    },

    methods: {
        handleCallback(...args) {
            if (this.callback) this.callback(this.mode, ...args)
        },

        oauthUrl(backend) {
            let path = `/oauth/login/${backend}/?next=${this.$route.path}`
            if (window.location.hash) path += `&hash=${window.location.hash.substr(1)}`
            return path
        },
    },
}
