//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'

import { isSafari } from 'core/util/browsers'
import SpeedchatAlert from './speedchat-alert'
import SpeedchatMessage from './speedchat-message'

export default {
    props: ['group', 'membership', 'forceShowAll'],

    components: { SpeedchatAlert, SpeedchatMessage },

    data() {
        return {
            newMessage: '',
            alert: null,
        }
    },

    computed: {
        initialLoading() {
            return !this.groupMessagesLoaded[this.group.id]
        },

        messageList() {
            if (!this.messages[this.group.id]) return []
            return _.sortBy(this.messages[this.group.id], 'id')
        },

        hiddenMessages() {
            if (this.showAll) return false
            if (!this.messages[this.group.id]) return false
            let missing = false
            _.each(this.messages[this.group.id], (message) => {
                if (this.members[message.member]?.left) missing = true
            })
            return missing
        },

        messageIsVisible() {
            return (message) => {
                if (message.deleted) return false
                return this.members[message.member] && !this.members[message.member].left
            }
        },

        members() {
            return _.keyBy(this.group.members, 'id')
        },

        footerStyleFix() {
            // We only specify the min-height for Safari's sake because it can't handle our layout
            if (isSafari) return { 'min-height': '40px' }
            return {}
        },

        groupIsActive() {
            return this.activeGroups.includes(this.group)
        },

        showAll() {
            return this.isMod || this.forceShowAll
        },

        ...mapGetters(['isMod', 'activeGroups']),
        ...mapState({
            user: state => state.accounts.user,
            messages: state => state.groups.messages,
            groupMessagesLoaded: state => state.groups.groupMessagesLoaded,
        }),
    },

    methods: {
        onKeyPress(e) {
            if (e.keyCode === 13) {
                e.preventDefault()
                this.send()
            }
        },

        async send() {
            this.alert = null
            if (!this.newMessage.length) return
            try {
                await this.sendMessage({ group: this.group, message: this.newMessage })
            } catch (e) {
                this.alert = this.$drf.parseError(e).squashed
            }
            this.newMessage = ''
        },

        scrollMessagesToBottom() {
            const scrollArea = this.$refs.body
            this.$nextTick(() => {
                scrollArea.scrollTop = scrollArea.scrollHeight
            })
        },

        async infiniteHandler($state) {
            if (this.messageList.length < 20) {
                $state.complete()
                return
            }

            const response = await this.loadMoreMessages({ group: this.group, before: this.messageList[0].id })
            const newMessages = response.data
            if (newMessages.length < 20) {
                $state.complete()
            } else {
                $state.loaded()
            }
        },

        ...mapActions(['sendMessage', 'loadMoreMessages']),
    },

    watch: {
        messageList() {
            const scrollArea = this.$refs.body
            if (scrollArea.scrollHeight - (scrollArea.scrollTop + scrollArea.offsetHeight) < 20) {
                // The user is close enough to the bottom that we should scroll them down
                this.scrollMessagesToBottom()
            }
        },
    },

    mounted() {
        this.scrollMessagesToBottom()
    },
}
