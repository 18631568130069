import axios from 'axios'
import _ from 'lodash'
import Vue from 'vue'

import { api, crudStore, combineStores } from 'core/util/vuex'

const initialState = window.STATE

export const SocketAuthState = {
    UNAUTHED: 'unauthed',
    AUTHENTICATING: 'authenticating',
    AUTHED: 'authed',
}

export default combineStores([
    crudStore('toons', '/api/toons/', null, 'toons'),

    {
        state: {
            user: initialState.user,
            gameAccounts: initialState.game_accounts?.length ? _.keyBy(initialState.game_accounts, 'id') : {},
            bans: initialState.bans,
            currentToon: null,
            lastToonForGame: {},

            pendingSocketAuthToken: initialState.socket_auth,
            socketAuthState: SocketAuthState.UNAUTHED,
        },

        getters: {
            isAuthenticated: state => !!state.user,
            isAdmin: state => state.user && state.user.role === 'admin',
            isMod: state => state.user && ['mod', 'admin'].indexOf(state.user.role) > -1,
            currentToon: state => state.toons[state.currentToon],
            discordLinked: state => state.user && !!state.user.discord_handle,
            hasBan: state => kind => _.find(state.bans, { [kind]: true }),

            accountsForGame: state => game => _.sortBy(_.filter(state.gameAccounts, { game }), 'id'),
            toonForGame: state => (game) => {
                // Try to use the last toon that was used for this game
                const lastToonId = state.lastToonForGame[game]
                if (lastToonId && state.toons[lastToonId]) return state.toons[lastToonId]

                // Try to find any toon from this game that isn't hidden
                const visibleToon = _.find(state.toons, { game, hidden: false })
                if (visibleToon) return visibleToon

                // Fall back to any toon associated with this game
                return _.find(state.toons, { game })
            },

            gameToons: (state, getters, rootState, rootGetters) => _.sortBy(_.filter(state.toons, { game: rootGetters.gameId }), ['order', 'id']),

            socketAuthed: state => state.socketAuthState === SocketAuthState.AUTHED,
        },

        mutations: {
            setAuth(state, data) {
                state.user = data.user
                state.gameAccounts = _.keyBy(data.game_accounts, 'id')
                state.toons = _.keyBy(data.toons, 'id')
                state.bans = data.bans
                state.pendingSocketAuthToken = data.socket_auth
            },

            patchCurrentUser(state, user) {
                state.user = { ...state.user, ...user }
            },

            logout(state) {
                state.user = null
                state.gameAccounts = {}
                state.toons = {}
                state.currentToon = null
                state.pendingSocketAuthToken = null
                axios.post('/api/accounts/logout/')
            },

            switchToon(state, { toonId, gameId }) {
                state.currentToon = toonId
                state.lastToonForGame = { ...state.lastToonForGame, [gameId]: toonId }
            },

            setGameAccount(state, data) {
                Vue.set(state.gameAccounts, data.id, data)
            },

            deleteGameAccount(state, id) {
                Vue.delete(state.gameAccounts, id)
            },

            setSocketAuthState(state, s) {
                state.socketAuthState = s
            },

            usePendingSocketAuthToken(state) {
                state.pendingSocketAuthToken = null
            },
        },

        actions: {
            createAccount({ commit, dispatch }, { instance }) {
                const request = axios.post('/api/accounts/register/', instance)
                request.then((response) => {
                    commit('setAuth', response.data)
                    if (response.data.user) dispatch('selectToonForCurrentGame')
                })
                return request
            },

            resendVerification: api('/api/accounts/resend_verification/'),

            verifyEmail({ commit }, token) {
                const request = axios.post('/api/accounts/verify/', { token })
                request.then((response) => {
                    commit('setAuth', response.data)
                })
                return request
            },

            login({ commit, dispatch }, { instance }) {
                const request = axios.post('/api/accounts/login/', instance)
                request.then((response) => {
                    commit('setAuth', response.data)
                    dispatch('selectToonForCurrentGame')
                })
                return request
            },

            changePassword: api('/api/accounts/change_password/'),

            startChangeEmail: api('/api/accounts/change_email/'),

            createPassword({ commit }, { instance }) {
                const request = axios.post('/api/accounts/create_password/', instance)
                request.then(() => {
                    commit('patchCurrentUser', { has_password: true })
                })
                return request
            },

            requestPasswordReset: api('/api/accounts/reset_password/start/'),

            completePasswordReset({ commit }, { instance }) {
                const request = axios.post('/api/accounts/reset_password/finish/', instance)
                request.then((response) => {
                    commit('setAuth', response.data)
                })
                return request
            },

            updateUser({ commit }, { instance }) {
                const request = axios.post('/api/accounts/update_user/', instance)
                request.then((response) => {
                    commit('patchCurrentUser', response.data)
                })
                return request
            },

            async ackChangelog({ commit }, changelogId) {
                await axios.post('/api/accounts/ack_changelog/', { changelog: changelogId })
                commit('patchCurrentUser', { changelog: changelogId })
            },

            unlinkOAuth({ commit }, { instance }) {
                const request = axios.post(`/oauth/unlink/${instance.service}/`, { account: instance.account })
                request.then(() => {
                    // Check if we're unlinking a service, or a specific account
                    if (instance.account) {
                        commit('deleteGameAccount', instance.account)
                    } else {
                        commit('patchCurrentUser', { discord_handle: null })
                    }
                })
                return request
            },

            async redeemSpecialCode({ commit, state }, { instance }) {
                const response = await axios.post('/api/accounts/redeem_special_code/', instance)
                commit('patchCurrentUser', { unlocks: [...state.user.unlocks, response.data.unlockable] })
                return response.data
            },

            async deleteAccount({ commit }) {
                const response = await axios.post('/api/accounts/delete/')
                commit('logout')
                return response
            },

            saveToon({ commit }, { instance }) {
                const formData = new FormData()
                _.each(instance, (val, key) => {
                    if (key !== 'photo') {
                        formData.set(key, val)
                    } else {
                        formData.set('photo', val, `${Math.random().toString(36).substring(2)}${Math.random().toString(36).substring(2)}.jpg`)
                    }
                })
                const request = axios({
                    method: instance.id ? 'put' : 'post',
                    url: instance.id ? `/api/toons/${instance.id}/` : '/api/toons/',
                    data: formData,
                    config: { headers: { 'Content-Type': 'multipart/form-data' } },
                })
                request.then(response => commit('setToon', response.data))
                return request
            },

            saveToonAndSwitchIfNew({ dispatch, getters }, options) {
                const request = dispatch('saveToon', options)
                request.then((response) => {
                    if (!options.instance.id && response.data.game === getters.gameId) {
                        dispatch('switchToon', response.data.id)
                    }
                })
                return request
            },

            switchToon({ commit, rootGetters }, toonId) {
                commit('switchToon', { toonId, gameId: rootGetters.gameId })
            },

            selectToonForCurrentGame({ commit, getters, rootGetters }, currentGame) {
                if (!currentGame) currentGame = rootGetters.gameId
                const toonGame = getters.currentToon ? getters.currentToon.game : null

                if (toonGame !== currentGame) {
                    const toon = getters.toonForGame(currentGame)
                    const toonId = toon ? toon.id : null
                    commit('switchToon', { toonId, gameId: currentGame })
                }
            },

            syncToonWithGame(x, toonId) {
                return axios.post(`/api/toons/${toonId}/sync/`)
            },

            syncCcAccount(x, oauthId) {
                return axios.post(`/api/accounts/sync_account/cc/${oauthId}/`)
            },

            syncTTRAccount(x, oauthId) {
                return axios.post(`/api/accounts/sync_account/ttr/${oauthId}/`)
            },

            registerSocketHandlers({ commit }, { socket }) {
                socket.on('update_toons', (data) => {
                    console.log('update toons', data.toons)
                    _.each(data.toons, toon => commit('setToon', toon))
                    commit('setGameAccount', data.oauth)
                })
                socket.on('update_game_account', data => commit('setGameAccount', data))
            },

            startAccountsSocket({ dispatch, getters }) {
                // We'll connect once the socket is ready
                if (!getters.socketActive) return

                dispatch('sendSocketAuth')
            },

            async getSocketAuthToken({ commit, state }) {
                // Use the one that came with our initial page load (or login state) if we haven't yet
                if (state.pendingSocketAuthToken) {
                    const token = state.pendingSocketAuthToken
                    commit('usePendingSocketAuthToken')
                    return token
                }

                // Load a fresh one from the server
                try {
                    const response = await axios.post('/api/accounts/socket_auth/')
                    return response.data
                } catch (e) {
                    console.log('Failed to load a socket auth token!')
                    return null
                }
            },

            async sendSocketAuth({ commit, getters, dispatch, state }) {
                // Make sure we aren't already authed (or authenticating)
                if (state.socketAuthState !== SocketAuthState.UNAUTHED || !getters.isAuthenticated) return

                // Get the token to authenticate with
                console.log('[socket auth] authenticating')
                commit('setSocketAuthState', SocketAuthState.AUTHENTICATING)
                const token = await dispatch('getSocketAuthToken')

                // Ensure we got a token
                if (!token) {
                    console.log('[socket auth] failed to get token')
                    commit('setSocketAuthState', SocketAuthState.UNAUTHED)
                    return
                }

                const result = await dispatch('socketEmitWithAck', { event: 'auth', args: [token] })
                commit('setSocketAuthState', result ? SocketAuthState.AUTHED : SocketAuthState.UNAUTHED)
                console.log('[socket auth] auth result:', result)
            },

            stopAccountsSocket({ commit, dispatch, state }) {
                if (state.socketAuthState === SocketAuthState.UNAUTHED) return
                console.log('clearing socket auth')
                dispatch('socketEmit', { event: 'clear_auth' })
                commit('setSocketAuthState', SocketAuthState.UNAUTHED)
            },

            socketConnected({ commit, dispatch }) {
                commit('setSocketAuthState', SocketAuthState.UNAUTHED)
                dispatch('sendSocketAuth')
            },

            socketLost({ commit }) {
                commit('setSocketAuthState', SocketAuthState.UNAUTHED)
            },
        },
    },
])
