//
//
//
//

import { assetPath } from 'core/util/filters'

export default {
    props: ['src', 'alt'],

    computed: {
        assetPath() {
            return assetPath(this.src)
        },
    },

    created() {
        if (!this.alt) console.error('No alt text defined for asset with src: ', this.src)
    },
}
