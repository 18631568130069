//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ModalFormMixin } from 'core/components/forms/mixin'

export default {
    name: 'LoginForm',

    mixins: [ModalFormMixin],

    props: ['oauthUrl'],

    fields: [
        'username',
        'password',
    ],

    dispatch: 'login',

    data() {
        return {
            needsVerification: false,
            resending: false,
            resent: false,
        }
    },

    methods: {
        onSubmitFailure(data, response, error, parsedError) {
            if (parsedError.squashed === 'Please verify your email address.') {
                this.needsVerification = true
            }
        },

        resendVerification() {
            this.resending = true
            this.$store.dispatch('resendVerification', {
                instance: {
                    email: this.model.username.value,
                },
            }).then(() => {
                this.resent = true
            })
        },
    },
}
