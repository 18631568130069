//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        const params = new URLSearchParams(window.location.search)
        return {
            error: this.$route.params.error,
            next: params.get('next') || '/',
        }
    },
}
