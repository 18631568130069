import Vue from 'vue'
import _ from 'lodash'

const radio = new Vue()

const Radio = {
    install() {
        Vue.mixin({
            beforeCreate() {
                this.$radioCallbacks = []
            },

            beforeDestroy() {
                _.each(this.$radioCallbacks, (c) => {
                    radio.$off(c.event, c.callback)
                })
            },
        })

        Vue.prototype.$listenFor = function listenFor(event, callback) {
            radio.$on(event, callback)
            this.$radioCallbacks.push({ event, callback })
        }
    },
}

Vue.use(Radio)

export default radio
