//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { mapState, mapGetters } from 'vuex'

import AuthForm from 'accounts/components/auth-form'
import VerifyEmail from 'accounts/components/verify-email'
import ResetPassword from 'accounts/components/reset-password'
import ChristmasToons from 'core/img/home/toons/christmas-toons.jpg'
import EverydayToons from 'core/img/home/toons/everyday-toons.jpg'
import ValenToons from 'core/img/home/toons/valen-toons.jpg'
import WinterToons from 'core/img/home/toons/winter-toons.jpg'
import HomeParallax from './home-parallax'

export default {
    name: 'HomePage',

    metaInfo: {
        title: 'ToonHQ | Guides for Toontown Rewritten and Corporate Clash',
        titleTemplate: '%s',
    },

    theme: 'generic-solid',

    components: { HomeParallax },

    computed: {
        toonsPhoto() {
            const today = moment()
            const year = today.year()
            if (today.isBetween(`${year}-02-01`, `${year}-02-28`, 'day', '[]')) return ValenToons
            if (today.isBetween(`${year}-01-01`, `${year}-04-01`, 'day', '[]')) return WinterToons
            if (today.isBetween(`${year}-12-01`, `${year}-12-31`, 'day', '[]')) return ChristmasToons
            return EverydayToons
        },

        ...mapState({
            user: state => state.accounts.user,
        }),
        ...mapGetters(['isAuthenticated', 'game']),
    },

    methods: {
        login() {
            this.$present(AuthForm, { intent: 'login' })
        },

        register() {
            this.$present(AuthForm, { intent: 'register' })
        },

        logout() {
            this.$store.commit('logout')
        },
    },

    mounted() {
        if (this.$route.params.token) {
            this.$present(VerifyEmail, this.$route.params)
        } else if (this.$route.params.resetToken) {
            this.$present(ResetPassword, this.$route.params)
        }
    },
}
