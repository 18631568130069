import _ from 'lodash'

export const BeanColor = {
    RED: {
        name: 'Red',
        color: '#d50200',
        shape: 'square',
        pos: 1,
        posColor: '#ffdfdc',
    },

    GREEN: {
        name: 'Green',
        color: '#00dc01',
        shape: 'triangle',
        pos: 2,
        posColor: '#115511',
    },

    ORANGE: {
        name: 'Orange',
        color: '#d58c00',
        shape: 'star',
        pos: 3,
        posColor: '#443300',
    },

    VIOLET: {
        name: 'Violet',
        color: '#7c02b6',
        shape: 'diamond',
        pos: 4,
        posColor: '#eeccff',
    },

    BLUE: {
        name: 'Blue',
        color: '#0100d7',
        shape: 'moon',
        pos: 5,
        posColor: '#dfdcff',
    },

    PINK: {
        name: 'Pink',
        color: '#dba5b0',
        shape: 'hexagon',
        pos: 6,
        posColor: '#553545',
    },

    YELLOW: {
        name: 'Yellow',
        color: '#d8d803',
        shape: 'rectangle-wide',
        pos: 7,
        posColor: '#434301',
    },

    CYAN: {
        name: 'Cyan',
        color: '#02dada',
        shape: 'heart',
        pos: 8,
        posColor: '#014343',
    },

    GREY: {
        name: 'Grey',
        color: '#333333',
        shape: 'spade',
        pos: 9,
        posColor: '#333',
    },
}

export const flowerTypes = [
    {
        name: 'Daisy',
        pluralName: 'Daisies',
        flowers: [
            {
                name: 'School Daisy',
                combination: [BeanColor.YELLOW],
            },
            {
                name: 'Lazy Daisy',
                combination: [BeanColor.YELLOW, BeanColor.RED],
            },
            {
                name: 'Midsummer Daisy',
                combination: [BeanColor.YELLOW, BeanColor.RED, BeanColor.GREEN],
            },
            {
                name: 'Freshasa Daisy',
                combination: [BeanColor.YELLOW, BeanColor.RED, BeanColor.CYAN, BeanColor.ORANGE],
            },
            {
                name: 'Whoopsie Daisy',
                combination: [BeanColor.YELLOW, BeanColor.RED, BeanColor.ORANGE, BeanColor.ORANGE, BeanColor.ORANGE],
            },
            {
                name: 'Upsy Daisy',
                combination: [BeanColor.YELLOW, BeanColor.BLUE, BeanColor.CYAN, BeanColor.VIOLET, BeanColor.BLUE, BeanColor.BLUE],
            },
            {
                name: 'Crazy Daisy',
                combination: [BeanColor.YELLOW, BeanColor.GREEN, BeanColor.RED, BeanColor.ORANGE, BeanColor.GREEN, BeanColor.GREEN, BeanColor.GREEN],
            },
            {
                name: 'Hazy Dazy',
                combination: [BeanColor.YELLOW, BeanColor.BLUE, BeanColor.VIOLET, BeanColor.CYAN, BeanColor.VIOLET, BeanColor.RED, BeanColor.ORANGE, BeanColor.VIOLET],
            },
        ],
    },

    {
        name: 'Tulip',
        pluralName: 'Tulips',
        flowers: [
            {
                name: 'Onelip',
                combination: [BeanColor.VIOLET, BeanColor.RED, BeanColor.BLUE, BeanColor.VIOLET, BeanColor.VIOLET],
            },
            {
                name: 'Twolip',
                combination: [BeanColor.VIOLET, BeanColor.RED, BeanColor.RED, BeanColor.RED, BeanColor.VIOLET, BeanColor.VIOLET],
            },
            {
                name: 'Threelip',
                combination: [BeanColor.VIOLET, BeanColor.YELLOW, BeanColor.YELLOW, BeanColor.VIOLET, BeanColor.YELLOW, BeanColor.ORANGE, BeanColor.VIOLET, BeanColor.YELLOW],
            },
        ],
    },

    {
        name: 'Carnation',
        pluralName: 'Carnations',
        flowers: [
            {
                name: 'What-in Carnation',
                combination: [BeanColor.PINK],
            },
            {
                name: 'Instant Carnation',
                combination: [BeanColor.PINK, BeanColor.YELLOW],
            },
            {
                name: 'Hybrid Carnation',
                combination: [BeanColor.PINK, BeanColor.RED, BeanColor.RED],
            },
            {
                name: 'Side Carnation',
                combination: [BeanColor.PINK, BeanColor.RED, BeanColor.GREEN, BeanColor.BLUE, BeanColor.RED],
            },
            {
                name: 'Model Carnation',
                combination: [BeanColor.PINK, BeanColor.GREEN, BeanColor.GREEN, BeanColor.GREEN, BeanColor.GREEN, BeanColor.YELLOW, BeanColor.GREEN],
            },
        ],
    },

    {
        name: 'Lily',
        pluralName: 'Lilies',
        flowers: [
            {
                name: 'Lily-of-the-Alley',
                combination: [BeanColor.CYAN],
            },
            {
                name: 'Lily Pad',
                combination: [BeanColor.CYAN, BeanColor.GREEN],
            },
            {
                name: 'Tiger Lily',
                combination: [BeanColor.CYAN, BeanColor.ORANGE, BeanColor.ORANGE],
            },
            {
                name: 'Livered Lily',
                combination: [BeanColor.CYAN, BeanColor.ORANGE, BeanColor.ORANGE, BeanColor.PINK],
            },
            {
                name: 'Chili Lily',
                combination: [BeanColor.CYAN, BeanColor.RED, BeanColor.RED, BeanColor.RED, BeanColor.RED],
            },
            {
                name: 'Silly Lily',
                combination: [BeanColor.CYAN, BeanColor.RED, BeanColor.VIOLET, BeanColor.VIOLET, BeanColor.VIOLET, BeanColor.VIOLET],
            },
            {
                name: 'Indubitab Lily',
                combination: [BeanColor.CYAN, BeanColor.VIOLET, BeanColor.CYAN, BeanColor.BLUE, BeanColor.CYAN, BeanColor.BLUE, BeanColor.BLUE],
            },
            {
                name: 'Dilly Lilly',
                combination: [BeanColor.CYAN, BeanColor.BLUE, BeanColor.YELLOW, BeanColor.YELLOW, BeanColor.CYAN, BeanColor.BLUE, BeanColor.YELLOW, BeanColor.YELLOW],
            },
        ],
    },

    {
        name: 'Daffodil',
        pluralName: 'Daffodils',
        flowers: [
            {
                name: 'Laff-o-dil',
                combination: [BeanColor.GREEN],
            },
            {
                name: 'Daffy Dill',
                combination: [BeanColor.GREEN, BeanColor.CYAN],
            },
            {
                name: 'Giraff-o-dil',
                combination: [BeanColor.GREEN, BeanColor.PINK, BeanColor.YELLOW, BeanColor.YELLOW],
            },
            {
                name: 'Time and a half-o-dil',
                combination: [BeanColor.GREEN, BeanColor.PINK, BeanColor.BLUE, BeanColor.PINK, BeanColor.PINK],
            },
        ],
    },

    {
        name: 'Pansy',
        pluralName: 'Pansies',
        flowers: [
            {
                name: 'Dandy Pansy',
                combination: [BeanColor.ORANGE],
            },
            {
                name: 'Chim Pansy',
                combination: [BeanColor.ORANGE, BeanColor.CYAN],
            },
            {
                name: 'Potsen Pansy',
                combination: [BeanColor.ORANGE, BeanColor.RED, BeanColor.RED],
            },
            {
                name: 'Marzi Pansy',
                combination: [BeanColor.ORANGE, BeanColor.YELLOW, BeanColor.YELLOW, BeanColor.RED],
            },
            {
                name: 'Smarty Pansy',
                combination: [BeanColor.ORANGE, BeanColor.PINK, BeanColor.PINK, BeanColor.ORANGE, BeanColor.BLUE, BeanColor.PINK],
            },
        ],
    },

    {
        name: 'Petunia',
        pluralName: 'Petunias',
        flowers: [
            {
                name: 'Car Petunia',
                combination: [BeanColor.BLUE, BeanColor.VIOLET, BeanColor.BLUE, BeanColor.VIOLET, BeanColor.CYAN, BeanColor.BLUE, BeanColor.BLUE],
            },
            {
                name: 'Platoonia',
                combination: [BeanColor.BLUE, BeanColor.PINK, BeanColor.PINK, BeanColor.BLUE, BeanColor.RED, BeanColor.ORANGE, BeanColor.YELLOW, BeanColor.YELLOW],
            },
        ],
    },

    {
        name: 'Rose',
        pluralName: 'Roses',
        flowers: [
            {
                name: "Summer's Last Rose",
                combination: [BeanColor.RED, BeanColor.RED, BeanColor.RED],
            },
            {
                name: 'Corn Rose',
                combination: [BeanColor.RED, BeanColor.YELLOW, BeanColor.ORANGE, BeanColor.YELLOW],
            },
            {
                name: 'Tinted Rose',
                combination: [BeanColor.RED, BeanColor.PINK, BeanColor.ORANGE, BeanColor.RED, BeanColor.ORANGE, BeanColor.PINK],
            },
            {
                name: 'Stinking Rose',
                combination: [BeanColor.RED, BeanColor.CYAN, BeanColor.ORANGE, BeanColor.PINK, BeanColor.VIOLET, BeanColor.CYAN, BeanColor.CYAN],
            },
            {
                name: 'Istilla Rose',
                combination: [BeanColor.RED, BeanColor.BLUE, BeanColor.VIOLET, BeanColor.VIOLET, BeanColor.BLUE, BeanColor.BLUE, BeanColor.PINK, BeanColor.BLUE],
            },
        ],
    },
]

export const flowersByJellybeans = [[], [], [], [], [], [], [], []]

let id = 1
_.each(flowerTypes, (ft) => {
    _.each(ft.flowers, (f, fIdx) => {
        f.id = id
        f.type = ft
        f.asset = `${ft.name.toLowerCase()}_${fIdx + 1}.png`
        flowersByJellybeans[f.combination.length - 1].push(f)
        id += 1
    })
})

_.each(BeanColor, (bean) => {
    bean.asset = `${bean.name.toLowerCase()}-jb.svg`
})
