//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'

import games from 'core/util/games'

export default {
    data() {
        return {
            games,
        }
    },

    computed: {
        game() {
            return this.games[this.gameId]
        },

        ...mapGetters(['gameId']),
        ...mapState({
            theme: state => state.core.theme,
        }),
    },

    methods: {
        getGameUrl(game) {
            return this.$route.meta.mappings[game.shortName.toLowerCase()]
        },

        setGame(game) {
            this.$store.commit('setLastGame', game.id)
            this.$store.dispatch('selectToonForCurrentGame')
        },
    },
}
