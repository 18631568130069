//
//
//
//
//
//
//
//
//
//
//
//

export default {
    id: 12,
    version: '3.8.1',
    date: '2022-11-15',
    notify: true,
}
