//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'

import PulseMixin from 'core/util/pulse'

export default {
    name: 'FishingPage',

    metaInfo() {
        return {
            title: 'Fishing Guide',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: `Elegant, informative and modern ${this.game.name} Fishing Guide! Use this guide to find all your fish!`,
            }],
        }
    },

    theme: 'fishing',

    mixins: [PulseMixin],

    data() {
        return {
            bySpecies: true,

            rarityMap: {
                1: 'VC',
                2: 'VC',
                3: 'VC',
                4: 'C',
                5: 'C',
                6: 'R',
                7: 'R',
                8: 'VR',
                9: 'ER',
                10: 'UR',
            },

            rods: ['Twig Rod', 'Bamboo Rod', 'Hardwood Rod', 'Steel Rod', 'Gold Rod'],
        }
    },

    computed: {
        pondsByFish() {
            const pondsByFish = {}
            _.each(_.groupBy(this.fishPonds, 'fish'), (rawPonds, fish) => {
                // Consolidate into "All Ponds" when we can
                let ponds = _.cloneDeep(rawPonds)
                if (ponds.length === Object.keys(this.ponds).length) {
                    // This fish is in all ponds, let's separate the common ones out
                    // Group the ponds by similarity
                    const pondsBySimilarity = _.groupBy(ponds, p => `${p.rod}${p.rarity}`)

                    // Determine the size of the largest group
                    const maxSimilarPonds = _.max(_.map(pondsBySimilarity, pbs => pbs.length))

                    // Create the new list of ponds
                    ponds = []
                    _.each(pondsBySimilarity, (pbs) => {
                        if (pbs.length === maxSimilarPonds) {
                            ponds.push({ pond: 9001, rod: pbs[0].rod, rarity: pbs[0].rarity })
                        } else {
                            ponds = [...ponds, ...pbs]
                        }
                    })
                }

                // Add pond names and sort the ponds
                _.each(ponds, (p) => {
                    p.name = this.ponds[p.pond]?.abbreviation || this.ponds[p.pond]?.name || 'All Ponds'
                    p.rod = p.rod.substr(0, p.rod.length - 4)
                    p.pond_sort_order = this.ponds[p.pond]?.sort_order || 9001 // eslint-disable-line camelcase
                })

                pondsByFish[fish] = _.orderBy(ponds, ['rarity', 'pond_sort_order'])
            })

            return pondsByFish
        },

        speciesByRod() {
            // Convert into the format we'll need the data in and get unique records
            const fishRods = _.uniqBy(_.map(this.fishPonds, (fp) => {
                const fish = this.fish[fp.fish]
                const species = this.species[fish.species]
                return {
                    photo: species.photo,
                    species: species.name,
                    fish: fish.name,
                    rod: fp.rod,
                }
            }), 'fish')

            // Group by rod
            const byRod = _.groupBy(fishRods, 'rod')

            // Within each rod, group by species
            _.each(byRod, (rodSpecies, rod) => {
                byRod[rod] = _.groupBy(rodSpecies, 'species')
            })

            return byRod
        },

        ...mapGetters(['speciesFishList', 'game']),
        ...mapState({
            species: state => state.fishing.species,
            fish: state => state.fishing.fish,
            ponds: state => state.fishing.ponds,
            fishPonds: state => state.fishing.fishPonds,
        }),
    },

    methods: {
        async prepare() {
            await this.loadFishingCoreData()
        },

        ...mapActions(['loadFishingCoreData']),
    },
}
