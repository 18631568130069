//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
    props: ['member', 'message', 'userIsOwner', 'hidden'],

    computed: {
        isOwnMessage() {
            return !!this.toons[this.member.toon.id]
        },

        ...mapState({
            toons: state => state.accounts.toons,
        }),
    },
}
