//
//
//
//
//
//

import axios from 'axios'
import { mapState } from 'vuex'

import radio from 'core/util/radio'

export default {
    props: ['userIsOwner', 'member', 'message'],

    computed: {
        actions() {
            if (this.user?.role === 'mod' || this.user?.role === 'admin') {
                // Check if this is a ToonHQ member, otherwise they can't be banned
                if (this.member.toon.id) return ['Kick', 'Ban']
                return ['Kick']
            }
            if (this.user?.role === 'kicker' || this.userIsOwner) return ['Kick']
            return ['Report']
        },

        ...mapState({
            user: state => state.accounts.user,
        }),
    },

    methods: {
        onClick(action) {
            radio.$emit('startReport', { member: this.member, message: this.message, action })
        },

        async deleteMessage() {
            await axios.post(`/api/groups/messages/${this.message.id}/delete/`)
            this.message.deleted = true
        },
    },
}
