//
//
//
//
//
//
//

import { mapState } from 'vuex'

import { keyedGameBackgrounds, keyedRings } from 'core/util/toonatars'

export default {

    props: {
        toon: {
            type: Object,
        },

        size: {
            type: Number,
            default: 50,
        },
    },

    computed: {
        style() {
            const style = {
                width: `${this.size}px`,
                height: `${this.size}px`,
            }

            // Apply rings
            if (this.toonInfo.ring) {
                style.padding = `${Math.max(Math.round(this.size / 12), 1)}px`
                style.background = keyedRings[this.toonInfo.ring].background
            }

            return style
        },

        backdropStyle() {
            const style = { backgroundColor: '#fff' }

            if (this.toonInfo.backdrop) {
                style.backgroundImage = `url(${this.toonInfo.backdrop})`
                style.backgroundSize = 'cover'
            } else {
                style.background = this.toonInfo.background
            }

            return style
        },

        toonInfo() {
            if (!this.toon) {
                return {
                    background: '#cacaca',
                    image: 'rabbit.png',
                    opacity: 0.2,
                }
            }
            if (this.toon.photo) {
                return {
                    backdrop: this.toon.photo_bg ? keyedGameBackgrounds[this.toon.game][this.toon.photo_bg]?.image : null,
                    background: 'white',
                    image: this.toon.photo,
                    ring: this.toon.ring,
                    opacity: 1,
                }
            }
            return {
                background: this.toon.color,
                image: this.species[this.toon.species].image,
                opacity: 1,
            }
        },

        ...mapState({
            species: state => state.core.toonSpecies,
        }),
    },
}
