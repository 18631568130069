import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import Vue from 'vue'

import RequireToonWrapper from 'accounts/components/require-toon-wrapper'
import { audioManager, groupSounds } from 'core/util/audio'
import { genericError, parseError } from 'core/util/drf'
import { present } from 'core/util/modals'
import GroupForm from 'groups/components/group-form'


const initialState = window.STATE

const defaultFilters = () => ({
    groupType: [],
    hideGroupType: [],
    district: null,
    area: [],
    sortBy: 'oldest',
    showFull: true,
})

let subscribedGroupMessages = []
const boardingRequestPromises = {}
let boardingRequestNonce = 1
window.resyncs = 0

// Events
const GROUP_CREATED = 'group_created'
const GROUP_UPDATED = 'group_updated'
const GROUP_DISBANDED = 'group_disbanded'
const GROUP_MESSAGE = 'group_message'
const MEMBER_JOINED = 'member_joined'
const MEMBER_LEFT = 'member_left'
const MESSAGE_RECEIVED = 'message_received'
const MESSAGE_DELETED = 'message_deleted'
const GAME_CLOSED = 'game_closed'

// Event history management
let lastFullSyncId = null
let fullSyncNonce = 0
let fullSyncInProgress = null
const groupEventHistory = []
const pruneEventHistory = () => {
    // Removes anything older than 5 minutes from the history
    const cutoff = moment().subtract(5, 'minute').unix()
    let numToRemove = _.findIndex(groupEventHistory, e => e.timestamp >= cutoff)
    if (numToRemove === -1) numToRemove = groupEventHistory.length
    groupEventHistory.splice(0, numToRemove)
}
const addEventToHistory = (event, syncId) => {
    groupEventHistory.push({
        event,
        syncId,
        timestamp: moment().unix(),
    })
    pruneEventHistory()
}

// Logic for sync IDs
const syncIdIsNewerThanTarget = (syncId, target) => {
    // In theory, we'd just be able to check that syncId is greater than the target
    // However, we need to handle cases where redis is reset and sync IDs start over
    // We'll assume that if the sync ID is 10000 less than the last full sync, it's a reset
    if (syncId > target) return true
    if (target - syncId > 10000) return true
    return false
}

export default {
    state: {
        groups: initialState.groups ? _.keyBy(initialState.groups, 'id') : null,
        activeGroups: _.map(initialState.groups || [], 'id'),
        groupTypes: initialState.group_types ? _.keyBy(initialState.group_types, 'id') : null,
        messages: {},

        filters: defaultFilters(),
        openGroupId: null,
        groupMessagesLoaded: {},

        gameOpen: true,
        toonBoardingGroupSubscription: null,
    },

    getters: {
        activeGroups: state => _.map(state.activeGroups, id => state.groups[id]),

        groupsList: (state, getters, rootState) => {
            const { groupType, hideGroupType, district, sortBy, area, showFull } = state.filters
            const { districts, locations } = rootState.core

            return _.chain(getters.activeGroups)
                .filter((g) => {
                    // Always show groups that the user is involved in
                    if (getters.userMembershipInGroup(g)) return true

                    // Hide groups that have gone in
                    if (g.went_in) return false

                    // Hide full groups if we're filtering those out
                    if (!showFull && getters.groupIsFull(g)) return false

                    // Group type
                    if (groupType.length && !groupType.includes(g.type)) return false
                    if (hideGroupType.length && hideGroupType.includes(g.type)) return false

                    // District
                    if (district) {
                        if (district === 'invaded') {
                            if (!getters.keyedActiveInvasions[g.district]) return false
                        } else if (district === 'noninvaded') {
                            if (getters.keyedActiveInvasions[g.district]) return false
                        } else if (district === 'speedchatonly') {
                            if (!districts[g.district].speedchat_only) return false
                        } else if (district === 'nonspeedchatonly') {
                            if (districts[g.district].speedchat_only) return false
                        }
                    }

                    // Area
                    if (area.length) {
                        const groupLocation = locations[g.location]
                        if (!area.includes(groupLocation.hood)) return false
                    }

                    // Everything passed
                    return true
                })
                .sortBy((g) => {
                    if (sortBy === 'oldest') return g.id
                    if (sortBy === 'newest') return -g.id
                    if (sortBy === 'most_toons') return -getters.groupNumPlayers(g)
                    if (sortBy === 'least_toons') return getters.groupNumPlayers(g)
                    throw new Error('Unknown sortBy')
                })
                .value()
        },

        groupsAreFiltered: (state) => {
            const { groupType, hideGroupType, district, area, showFull } = state.filters
            return groupType.length || hideGroupType.length || district || area.length || !showFull
        },

        isActiveGroup: state => group => state.activeGroups.indexOf(group.id) > -1,

        groupIsOwnedByUser: (state, getters) => (group) => {
            const m = getters.userGroupMembership
            return m && m.group.id === group.id && m.membership.owner
        },

        userGroupId: (state, getters) => {
            const group = getters.userGroup
            return group ? group.id : null
        },

        userGroup: (state, getters) => {
            const groupMembership = getters.userGroupMembership
            return groupMembership?.group
        },

        userGroupMembership: (state, getters) => {
            for (const g of getters.activeGroups) {
                const membership = getters.userMembershipInGroup(g)
                if (membership) return { group: g, membership }
            }
            return null
        },

        userMembershipInGroup: (state, getters, rootState) => (group) => {
            const toonIds = _.map(rootState.accounts.toons, 'id')
            if (!toonIds.length) return null

            for (const m of group.members) {
                if (m.left) continue
                if (toonIds.includes(m.toon.id)) return m
            }
            return null
        },

        groupIdsForMessages: (state, getters) => {
            const ids = []
            if (getters.userGroupId) ids.push(getters.userGroupId)
            if (state.openGroupId && state.openGroupId !== getters.userGroupId) ids.push(state.openGroupId)
            return ids
        },

        groupType: state => group => state.groupTypes[group.type],

        groupDisplayName: (state, getters) => (group) => {
            const type = getters.groupType(group)

            const optionsToString = prefix => _.chain(group.options)
                .map(v => getters.groupTypeOptionValues[v])
                .filter(v => v.prefix === prefix)
                .map(v => v.name)
                .value()
                .join(' ')

            if (type) {
                const prefixOptions = optionsToString(true)
                const suffixOptions = optionsToString(false)
                let name = ''
                if (prefixOptions.length > 0) name = `${prefixOptions} `
                name += type.name
                if (suffixOptions.length > 0) name = `${name} ${suffixOptions}`
                return name
            }

            // We don't have a type for this group
            return 'Group'
        },

        applyDyanmicGroupImage: (state, getters, rootState) => (group, image) => {
            if (image === '__sfo__') {
                // Sellbot Field Office images are based on the playground
                // We also have 4 options for each playground, so we base it on
                // the remainder of dividing the group's id by 4
                const hoodId = rootState.core.locations[group.location].hood
                const hoodMapping = {
                    7: 'dg',
                    9: 'ddl',
                    17: 'mm',
                    27: 'tb',
                }
                return `sfo/${hoodMapping[hoodId]}_${(group.id % 4) + 1}.jpg`
            }
            return image
        },

        groupImage: (state, getters) => (group) => {
            const type = getters.groupType(group)
            const options = _.chain(group.options).map(v => getters.groupTypeOptionValues[v].image_override).compact().value()
            if (options.length) return options[0]
            return getters.applyDyanmicGroupImage(group, type.image)
        },

        groupNumPlayers: () => (group) => {
            let count = 0
            _.each(group.members, (member) => {
                if (!member.left) count += member.num_players
            })
            return count
        },

        groupPlayerCount: (state, getters) => (group) => {
            const joined = getters.groupNumPlayers(group)
            if (group.max_players) return `${joined} / ${group.max_players} toons`
            return `${joined} toons`
        },

        groupHasRoomLeft: (state, getters) => (group) => {
            if (group.max_players) return Math.max(group.max_players - getters.groupNumPlayers(group), 0)
            return true
        },

        groupIsFull: (state, getters) => group => group.max_players && getters.groupNumPlayers(group) >= group.max_players,

        groupTypeOptions: state => _.chain(state.groupTypes)
            .map(gt => gt.options)
            .flatten()
            .keyBy('id')
            .value(),

        groupTypeOptionValues: (state, getters) => _.chain(getters.groupTypeOptions)
            .map(gto => gto.values)
            .flatten()
            .keyBy('id')
            .value(),

        groupTypeList: (state, getters) => _.sortBy(_.filter(state.groupTypes, { game: getters.game.id }), 'id'),
    },

    mutations: {
        setActiveGroups(state, groups) {
            console.log('[groups] setActiveGroups', groups)
            state.groups = { ...state.groups, ..._.keyBy(groups, 'id') }
            state.activeGroups = _.map(groups, 'id')
            state.gameOpen = true
        },

        addOrUpdateGroup(state, group) {
            console.log('[groups] addOrUpdateGroup', group)
            let groupData = group
            if (state.groups[group.id]) groupData = { ...state.groups[group.id], ...group }
            state.groups = { ...state.groups, ...{ [group.id]: groupData } }
            const owner = _.find(group.members, { owner: true })
            if (!state.activeGroups.includes(group.id) && !owner.left) state.activeGroups = [...state.activeGroups, group.id]
        },

        addGroupMessageCount(state, { id, amount }) {
            const group = state.groups[id]
            group.num_messages += amount
        },

        addOrUpdateGroupMember(state, { member, rootState }) {
            const group = state.groups[member.group]
            const memberIdx = group.members.findIndex(m => m.id === member.id)
            let wasInGroup = false
            const isInGroup = !member.left
            if (memberIdx !== -1) {
                if (!group.members[memberIdx].left) wasInGroup = true
                group.members.splice(memberIdx, 1, member)
            } else {
                group.members = [...group.members, member]
            }

            // Notify about the user joining
            const toonIds = _.map(rootState.accounts.toons, 'id')
            if (!wasInGroup && isInGroup && !toonIds.includes(member.toon.id)) {
                console.log('[groups] would play about a member joining, but skipping', audioManager, groupSounds)
                // audioManager.play(groupSounds.memberJoined)
            }

            // Update group metadata
            group.updated = moment().format()
            if (isInGroup && !member.owner) {
                group.keep_alives = 0
            }
        },

        removeGroupMember(state, { id, group }) {
            const member = state.groups[group].members.find(m => m.id === id)
            member.left = moment().format()
        },

        archiveGroup(state, groupId) {
            const now = moment().format()
            _.each(state.groups[groupId].members, (m) => {
                m.left = now
            })
            state.activeGroups = state.activeGroups.filter(g => g !== groupId)
        },

        addOrUpdateMessage(state, message) {
            if (!state.messages[message.group]) Vue.set(state.messages, message.group, {})
            Vue.set(state.messages[message.group], message.id, message)
        },

        addOrUpdateMessages(state, { groupId, messages }) {
            if (!state.messages[groupId]) Vue.set(state.messages, groupId, {})
            state.messages[groupId] = { ...state.messages[groupId], ..._.keyBy(messages, 'id') }
            Vue.set(state.groupMessagesLoaded, groupId, true)
        },

        removeMessage(state, message) {
            Vue.delete(state.messages[message.group], message.id)
        },

        setGroupTypes(state, types) {
            state.groupTypes = _.keyBy(types, 'id')
        },

        setGroupFilters(state, filters) {
            state.filters = filters
        },

        resetGroupFilters(state) {
            state.filters = defaultFilters()
        },

        openGroup(state, groupId) {
            state.openGroupId = groupId
        },

        closeGroup(state) {
            state.openGroupId = null
        },

        updateGameOpen(state, open) {
            state.gameOpen = open
            if (!open) {
                // Disband all our groups
                const now = moment().format()
                _.each(state.activeGroups, (groupId) => {
                    _.each(state.groups[groupId].members, (m) => {
                        m.left = now
                    })
                })
                state.activeGroups = []
            }
        },

        setToonBoardingGroupSubscription(state, toonId) {
            state.toonBoardingGroupSubscription = toonId
        },
    },

    actions: {
        loadGroupsCoreData({ commit, getters }) {
            if (initialState.group_types) {
                initialState.group_types = null
                return true
            }

            return axios.get(`/api/groups/core_data/${getters.game.id}/`).then(({ data }) => {
                commit('setGroupTypes', data.group_types)
                commit('set', { model: 'cogs', data: _.keyBy(data.cogs, 'id') })
                commit('set', { model: 'districts', data: _.keyBy(data.districts, 'id') })
                commit('set', { model: 'locations', data: _.keyBy(data.locations, 'id') })
            })
        },

        trackGroups({ commit, dispatch, getters }) { // eslint-disable-line object-curly-newline
            return new Promise(async (resolve) => {
                console.log(`[groups] trackGroups called for game ${getters.gameId}`)

                // Resolve immediately (but continue connecting) if we're using initial state
                if (initialState.groups) {
                    console.log('[groups] using initialState for data, continuing socket connection...')
                    lastFullSyncId = initialState.sync_id
                    initialState.groups = null
                    resolve()
                }

                if (getters.roomConnectedToGame('groups', getters.gameId)) {
                    // We're already connected and loaded for this game
                    console.log('[groups] trackGroups is already configured for the right game')
                    return resolve()
                }

                // Connect to the room for this game
                console.log('[groups] connecting to room...')
                subscribedGroupMessages = []
                commit('setToonBoardingGroupSubscription', null)
                await dispatch('joinRoom', { room: 'groups', game: getters.gameId, service: 'groupsStore' })

                // We've connected, kick off post-connect logic
                dispatch('onGroupsSocketGameSet', resolve)
            })
        },

        roomReconnected({ commit, dispatch }, { room }) {
            if (room.room === 'groups') {
                subscribedGroupMessages = []
                commit('setToonBoardingGroupSubscription', null)
                dispatch('onGroupsSocketGameSet')
            }
        },

        onGroupsSocketGameSet({ dispatch, getters }, resolve) {
            console.log('[groups] groups room connected, loading groups API and subscribing to messages / boarding group events...')

            // Now that we're connected to the socket, let's check if we're
            // already part of a group and need to subscribe to messages
            if (getters.groupIdsForMessages.length) dispatch('handleGroupIdsForMessagesChange', getters.groupIdsForMessages)

            // Load groups from the API
            const loadGroupsPromise = dispatch('loadGroups', { gameId: getters.gameId, loadAgainAfterDelay: true })
            if (resolve) loadGroupsPromise.then(resolve)

            dispatch('subscribeToActiveToonBoardingGroupCreation')
        },

        registerSocketHandlers({ dispatch }, { socket }) {
            socket.on('boarding_response', ({ success, nonce, result, message }) => {
                // Pull up the promise for this response
                const promise = boardingRequestPromises[nonce]
                if (!promise) return
                delete boardingRequestPromises[nonce]

                // Process the response
                if (success) {
                    promise.resolve(result)
                } else {
                    promise.reject(new Error(message || genericError))
                }
            })

            socket.on('boarding_created', (boardingGroup) => {
                console.log('[groups] A boarding group was created in-game, opening the create group form', boardingGroup)
                present(RequireToonWrapper, { target: GroupForm, props: { initialBoardingGroup: boardingGroup } })
            })

            // Listen for all the group events
            _.each([
                GROUP_CREATED,
                GROUP_UPDATED,
                GROUP_DISBANDED,
                MEMBER_JOINED,
                MEMBER_LEFT,
                GROUP_MESSAGE,
                MESSAGE_RECEIVED,
                MESSAGE_DELETED,
                GAME_CLOSED,
            ], (type) => {
                socket.on(type, data => dispatch('processGroupEvent', { type, data }))
            })
        },

        async loadGroups({ commit, dispatch, getters }, { gameId, nonce, loadAgainAfterDelay }) {
            if (getters.gameId !== gameId || !getters.roomConnectedToGame('groups', gameId)) return
            if (!nonce) {
                fullSyncNonce += 1
                nonce = fullSyncNonce
            } else if (nonce !== fullSyncNonce) {
                return
            }
            fullSyncInProgress = getters.gameId

            let response
            console.log('[groups] Loading groups API...', nonce)
            try {
                response = await axios.get(`/api/groups/list/${getters.gameId}/`)
            } catch (e) {
                if (getters.gameId === gameId) {
                    console.warning('Failed to load groups', e)
                    fullSyncInProgress = null
                }
            }
            if (getters.gameId !== gameId) return
            commit('setActiveGroups', response.data.groups)
            lastFullSyncId = response.data.sync_id
            dispatch('replayGroupEvents', lastFullSyncId)

            if (loadAgainAfterDelay) {
                // The server caches 5 seconds of data at a time, so we load a second time 7 seconds later
                // when we should be able to get data for everything that we could have possibly missed.
                // We'll apply all the socket events we collected over the data too.
                setTimeout(() => {
                    dispatch('loadGroups', { gameId, nonce, loadAgainAfterDelay: false })
                }, 12000)
            } else {
                fullSyncInProgress = null
            }
        },

        processGroupEvent({ dispatch, state }, { type, data }) {
            // Add the event to the replay history
            const syncId = data.sync_id
            delete data.sync_id
            addEventToHistory({ type, data }, syncId)

            // Discard the event if it is before our most recent full sync
            // Note: We exclude group messages from this check because they don't include a sync ID
            if (type !== MESSAGE_RECEIVED && lastFullSyncId && !syncIdIsNewerThanTarget(syncId, lastFullSyncId)) {
                console.warn(`Ignoring ${type} event because its sync ID is before the last full sync.`)
                return
            }

            // Ensure groups are loaded
            if (state.groups === null) {
                // We haven't finished loading initial groups yet so we'll ignore this message (for now)
                // It will potentially be applied during the replay process after the load
                console.warn(`Ignoring ${type} event because groups have not finished loading yet!`, data)
                return
            }

            // Get the target group of the event so we can see if we need to resync (if we don't know about the group)
            let resyncIfMissingGroup = null
            if ([GROUP_UPDATED, GROUP_DISBANDED, GROUP_MESSAGE].includes(type)) {
                resyncIfMissingGroup = data.id
            } else if ([MEMBER_JOINED, MEMBER_LEFT, MESSAGE_RECEIVED].includes(type)) {
                resyncIfMissingGroup = data.group
            }

            // Check if we need to resync because we're missing the target group
            if (resyncIfMissingGroup && !state.groups[resyncIfMissingGroup]) {
                dispatch('resyncGroups', type)
                return
            }

            // We can also see if we need to resync if we received a message from someone we don't know about
            if (type === MESSAGE_RECEIVED && data.source === 0) {
                const { members } = state.groups[data.group]
                if (_.findIndex(members, m => m.id === data.member) === -1) {
                    dispatch('resyncGroups', type)
                    return
                }
            }

            // Everything about the event checks out, let's apply it
            dispatch('applyGroupEvent', { type, data })
        },

        replayGroupEvents({ dispatch }, afterSyncId) {
            const events = _.chain(groupEventHistory)
                .filter(e => syncIdIsNewerThanTarget(e.syncId, afterSyncId))
                .sortBy('syncId')
                .value()

            console.log('[groups] Replaying group events', events, groupEventHistory)
            _.each(events, e => dispatch('applyGroupEvent', e.event))
        },

        applyGroupEvent({ commit, rootState }, { type, data }) {
            if (type === GROUP_CREATED) {
                commit('addOrUpdateGroup', data)
            } else if (type === GROUP_UPDATED) {
                commit('addOrUpdateGroup', data)
            } else if (type === GROUP_DISBANDED) {
                commit('archiveGroup', data.id)
            } else if (type === GROUP_MESSAGE) {
                commit('addGroupMessageCount', { id: data.id, amount: 1 })
            } else if (type === MEMBER_JOINED) {
                commit('addOrUpdateGroupMember', { member: data, rootState })
            } else if (type === MEMBER_LEFT) {
                commit('removeGroupMember', data)
            } else if (type === MESSAGE_RECEIVED) {
                commit('addOrUpdateMessage', data)
            } else if (type === MESSAGE_DELETED) {
                commit('addGroupMessageCount', { id: data.group, amount: -1 })
                commit('removeMessage', data)
            } else if (type === GAME_CLOSED) {
                commit('updateGameOpen', false)
            }
        },

        resyncGroups({ dispatch, getters }, socketEvent) {
            if (fullSyncInProgress === getters.gameId) return
            console.warn(`Groups resyncing because of ${socketEvent} event for a group we didn't know about`)
            window.resyncs += 1
            Vue.$gtag.event('resync', { category: socketEvent })
            dispatch('loadGroups', { gameId: getters.gameId })
        },

        stopTrackingGroups({ dispatch }) {
            dispatch('leaveRoom', { room: 'groups', service: 'groupsStore' })
            subscribedGroupMessages = []
        },

        gameChanged({ commit, state }) {
            console.log('[groups] game changed, clearing data...')
            state.groups = null
            state.activeGroups = []
            commit('resetGroupFilters')
        },

        async createGroup({ commit }, { instance }) {
            const response = await axios.post('/api/groups/create/', instance)
            commit('addOrUpdateGroup', response.data)
            return response
        },

        keepGroupAlive({ commit }, group) {
            axios.post(`/api/groups/${group.id}/keep_alive/`)
            group.last_keep_alive = moment().format()
            group.keep_alives += 1
            commit('addOrUpdateGroup', group)
        },

        async joinGroup({ commit, getters, rootState }, { group, numPlayers }) {
            const response = await axios.post('/api/groups/join/', {
                group: group.id,
                toon: getters.currentToon.id,
                num_players: numPlayers || 1,
            })
            commit('addOrUpdateGroupMember', { member: response.data, rootState })
            return response
        },

        async leaveGroup({ commit, getters, state }, groupId) {
            const response = await axios.post(`/api/groups/${groupId}/leave/`)
            const membership = getters.userMembershipInGroup(state.groups[groupId])

            // It's possible the socket has already notified us so we don't need to do the rest
            if (!membership) return response

            // The socket hasn't told us yet, apply the leaving manually
            if (membership.owner) {
                commit('archiveGroup', groupId)
            } else {
                commit('removeGroupMember', { id: membership.id, group: groupId })
            }
            return response
        },

        async changeDistrict({ commit }, { group, district }) {
            await axios.post(`/api/groups/${group.id}/change_district/${district}/`)
            commit('addOrUpdateGroup', { id: group.id, district })
        },

        async relistGroup({ commit }, group) {
            commit('addOrUpdateGroup', { id: group.id, went_in: false, boarding: false })
            try {
                await axios.post(`/api/groups/${group.id}/relist/`)
            } catch (e) {
                commit('addOrUpdateGroup', { id: group.id, went_in: true })
            }
        },

        async subscribeGroupMessages({ dispatch }, { groupId }) {
            if (subscribedGroupMessages.includes(groupId)) return
            console.log('[groups] subscribing to messages for group', groupId)
            subscribedGroupMessages.push(groupId)
            await dispatch('socketEmitWithAck', { event: 'subscribe_group_messages', args: [groupId] })
            dispatch('loadInitialMessages', groupId)
        },

        async unsubscribeGroupMessages({ dispatch }, groupId) {
            console.log('[groups] unsubscribing from messages for group', groupId)
            subscribedGroupMessages = _.without(subscribedGroupMessages, groupId)
            dispatch('socketEmit', { event: 'unsubscribe_group_messages', args: [groupId] })
        },

        async loadInitialMessages({ commit }, groupId) {
            const response = await axios.get(`/api/groups/${groupId}/messages/`)
            commit('addOrUpdateMessages', { groupId, messages: response.data })
            return response
        },

        async loadMoreMessages({ commit }, { group, before }) {
            const response = await axios.get(`/api/groups/${group.id}/messages/before/${before}/`)
            commit('addOrUpdateMessages', { groupId: group.id, messages: response.data })
            return response
        },

        async sendMessage({ commit }, { group, message }) {
            const response = await axios.post('/api/groups/messages/send/', { group: group.id, message })
            commit('addOrUpdateMessage', message)
            return response
        },

        async handleGroupIdsForMessagesChange({ dispatch, getters }, groupIds) {
            // Ignore if we aren't on the groups page (or aren't connected to the socket yet)
            if (!getters.roomConnected('groups')) {
                console.log('[groups] Ignoring groupIdsForMessages change because the groups socket is not active.')
                return
            }

            const subscribe = _.difference(groupIds, subscribedGroupMessages)
            const unsubscribe = _.difference(subscribedGroupMessages, groupIds)
            _.each(subscribe, groupId => dispatch('subscribeGroupMessages', { groupId }))
            _.each(unsubscribe, groupId => dispatch('unsubscribeGroupMessages', groupId))
        },

        reportToon({ getters }, report) {
            const payload = {
                user_toon: getters.currentToon.id,
                reason: report.reason,
                block: report.block,
            }
            if (report.member) payload.target_member = report.member.id
            if (report.message) payload.target_message = report.message.id
            return axios.post('/api/groups/reports/report/', payload)
        },

        async loadReport({ commit }, reportId) {
            const response = await axios.get(`/api/groups/reports/${reportId}/`)
            commit('addOrUpdateGroup', response.data.group)
            return response.data
        },

        async loadGroup({ commit }, params) {
            const response = await axios.get('/api/groups/get/', { params })
            commit('addOrUpdateGroup', response.data)
            return response.data
        },

        boardingRequest({ getters, rootState }, { request, toonId }) {
            return new Promise(async (resolve, reject) => {
                // Ensure we're connected and have an SID
                if (!getters.roomConnected('groups') || !rootState.socket.sid) {
                    console.warn("Can't send boarding request, not connected to socket.")
                    return reject(new Error('Still connecting to ToonHQ, please try again shortly.'))
                }

                // Grab the nonce for this request and increment it for next time
                const nonce = boardingRequestNonce
                boardingRequestNonce += 1

                // Store the promise so we can handle the response (above)
                boardingRequestPromises[nonce] = { resolve, reject }

                // Kick off the request
                try {
                    await axios.post('/api/groups/boarding_request/', {
                        toon: toonId,
                        sid: rootState.socket.sid,
                        request,
                        nonce,
                    })
                } catch (e) {
                    reject(new Error(parseError(e).squashed))
                }
            })
        },

        loadExistingBoardingGroup({ dispatch }, toonId) {
            return dispatch('boardingRequest', { request: 'get_boarding_group', toonId })
        },

        retryBoarding({ dispatch }, toonId) {
            return dispatch('boardingRequest', { request: 'retry_boarding', toonId })
        },

        async subscribeToActiveToonBoardingGroupCreation({ commit, getters, state, rootState }) {
            // Ensure we're connected to the socket with an SID
            if (!getters.roomConnected('groups') || !rootState.socket.sid) {
                console.log('[groups] Ignoring subscribeToActiveToonBoardingGroupCreation because the groups room is not active')
                return
            }

            // Pull up the active Toon and make sure we aren't already subscribed to it
            const toon = getters.currentToon
            if (state.toonBoardingGroupSubscription === toon?.id) {
                console.log(`[groups] Ignoring subscribeToActiveToonBoardingGroupCreation because we're already subscribed to ${toon.id}`)
                return
            }

            // Make sure it's an external TTR Toon
            if (!toon || !toon.external || toon.game !== 1) {
                // This toon isn't one that we can subscribe to
                // Check if we're already subscribed so we can unsubscribe from the old one
                // Note: We don't need to unsubscribe if the game is changing since the
                // namespace will automatically handle unsubscribing us
                if (state.toonBoardingGroupSubscription && getters.game.id === 1) {
                    console.log(`[groups] subscribeToActiveToonBoardingGroupCreation unsubscribing from Toon ${state.toonBoardingGroupSubscription}`)
                    axios.post('/api/groups/boarding_request/', {
                        toon: state.toonBoardingGroupSubscription,
                        sid: rootState.socket.sid,
                        request: 'unsubscribe',
                        nonce: 0,
                    })
                } else {
                    console.log("[groups] Ignoring subscribeToActiveToonBoardingGroupCreation because active Toon isn't external TTR Toon", toon)
                }
                commit('setToonBoardingGroupSubscription', null)
                return
            }

            console.log(`[groups] Subscribing to boarding group creation for Toon on ${rootState.socket.sid}...`, toon)
            axios.post('/api/groups/boarding_request/', {
                toon: toon.id,
                sid: rootState.socket.sid,
                request: 'subscribe',
                nonce: 0,
            })
            commit('setToonBoardingGroupSubscription', toon.id)
        },
    },
}

export const registerGroupsWatchers = (store) => {
    store.watch(
        (state, getters) => getters.groupIdsForMessages,
        (groupIds) => {
            store.dispatch('handleGroupIdsForMessagesChange', groupIds)
        },
    )

    store.watch(
        (state, getters) => getters.currentToon,
        () => {
            store.dispatch('subscribeToActiveToonBoardingGroupCreation')
        },
    )
}
