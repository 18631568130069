//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ModalFormMixin } from 'core/components/forms/mixin'

export default {
    name: 'ForgotPasswordForm',

    mixins: [ModalFormMixin],

    fields: [
        'email',
    ],

    dispatch: 'requestPasswordReset',

    data() {
        return {
            submitted: false,
        }
    },

    methods: {
        onSubmitSuccess() {
            this.submitted = true
        },
    },
}
