//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
    computed: mapGetters({
        game: 'game',
        invasions: 'activeInvasions',
    }),
}
