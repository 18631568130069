import Vue from 'vue'
import _ from 'lodash'
import moment from 'moment'

/**
 * Makes lodash functions available as filters.
 */
Vue.filter('startCase', _.startCase)
Vue.filter('truncate', _.truncate)
Vue.filter('capitalize', _.capitalize)

/**
 * Generate an absolute path to an asset. Assets never change (like cog images, group photos, etc.)
 * @param  {String} path path to the asset to include
 * @return {String}      absolute path to the passed asset
 */
export const assetPath = path => window.ASSETS_URL + path
Vue.filter('asset', assetPath)

/**
 * Create a string with the passed number and the singular or plural word,
 * as appropriate.
 * @param  {Number} num      the number to display and base singular/plural on
 * @param  {String} singular the word to display when num is 1
 * @param  {String} plural   the world to display when num != 1
 * @return {String}           i.e. "1 function" or "2 functions"
 */
export const pluralize = (num, singular, plural) => {
    if (!plural) plural = `${singular}s`
    return `${num} ${num === 1 ? singular : plural}`
}
Vue.filter('pluralize', pluralize)

/**
 * Convert a date string (or moment) into the specified format.
 * @param  {String} dt                original date string / moment
 * @param  {String} [format='M/D/YY'] a moment-compatible format
 * @return {String}                   formatted date string
 */
export const date = (dt, format = 'M/D/YY') => {
    const m = moment(dt)
    if (!m.isValid()) return undefined
    return m.format(format)
}
Vue.filter('date', date)

/**
 * Convert a datetime string (or moment) into the specified format.
 * @param  {String} dt                       original datetime string / moment
 * @param  {String} [format='M/D/YY h:mm a'] a moment-compatible format
 * @return {String}                          formatted datetime string
 */
export const datetime = (dt, format = 'M/D/YY h:mm a') => {
    const m = moment(dt)
    if (!m.isValid()) return undefined
    return m.format(format)
}
Vue.filter('datetime', datetime)

/**
 * Calculate the difference in time from the passed date to now.
 * @param  {String} dt   date string / moment
 * @return {String}      difference in time
 */
export const timeFromNow = dt => moment(dt).fromNow()
Vue.filter('timeFromNow', timeFromNow)

/**
 * Sorts the items by a key (defaulting to name).
 * @param  {Array}  items items to sort
 * @param  {String} key   key to sort by
 * @return {Array}        sorted items
 */
export const sortItems = (items, key = 'name') => _.sortBy(items, key)
Vue.filter('sort', sortItems)

/**
 * Returns "Add" or "Edit" based on the id.
 *
 * @param  {Integer} int the id
 * @return {String}
 */
Vue.filter('addOrEdit', id => (id ? 'Edit' : 'Add'))


/**
 * Displays a number in stat format.
 */
const statSuffixes = ['K', 'M', 'B', 'T', 'Q']
export const stat = (number, currency) => {
    let suffixIdx = -1
    while (number >= 1000) {
        number /= 1000
        suffixIdx += 1
    }
    const doubleDigits = currency && suffixIdx === -1
    const roundingAmount = doubleDigits ? 100 : 10
    number = Math.round(number * roundingAmount) / roundingAmount
    if (doubleDigits) number = number.toFixed(2)
    return `${number}${suffixIdx > -1 ? statSuffixes[suffixIdx] : ''}`
}
Vue.filter('stat', stat)


/**
 * Displays a percentage.
 */
Vue.filter('percentage', number => `${Math.round(number * 100)}%`)


/**
 * Summarizes a list.
 */
const summarizeList = (list, emptyMessage = '', maxShow = 3) => {
    // If there's nothing in the list, we're done
    if (list.length === 0) return emptyMessage

    // If there's only one item, we're done
    if (list.length === 1) return list[0]

    let commaJoined = []
    let andJoined = ''
    if (list.length > maxShow) {
        commaJoined = list.slice(0, maxShow - 1)
        andJoined = pluralize(list.length - maxShow + 1, 'other')
    } else {
        commaJoined = list.slice(0, list.length - 1)
        andJoined = list[list.length - 1]
    }

    return `${commaJoined.join(', ')} and ${andJoined}`
}
Vue.filter('summarizeList', summarizeList)


/**
 * Pads a number with a zero.
 */
export const zeroPad = (num) => {
    if (num < 10) return `0${num}`
    return num
}
Vue.filter('zeroPad', zeroPad)
