import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import 'moment-timezone'

const initialState = window.STATE

// Determines the number of days between two weekdays. (i.e. (6, 4) is 5)
const dayOffsetBetweenWeekdays = (day1, day2) => {
    let days = Math.abs(day1 - (7 + day2))
    if (days >= 7) days -= 7
    return days
}

const prepareGuildsData = (data) => {
    // Get the current date and user's timezone
    const actualDayIndex = moment.tz('America/Los_Angeles').day()
    const timezone = moment.tz.guess()

    // Convert each boss run into a moment object
    _.each(data.guilds, (guild) => {
        _.each(guild.schedule, (dayRuns, dayIdx) => {
            _.each(dayRuns, (bossRuns) => {
                _.each(bossRuns, (run, runIdx) => {
                    bossRuns[runIdx] = moment.tz('America/Los_Angeles')
                        .add(dayOffsetBetweenWeekdays(actualDayIndex, parseInt(dayIdx)), 'days')
                        .hour(run[0])
                        .minute(run[1])
                        .second(0)
                        .tz(timezone)
                })
            })
        })
    })

    return {
        guilds: data.guilds,
        actualDayIndex,
        timezone: moment().tz(timezone).format('z'),
    }
}

export default {
    state: initialState.guilds ? prepareGuildsData(initialState.guilds) : {
        guilds: null,
        actualDayIndex: null,
        timezone: null,
    },

    mutations: {
        updateGuildData(state, data) {
            const prepared = prepareGuildsData(data)
            state.guilds = prepared.guilds
            state.actualDayIndex = prepared.actualDayIndex
            state.timezone = prepared.timezone
        },
    },

    actions: {
        async loadGuildsCoreData({ state, commit }) {
            if (state.guilds) return

            const response = await axios.get('/api/guilds/core_data/')
            commit('updateGuildData', response.data)
        },
    },
}
