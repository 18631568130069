//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    id: 2,
    version: '3.6.1',
    date: '2022-02-04',
    notify: true,
}
