//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueRecaptcha from 'vue-recaptcha'

import { ModalFormMixin } from 'core/components/forms/mixin'

export default {
    name: 'RegisterForm',

    components: { VueRecaptcha },

    mixins: [ModalFormMixin],

    props: ['oauthUrl'],

    fields: [
        'email',
        'password',
        'recaptcha_response',
    ],

    dispatch: 'createAccount',

    data() {
        return {
            sitekey: window.RECAPTCHA_SITE_KEY,
        }
    },

    methods: {
        onVerify(response) {
            this.model.recaptcha_response.value = response
        },

        onSubmitSuccess(data, response) {
            if (data.user) {
                // Verification wasn't required, so they're all set
                ModalFormMixin.methods.onSubmitSuccess.call(this, data, response)
            }
        },

        // onSubmitFailure() {
        //     this.$refs.recaptcha.reset()
        // },
    },
}
