export const toonColors = {
    Peach: '#f4b1b2',
    Bubblegum: '#ff5b71',
    'Bright Red': '#ec4a44',
    Red: '#d96a69',
    Maroon: '#b23f71',
    Crimson: '#a72d42',
    Beige: '#cdc19c',
    Sienna: '#917317',
    Brown: '#a15c42',
    Tan: '#fcb37b',
    Coral: '#d28143',
    Amber: '#f6bf59',
    Orange: '#fb7d0a',
    Apricot: '#fa8965',
    Yellow: '#fee525',
    Cream: '#fef48b',
    Citrine: '#daee6b',
    Green: '#5ef54a',
    Lime: '#91d235',
    'Forest Green': '#69a448',
    'Sea Green': '#49bc7d',
    Emerald: '#07dba6',
    'Mint Green': '#a3daab',
    'Ice Blue': '#bddef3',
    'Light Blue': '#76e6d4',
    Teal: '#34b8b6',
    Aqua: '#5ecff5',
    Blue: '#338ec9',
    Periwinkle: '#8d95e4',
    'Steel Blue': '#536899',
    'Royal Blue': '#4651bf',
    'Slate Blue': '#725fd7',
    Purple: '#8846c6',
    Lavender: '#b679e1',
    Pink: '#e19deb',
    'Rose Pink': '#e370b2',
    Black: '#4c4d59',
    White: '#ebebeb',
    'Spooky Purple': '#5b3d82',
    'ToonFest Blue': '#4699f7',
    'ToonFest Pink': '#ee5dd1',
}

export const speedchatColors = {
    Purple: '#dcc3e5',
    Blue: '#c9d7fe',
    Cyan: '#d0e6f9',
    Teal: '#eafefe',
    Green: '#ccfecc',
    Yellow: '#fef9cc',
    Orange: '#feeacc',
    Red: '#fecccc',
    Pink: '#fed7ed',
    Brown: '#d2c8b4',
}
