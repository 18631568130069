//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'FancyCheckbox',

    props: {
        value: {
            type: Boolean,
            required: true,
        },

        loading: {
            type: Boolean,
            default: false,
        },
    },
}
