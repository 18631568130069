//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

import AuthForm from 'accounts/components/auth-form'
import GameToggle from 'core/components/game-toggle'
import UserDropdown from 'core/components/user-dropdown'

export default {
    components: { GameToggle, UserDropdown },

    computed: {
        ...mapGetters(['isAuthenticated', 'gameId']),
    },

    methods: {
        login() {
            this.$present(AuthForm, { intent: 'login' })
        },

        register() {
            this.$present(AuthForm, { intent: 'register' })
        },
    },
}
