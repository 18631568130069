import 'moment-timezone'
import Vue from 'vue'
import axios from 'axios'
import { sync } from 'vuex-router-sync'

import '@/scss/styles.scss'
// import '@/plugins/sentry'
import '@/plugins/gtag'
import '@/plugins/bootstrap-vue'
import '@/plugins/transitions'
import '@/plugins/vue-multiselect'
import '@/plugins/vue-draggable'
import '@/plugins/vue-swatches'
import '@/plugins/vue-infinite-loading'
import '@/plugins/vue-js-toggle-button'
import '@/plugins/vue-toast-notification'
import 'core/util/drf'
import 'core/util/filters'
import 'core/util/radio'
import 'core/util/timer'
import router from 'core/router'
import { store } from 'core/store'
import App from 'core/components/app'
import 'core/components/registry'
import 'invasions/components/registry'

// Configure Vue
Vue.config.productionTip = false

// Axios
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
axios.defaults.xsrfCookieName = 'csrftoken'

// Vuex Router Sync
sync(store, router)

export default new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App),
})
